import { FaRegListAlt } from "react-icons/fa";

export default {
  primaryNav: [
    {
      "label": "News",
      "class": "icons_news",
      "url": "link.sales-resource-center-news",
      "href": "/News",
      "title": "The News tab is the primary location where key communications from Marketing, Sales Enablement, and Sales Operations are delivered. In addition, the most relevant key templates and links are also included."
    },
    {
      "label": "Sales Plays",
      "class": "icons_sales-plays",
      "url": "link.sales-resource-center-sales-plays",
      "href": "/Plays",
      "title": "The Sales Plays tab is the primary location for key content on specific AspenTech initiatives in certain industries or verticals. It includes presentations, a talking point, business value presentations, solution presentations, and other assets (content)."
    },
    {
      "label": "Products & Solutions",
      "class": "icons_productSolutions-n",
      "label-class": "lines-3",
      "url": "link.sales-resource-center-products",
      "href": "/Products",
      "title": "The Products & Solutions tab is the primary location for key content regarding AspenTech products and solutions. It is organized by Product Suite or Industry. You will find content related to New Product Releases, Datasheets, Videos, Presentation, Brochures, Catalogs, and other relevant assets."
    },
    {
      "label": "Industry & Insights",
      "class": "icons-insights-n",
      "url": "link.sales-resource-center-news",
      "href": "/SalesProcess",
      "title": "The Insights tab is the primary location for Industry-related information. The information is organized by Industries and includes Analyst Reports, Economic Outlook per Industry, Regional Reports, and other relevant assets."
    },
    {
      "label": "Success Stories",
      "class": "icons_success-stories",
      "url": "link.sales-resource-center-success-stories",
      "href": "/SuccessStories",
      "title": "The Success Stories tab is the primary location for customer case studies and success stories. These are organized by Industry and by Suite Area. Customer Case Studies are PowerPoint presentations about 3 – 4 pages in length The Customer Success Stories are in PDF format and about  5 – 7 pages in length."

    },
    {
      "label": "Competitive Intelligence",
      "class": "icons_competitive-intel",
      "url": "link.sales-resource-center-competitive-intelligence",
      "href": "/Intel",
      "title": "The Competitive Intelligence tab is the primary location for key content regarding AspenTech competitors. It contains Competitor Overviews, Analysts Reports, Battle Cards, Presentations, and other relevant assets that will enable you to understand the competitive landscape."
    },
    {
      "label": "Aspen Events",
      "class": "icons_events",
      "url": "link.sales-resource-center-listing",
      "href": "/Events",
      "title": "The Aspen Events tab is the primary location for all Aspen-related Events such as Webinars, OPTIMIZE, and SKO. It also includes any special educational events on specific topics."
    },
    {
      "label": "Pricing & Promos",
      "class": "icons_adoption",
      "url": "link.sales-resource-center-listing",
      "href": "/Deployment",
      "title": "The Pricing & Promos tab is the primary location for all Pricing, Deal Management, and Promotions. Most of the content in this section is confidential and password protected. Deal Model and Pricing Calculator assets are updated throughout the year after new product releases."
    },
    {
      "label": "Partners",
      "class": "icons_partners",
      "url": "link.sales-resource-center-partners",
      "href": "/Partners",
      "title": "The Partners tab is the primary location for all assets relevant to the Partners program. Content on Global Program, ISP Information, Newsletters, Success Stories, and others are found here."
    },
    {
      "label": "Sales Training",
      "class": "icons_training",
      "url": "link.sales-resource-center-training",
      "href": "/Training",
      "title": "The Sales Training tab is the primary location for all Sales Enablement content. It includes training for Onboarding, Salesforce, Deal Model, Industries, Aspen Solutions, New Product Releases, and other sales-related training."
    }
  ],
  briefcaseNav: [
    {
      "label": "Bulk Editor",
      "class": "icons_editor",
      "url": "link.editor",
      "href": "/Editor",
      "roles": [
        "isEditor"
      ],
      "icon": <FaRegListAlt />
    },
    {
      "label": "My Briefcase",
      "class": "icons_briefcase",
      "url": "link.sales-resource-center-my-briefcase",
      "href": "/Briefcase",
      "title": "The My Briefcase tab is where all assets (content) that are downloaded from the SRC are stored. All assets that are added to the My Briefcase tab are automatically added to each user’s OneDrive in a folder titled SRC Briefcase. Files that are stored in the My Briefcase are automatically updated when changed. These changes will also be reflected in the user’s OneDrive folder."
    },
    {
      "label": "Salesforce",
      "class": "icons_salesforce",
      "href": "https://aspentech.my.salesforce.com",
      "target": "at_salesforce",
      "title": "The Salesforce tab is a link that will take each user with a Salesforce license to the application."
    }
  ]
}