import React from 'react';
import './Modal.scss';
import { connect } from 'react-redux';
import bem from "../../helpers/bem"

class Modal extends React.Component {
    constructor(props) {
        super(props)
    }
    close = () => this.props.onClose && this.props.onClose()
    render = () => {
        const {
            children,
            className,
            title,
            scrollable,
            fixedTitle
        } = this.props

        return (
            <div className={bem("modal", "", [scrollable && "scrollable", fixedTitle && "fixed-title"], className)}>
                <div className={bem("modal", "backdrop")} onClick={this.close}></div>
                <div className={bem("modal", "wrapper")}>
                    {fixedTitle && title &&
                        <div className={bem("modal", "title")}>{title}</div>
                    }
                    <div className={bem("modal", "content")}>
                        {!fixedTitle && title &&
                            <div className={bem("modal", "title")}>{title}</div>
                        }
                        {children}
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = state => {
    return { 
        state: {
            ...state
        }
    };
};


export default connect(mapStateToProps)(Modal);