import React from 'react';
import bem from '../../helpers/bem';
import Modal from '../modal/Modal';
import './Explain.scss';

export class Explain extends React.Component {
    
    constructor(props) {
      super(props);
      this.state = {
          show: false
      };
    }
  
    render() {
        if (this.props.explanation) {
            return (
                <div className={"explain" + (this.state.show ? " explain--active" : "")}>
                    <a className="c-card__action c-card__action--explain c-btn c-btn--default c-btn--inverse" onClick={() => this.setState({show: true })}>Explain</a>
                    {this.state.show && 
                        <Modal className={bem("explain", "modal")} onClose={() => this.setState({show: false })} scrollable={true}>
                            <div className="explain__body" dangerouslySetInnerHTML={{ __html: this.props.explanation }}></div>
                        </Modal>
                    }
                </div>
            )
        }
    }
  }