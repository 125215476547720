import { InteractionStatus } from "@azure/msal-browser";
import axios from "axios"
import store from "../redux/store"

const isLocal = !!(window.location.hostname === "localhost");
const API_URL = window.location.protocol + '//' + window.location.host + "/api" ;
// const auth = JSON.parse(localStorage.getItem("auth"));
const httpFactory = (settings) => {
    const httpClient = axios.create({
        baseURL: API_URL,
        responseType: "json",
        responseEncoding: 'utf8'
    });
    
    httpClient.interceptors.request.use(
        config => {
            if (isLocal) {
                //config.url += ".json"
            }
            const state = store.getState()
            if (state.userReducer?.accessToken && !settings?.anonymous) {
                config.headers.common['Authorization'] = `Bearer ${state.userReducer.accessToken}`
            }

            if (config.data instanceof FormData) {
                config.headers.common["Content-Type"] = "multipart/form-data"
            }
            
            return config;
        }, 
    
        error => {
            if (!axios.isCancel(error)) { 
                return Promise.reject(error)
            }
        }
    );
    
    httpClient.interceptors.response.use(
    
        response => {
            // status code range of 2xx
            return response;
        }, 
        
        error => {
            //if (string.Equals(base.SubError, "basic_action", StringComparison.OrdinalIgnoreCase)) {
            //    return UiRequiredExceptionClassification.BasicAction;
            //}
            //if (string.Equals(base.SubError, "additional_action", StringComparison.OrdinalIgnoreCase)) {
            //    return UiRequiredExceptionClassification.AdditionalAction;
            //}
            //if (string.Equals(base.SubError, "message_only", StringComparison.OrdinalIgnoreCase)) {
            //    return UiRequiredExceptionClassification.MessageOnly;
            //}
            //if (string.Equals(base.SubError, "consent_required", StringComparison.OrdinalIgnoreCase)) {
            //    return UiRequiredExceptionClassification.ConsentRequired;
            //}
            //if (string.Equals(base.SubError, "user_password_expired", StringComparison.OrdinalIgnoreCase)) {
            //    return UiRequiredExceptionClassification.UserPasswordExpired;
            //}


            if (!axios.isCancel(error)) {
                switch (error?.response?.status) {
                    case 400:
                        if (error.response?.data?.action == "ConsentRequired" && settings.onConsentRequired) {
                            settings.onConsentRequired(error.response.data.data)
                        }
                        break;
                    case 401:
                        return Promise.reject(error);
                        break;
                    default:
                        return Promise.reject(error);
                        break;
                }
            }
        }
    );

    return httpClient;
}


const ImageCacheFactory = () => {
    const httpClient = axios.create({
        baseURL: API_URL,
        responseType: "blob"
    });
    
    httpClient.interceptors.request.use(
        config => {
            const state = store.getState()
            if (state.userReducer?.accessToken) {
                config.headers.common['Authorization'] = `Bearer ${state.userReducer.accessToken}`
            }

            return config
        }, 
    
        error => {
            if (!axios.isCancel(error)) { 
                return Promise.reject(error)
            }
        }
    );
    
    httpClient.interceptors.response.use(
    
        response => {
            // status code range of 2xx
            return response
        }, 
        
        error => {

            if (!axios.isCancel(error)) { 
                if (error?.response?.status === 401) {
                    return Promise.reject(error)
                } 
                else {
                    return Promise.reject(error)
                } 
            }
        }
    );

    return httpClient
}

export const ImageCacheInstance = ImageCacheFactory()


const FileUploadFactory = () => {
    const httpClient = axios.create({
        baseURL: API_URL,
        responseType: "text"
    });
    
    httpClient.interceptors.request.use(
        config => {
            const state = store.getState()
            if (state.userReducer?.accessToken) {
                config.headers.common['Authorization'] = `Bearer ${state.userReducer.accessToken}`
            }

            return config
        }, 
    
        error => {
            if (!axios.isCancel(error)) { 
                return Promise.reject(error)
            }
        }
    );
    
    httpClient.interceptors.response.use(
    
        response => {
            return {data: {link: `data:image/png;base64,${response.data}`}}
        }, 
        
        error => {
            if (!axios.isCancel(error)) { 
                if (error?.response?.status === 401) {
                    return Promise.reject(error)
                } 
                else {
                    return Promise.reject(error)
                } 
            }
        }
    );

    return httpClient
}

export const FileUploadInstance = FileUploadFactory()


export default httpFactory