import httpFactory, { FileUploadInstance } from "../utils/httpClient";
import qs from "qs";

export const save = (data, type, bulk, hasFile, claimsRequest) => {
    if (hasFile) {
        const formData = new FormData();
        
        if (bulk) {
            data = data.map( item => {
                if (item?.assetContent?.name) {
                    item.filename=item?.assetContent?.name
                    formData.append("files", item.assetContent, item.assetContent.name)
                }
                return item
            })
        } else {
            formData.append("files", data.assetContent, data.assetContent.name)
        }

        formData.append("data", JSON.stringify(data) )

        data = formData
    }
    
    
    return httpFactory({
        onConsentRequired: (data) => {
            if (claimsRequest && data) {
                claimsRequest(data)
            }
        }
    }).post(`editor/${type}${hasFile ? "/files" : ""}${bulk ? "/bulk" : ""}`, data)
}

export const edit = (action, type, bulk, claimsRequest) => httpFactory({
    onConsentRequired: (data) => {
        if (claimsRequest && data) {
            claimsRequest(data)
        }
    }
}).put(`editor/${type}${bulk ? "/bulk" : ""}`, action)


export const get = (action, type, bulk, claimsRequest) => httpFactory({
    onConsentRequired: (data) => {
        if (claimsRequest && data) {
            claimsRequest(data)
        }
    }
}).get(`editor/${type}${bulk ? "/bulk" : ""}`, { 
    params: {
        ...action
    }    
})


// export const packageSave = (action, type, bulk) => httpFactory().post("packageSave", action)
// export const assetFileUpload = (file, action, type, bulk) => {
//     // Create an object of formData
//     const formData = new FormData();


//     // Update the formData object
//     formData.append(
//         "file",
//         file,
//         file.name
//     );
//     formData.append(
//         "file2",
//         file,
//         file.name
//     );

//     return FileUploadInstance.post(`editor/file/${type}${bulk ? "/bulk" : ""}`, formData, { headers: { "Content-Type": "multipart/form-data" } })
// }
