import React from 'react';
import './SearchForm.scss';
import { withRouter } from 'react-router-dom';
import Tooltip from '../tooltip/Tooltip';

class SearchForm extends React.Component { 
    
    constructor(props) {
      super(props);
      this.state = {
        searchQuery: ""
      }
    }
  
    render() {
        return (
            <form className="c-search-form" onSubmit={(event) => {
                event.preventDefault();
                if (window.location.search.length > 1) {
                    this.props.history && this.props.history.push(`/Search?query=${encodeURIComponent(this.state.searchQuery)}`);
                } else {
                    this.props.history && this.props.history.replace(`/Search?query=${encodeURIComponent(this.state.searchQuery)}`);
                }
                
            }}>
                <div
                    //title="The Search Bar allows the user to search for assets on the entire SRC application. To make your search more specific, take advantage of the filters that will appear next to your search results."
                    data-tip="The Search Bar allows the user to search for assets on the entire SRC application. To make your search more specific, take advantage of the filters that will appear next to your search results."
                    className="c-search-form__input-wrapper search">
                    <input
                        type="search" 
                        id="q" name="q" 
                        className="c-search-form__input input--small" 
                        placeholder="Search the site"
                        onChange={ event => this.setState({searchQuery: event.target.value})}
                        value={this.state.searchQuery}
                    />
                </div>
                <button className="c-search-form__label">
                    <span className="label__text">Search</span>
                    <svg className="svg-icon__search svg-icon-size svg-icons_search-dims">
                        <use xlinkHref="#icons_search"></use>
                    </svg>
                </button>

                <Tooltip />
            </form>
        )
    }
}

export default withRouter(SearchForm);