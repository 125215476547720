import React, { memo, useRef } from "react"
import { useDrag, useDrop } from "react-dnd";
import "./DragItem.scss"
import bem from "../../helpers/bem"
export const DragItem = function DragItem({
    name,
    index,
    moveHandler,
    children,
    className,
    path,
    accept,
    type,
    greedy,
    placeholder=false,
    drag=true,
    drop=true
}) {

    let elementRef = useRef(null),
        originalPath = [...path],
        c = [className]

    //DRAG
        const [dragItemContext, dragRef] = useDrag({
            type,
            item: { index, name, type, path },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
                didDrop: monitor.didDrop(),
            }),
            end: (dragItem, monitor) => {
                let revert = true;

                if (monitor.didDrop()) {
                    revert = false
                    //console.log("DROP", monitor.getDropResult())
                    const newPath = moveHandler(dragItem, dragItem.path, dragItem.dropPath)
                    originalPath = [...newPath]
                    dragItem.path = [...newPath]
                } 
                
                if (revert) {
                    //console.log("NO DROP", dragItem.path)
                    const newPath = moveHandler(dragItem, dragItem.path, originalPath)
                    dragItem.path = [...newPath]
                }
                
                delete dragItem.dropPath
            }
        })
        // [path, originalPath, moveHandler],

        if (drag) {
            c.push(
                bem("drag-item", "", [
                    dragItemContext.isDragging && "dragging",
                    dragItemContext.didDrop && "dropped"
                ])
            )
        }

    //DROP
        const [dropItemContext, dropRef] = useDrop({
            accept,
            hover: (dragItem, monitor) => {
                if (!elementRef.current) return
                // Don't replace items with themselves
                if (JSON.stringify(dragItem.path) !== JSON.stringify(path)) {
                    if (monitor.isOver({shallow: true}) && monitor.canDrop()) {
                        dragItem.dropPath = [...path]
                    }
                }
            },
            canDrop: dragItem => {
                var ret = false

                if (JSON.stringify(dragItem.path) !== JSON.stringify(path)) {
                    switch ( typeof accept ) {
                        case "string": {
                            ret =  (accept === dragItem.type)
                            break
                        }
                        case "object": {
                            ret = (accept.indexOf(dragItem.type) > -1)
                            break
                        } 
                    }
                }

                //console.log(dragItem, dropItemContext)

                return ret
            },
            
            //return dragItem, end() will receive this and take action
            drop: dragItem => dragItem,
            collect: monitor => ({
                canDrop: monitor.canDrop(),
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
            })
        })

    if (drop) {
        c.push(
            bem("drop-target", "", [
                dropItemContext.isOver && !dropItemContext.isOverCurrent && "hover-child",
                dropItemContext.isOverCurrent && "hover",
                dropItemContext.isOverCurrent ? dropItemContext.canDrop ? "allowed" : "denied" : ""
            ])
        )
    }



    let content = {
        show: true,
        params: {
            children,
            className: c.join(" ")
        }
    }

    let ph = {
        show: false,
    }

    if (placeholder && dropItemContext.isOverCurrent && dropItemContext.canDrop) {
        content = {
            show: true,
            params: {
                className: bem("drag-item", "", ["placeholder"], c.join(" "), "m")
            }
        }

        ph = {
            show: true,
            params: {
                children,
                className
            }
        }

    }

    let getRef = el => {
        if (drag && drop) {
            return dragRef(dropRef(el))
        } else if (drag) {
            return dragRef(el)
        } else if (drop) {
            return dropRef(el)
        }
    }

    return (
        <>
            {content.show && 
                <li ref={getRef(elementRef)} {...content.params} />
            }
            {placeholder && ph.show && 
                <li {...ph.params} />
            }
        </>
    )
}
