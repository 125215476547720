import bem from "../../helpers/bem";

export const FIELDS_MAPPING = {
  Publish: "published",
  "Content Type ID": "contentType",
  "Package Type ID": "packageType",
  Confidentiality: "confidentiality",
  Competitor: "competitor",
  Customer: "customer",
  Language: "language",
  "Year Published": "yearPublished",
  Downloadable: "downloadable",
  Region: "region",
  "Sales Role": "salesRole",
  "Sales Stage": "salesStage",
  "Product Version": "productVersion",
  Application: "application",
  Product: "product",
  "Suite Area": "suiteArea",
  Suite: "suite",
  Industry: "industry",
  "Target Audience": "audience",
  "Sales Play Type": "salesPlayType",
};

export const assetTableColumns = [
  {
    key: "title",
    data: "Asset Title",
    className: bem("column", "", ["fixed", "large", "title"]),
  },
  {
    key: "description",
    data: "Description",
    className: bem("column", "", ["x-large"]),
  },
  {
    key: "contentType",
    data: "Content Type",
    className: bem("column", "", ["large"]),
  },
  { key: "fileName", data: "File", className: bem("column", "", ["medium"]) },
  { key: "imageName", data: "Image", className: bem("column", "", ["medium"]) },
  {
    key: "confidentiality",
    data: "Confidentiality",
    className: bem("column", "", ["small"]),
  },
  {
    key: "downloadable",
    data: "Downloadable",
    className: bem("column", "", ["small"]),
  },
  {
    key: "publishYear",
    data: "Publish Year",
    className: bem("column", "", ["small"]),
  },
  {
    key: "published",
    data: "Publish",
    className: bem("column", "", ["small"]),
  },
  {
    key: "language",
    data: "Language",
    className: bem("column", "", ["small"]),
  },
  {
    key: "salesPlayType",
    data: "Sales Play Type",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "salesRole",
    data: "Sales Role",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "salesStage",
    data: "Sales Stage",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "audience",
    data: "Audience",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "industry",
    data: "Industry",
    className: bem("column", "", ["medium"]),
  },
  { key: "region", data: "Region", className: bem("column", "", ["medium"]) },
  { key: "suite", data: "Suite", className: bem("column", "", ["medium"]) },
  {
    key: "suiteArea",
    data: "Suite Area",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "application",
    data: "Application",
    className: bem("column", "", ["medium"]),
  },
  { key: "product", data: "Product", className: bem("column", "", ["medium"]) },
  {
    key: "productVersion",
    data: "Product Version",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "competitor",
    data: "Competitor",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "customer",
    data: "Customer",
    className: bem("column", "", ["medium"]),
  },
];

export const packageTableColumns = [
  {
    key: "title",
    data: "Package Title",
    className: bem("column", "", ["fixed", "large", "title"]),
  },
  {
    key: "description",
    data: "Description",
    className: bem("column", "", ["x-large"]),
  },
  {
    key: "contentType",
    data: "Content Type",
    className: bem("column", "", ["large"]),
  },
  { key: "fileName", data: "File", className: bem("column", "", ["medium"]) },
  { key: "imageName", data: "Image", className: bem("column", "", ["medium"]) },
  {
    key: "confidentiality",
    data: "Confidentiality",
    className: bem("column", "", ["small"]),
  },
  {
    key: "downloadable",
    data: "Downloadable",
    className: bem("column", "", ["small"]),
  },
  {
    key: "publishYear",
    data: "Publish Year",
    className: bem("column", "", ["small"]),
  },
  {
    key: "published",
    data: "Publish",
    className: bem("column", "", ["small"]),
  },
  {
    key: "language",
    data: "Language",
    className: bem("column", "", ["small"]),
  },
  {
    key: "salesPlayType",
    data: "Sales Play Type",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "salesRole",
    data: "Sales Role",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "salesStage",
    data: "Sales Stage",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "audience",
    data: "Audience",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "industry",
    data: "Industry",
    className: bem("column", "", ["medium"]),
  },
  { key: "region", data: "Region", className: bem("column", "", ["medium"]) },
  { key: "suite", data: "Suite", className: bem("column", "", ["medium"]) },
  {
    key: "suiteArea",
    data: "Suite Area",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "application",
    data: "Application",
    className: bem("column", "", ["medium"]),
  },
  { key: "product", data: "Product", className: bem("column", "", ["medium"]) },
  {
    key: "productVersion",
    data: "Product Version",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "competitor",
    data: "Competitor",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "customer",
    data: "Customer",
    className: bem("column", "", ["medium"]),
  },
];
