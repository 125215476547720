import httpFactory from "../utils/httpClient";
import qs from "qs";

export const searchQueryService = action => httpFactory().post("search", action.params)

export const searchMLTService = action => httpFactory().get("more-like-this", { 
    params: {
        ...action.params
    },
    paramsSerializer: function( params ) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
    }
})

export const suggestQueryService = action => httpFactory().get("suggest", {
    params: {
        ...action.params
    }
})