import { put, call } from 'redux-saga/effects';
import { searchQueryService } from '../../services/searchService';
import * as types from '../actions';

export function* assetsSaga(params) {

  try {
      const res = yield call(searchQueryService, params); 
      
      yield put({
          type: types.PUBLIC_LOAD_ASSETS_SUCCESS,
          ...res.data.data
    });

  } catch(error) {

    yield put({ 
      type: types.PUBLIC_LOAD_ASSETS_ERROR, 
      message: error.message
    });

  }
}