import {
  CheckboxGroup,
  Input,
  Select,
  TextArea,
  Button,
} from "ui-kit.aspentech.com/dist/components";
import { AssetCard } from "../cards/AssetCard";
import Modal from "../modal/Modal";
import Images from "../../screens/editor/Images";
import bem from "../../helpers/bem";

export const CURRENT_YEAR = new Date().getFullYear();

/**
 * maps suite ids to variables
 */
export const SUITES = {
  AIoT: "1d9c6b3d-a4e2-4a70-ad46-67f0edd96213",
  APM: "bc8ea72f-3100-4224-b63f-b3b155b80e8a",
  ENG: "76726547-3071-413a-87c5-c641d8438606",
  MSC: "8d7d379e-4f9f-48c7-a235-2be7f9f33e32",
};

/**
 * maps what suite areas to show when a suite is selected
 */
export const SUITE_AREA_PAIRINGS = {
  AIoT: [],
  APM: ["90a6809e-c435-4c5c-8863-aba7d31f1aba"], // "Asset Performance Management"
  ENG: ["2ed9a6f9-3f21-4911-b81d-cfd161627fd1"], // "Performance Engineering"
  MSC: [
    "6d7ec6e8-062b-4bb9-b899-30a54d7a7a87", // APC
    "06992c77-5a6a-4bbe-9c8c-b5e8f5300a87", // MES
    "15efefae-2186-4a4b-934a-20183b505991", // Production Optimization
    "2fee2496-b2ee-4a76-9e52-508a07145519", // PSC
    "2a885475-6ee6-4e66-b1ce-d85576ea8372", // SCM
    "54815b19-a22e-48fa-9433-5d3cd11e0f56", // Value Chain Optimization
  ],
};

export const DEFAULT_FORM_DATA = {
  publishYear: CURRENT_YEAR,
  confidentiality: "Confidential – Restricted",
  downloadable: "No",
  published: "Yes",
  salesRole: [
    "00eaa134-d403-44f3-8776-785e85de6d12",
    "a595567a-aca8-4090-ae1b-c2f9a808b8b6",
    "a93e7827-604d-4198-a728-5019bf453c50",
    "a8e58ba4-c1ed-4fa9-9ffb-b57e80fc78c5",
  ], // ["SAM", "iSAM", "Solution SC", "SC"]
  salesStage: ["b65b4287-d932-4748-b36e-97b2f48bdc7e"], // ["Stage 5 - Develop Power"]
  language: "English",
  audience: ["All", "User"]
};

export const TABLE_COLUMNS = [
  {
    key: "title",
    data: "Asset Title",
    className: bem("column", "", ["fixed", "large", "title"]),
  },
  {
    key: "description",
    data: "Description",
    className: bem("column", "", ["x-large"]),
  },
  {
    key: "contentType",
    data: "Content Type",
    className: bem("column", "", ["large"]),
  },
  { key: "fileName", data: "File", className: bem("column", "", ["medium"]) },
  { key: "imageName", data: "Image", className: bem("column", "", ["medium"]) },
  {
    key: "confidentiality",
    data: "Confidentiality",
    className: bem("column", "", ["small"]),
  },
  {
    key: "downloadable",
    data: "Downloadable",
    className: bem("column", "", ["small"]),
  },
  {
    key: "publishYear",
    data: "Publish Year",
    className: bem("column", "", ["small"]),
  },
  {
    key: "published",
    data: "Publish",
    className: bem("column", "", ["small"]),
  },
  {
    key: "language",
    data: "Language",
    className: bem("column", "", ["small"]),
  },
  {
    key: "salesPlayType",
    data: "Sales Play Type",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "salesRole",
    data: "Sales Role",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "salesStage",
    data: "Sales Stage",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "audience",
    data: "Audience",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "industry",
    data: "Industry",
    className: bem("column", "", ["medium"]),
  },
  { key: "region", data: "Region", className: bem("column", "", ["medium"]) },
  { key: "suite", data: "Suite", className: bem("column", "", ["medium"]) },
  {
    key: "suiteArea",
    data: "Suite Area",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "application",
    data: "Application",
    className: bem("column", "", ["medium"]),
  },
  { key: "product", data: "Product", className: bem("column", "", ["medium"]) },
  {
    key: "productVersion",
    data: "Product Version",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "competitor",
    data: "Competitor",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "customer",
    data: "Customer",
    className: bem("column", "", ["medium"]),
  },
];

function getSuiteAreaOptions(formData, options) {
  let availableOptions = [];
  if (formData && formData.suite) {
    formData.suite.forEach((suite) => {
      switch (suite) {
        case SUITES.APM: // APM
          availableOptions = availableOptions.concat(
            options.filter((option) =>
              SUITE_AREA_PAIRINGS.APM.includes(option.value)
            )
          );
          break;
        case SUITES.ENG: // ENG
          availableOptions = availableOptions.concat(
            options.filter((option) =>
              SUITE_AREA_PAIRINGS.ENG.includes(option.value)
            )
          );
          break;
        case SUITES.MSC: // MSC
          availableOptions = availableOptions.concat(
            options.filter((option) =>
              SUITE_AREA_PAIRINGS.MSC.includes(option.value)
            )
          );
          break;
        case SUITES.AIoT: // AIoT
        default:
          break;
      }
    });
  }

  availableOptions.sort((a, b) => {
    return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
  });
  return availableOptions;
}

function checkSuiteArea(selectedSuites, formData) {
  const missingSuites = Object.values(SUITES).filter(
    (suite) => !selectedSuites.suite.includes(suite)
  );
  if (formData?.suiteArea) {
    let newSuiteAreas = [...formData?.suiteArea];
    missingSuites.forEach((suiteId) => {
      const suiteName = Object.keys(SUITES).find(
        (key) => SUITES[key] === suiteId
      );
      newSuiteAreas = newSuiteAreas.filter(
        (suiteArea) => !SUITE_AREA_PAIRINGS[suiteName].includes(suiteArea)
      );
    });

    return newSuiteAreas;
  }
  return [];
}


/**
 * configured sections -> fields  to append to base form  for new records
 */
export const addNewFields = [
  {
    className: "count-field",
    fields: [
      (props) => (
        <Input
          {...props}
          key="count"
          id="count"
          type="number"
          label="How many new assets would you like to create?"
        />
      ),
    ],
  },
  {
    orientation: "vertical",
    fields: [
      () => (
        <h3 key="infoText">Select the common fields between all assets:</h3>
      ),
    ],
  },
];

/**
 * configured sections -> fields  to append to base form  for editing records
 */
export const singleEditFields = [
  {
    orientation: "vertical",
    sections: [
      {
        orientation: "vertical",
        gap: true,
        className: "image-picker",
        fields: [
          (props) => (
            <div key="card-preview" className="card-preview">
              <AssetCard
                key={props.formData?.imageId || props.formData?.url}
                data={{
                  title: props.formData?.title,
                  description: props.formData?.description,
                  publish_year: props.formData?.publishYear,
                  confidentiality: props.formData?.confidentiality,
                  content_type: props.formData?.contentTypeName,
                  is_downloadable: props.formData?.downloadable === "Yes",
                  src: props.formData?.url,
                }}
              />
              <Input
                key="image"
                id="image"
                className="hidden"
                value={props.formData?.imageName || null}
              />
            </div>
          ),
        ],
      },
      {
        orientation: "horizontal",
        numItemsWide: 2,
        className: "form-actions",
        gap: true,
        fields: [
          (props) => (
            <Button
              {...props}
              key="assetImage"
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                props.formProps?.setShowImagePickerModal(true);
              }}
            >
              Choose Asset Image
            </Button>
          ),
          (props) => (
            <Input
              {...props}
              key="imageName"
              id="imageName"
              value={props.formData?.imageName}
              disabled
            />
          ),
          (props) => {
            return (
              props.formProps?.showImagePickerModal && (
                <Modal
                  key="image-library"
                  className="image-library"
                  title={<h2>Image Library</h2>}
                  onClose={() => {
                    props.formProps?.setShowImagePickerModal(false);
                  }}
                  fixedTitle
                >
                  <Images
                    onChange={props.onChange}
                    setShowImagePickerModal={
                      props.formProps?.setShowImagePickerModal
                    }
                  />
                </Modal>
              )
            );
          },
          (props) => (
            <>
              <Input
                {...props}
                key="assetContent"
                id="assetContent"
                className="visually-hidden"
                type="file"
                onChange={(valObj) => {
                  props.onChange({
                    fileName: valObj.assetContent.name,
                    assetContent: valObj.assetContent,
                  });
                }}
              />
              <div className="file-upload">
                <label htmlFor="assetContent">Upload Asset Content</label>
              </div>
            </>
          ),
          (props) => (
            <Input
              {...props}
              key="fileName"
              id="fileName"
              value={props.formData?.fileName}
              disabled
            />
          ),
        ],
      },
    ],
  },
];


/**
 * configured sections -> fields/validation of base form
 */
export const formConfig = {
  /**
   * each function within  validation object consists of a Key ( form field name ) and a fnction that will return an array. The returned array should
   * be an array of error messages... an empty array means valid
   */
  validation: {
    count: (value, _formData, formProps) => {
      if (formProps.editMode === "add-new") {
        if (!value) return ["Count is required"];
        if (value <= 0) return ["Invalid count"];
        return [];
      }
      return [];
    },
    fileName: (value, _formData, formProps) => {
      if (formProps.editMode === "edit")
        return value ? [] : ["Content is required"];
      return [];
    },
    title: (value, _formData, formProps) => {
      if (formProps.editMode === "edit" || formProps.editMode === "edit-existing")
        return value ? [] : ["Title is required"];
      return [];
    },
    description: (value, _formData, formProps) => {
      if (formProps.editMode === "edit" || formProps.editMode === "edit-existing")
        return value ? [] : ["Description is required"];
      return [];
    },
    publishYear: (value, _formData, formProps) => {
      if (formProps.editMode === "edit" || formProps.editMode === "edit-existing")
        return value ? [] : ["Publish Year is required"];
      return [];
    },
    confidentiality: (value, _formData, formProps) => {
      if (formProps.editMode === "edit" || formProps.editMode === "edit-existing")
        return value ? [] : ["Confidentiality is required"];
      return [];
    },
    downloadable: (value, _formData, formProps) => {
      if (formProps.editMode === "edit" || formProps.editMode === "edit-existing")
        return value ? [] : ["Downloadable is required"];
      return [];
    },
    published: (value, _formData, formProps) => {
      if (formProps.editMode === "edit" || formProps.editMode === "edit-existing")
        return value ? [] : ["Publish is required"];
      return [];
    },
    contentType: (value, _formData, formProps) => {
      if (formProps.editMode === "edit" || formProps.editMode === "edit-existing")
        return value ? [] : ["Content Type is required"];
      return [];
    },
    salesRole: (value, _formData, formProps) => {
      if (formProps.editMode === "edit" || formProps.editMode === "edit-existing")
        return value ? [] : ["Sales Role is required"];
      return [];
    },
  },
  orientation: "vertical",
  gap: true,
  sections: [
    {
      orientation: "vertical", 
      fields: [
        (props) => (
          <Input {...props} key="title" id="title" label="Title" />
        ),
        (props) => (
          <TextArea
            {...props}
            key="description"
            id="description"
            label="Description"
          />
        ),
      ],
    },
    {
      orientation: "horizontal",
      sections: [
        {
          orientation: "vertical",
          sections: [
            {
              orientation: "horizontal",
              fields: [
                (props) => (
                  <Input
                    {...props}
                    key="publishYear"
                    id="publishYear"
                    className="publish-year"
                    type="number"
                    label="Publish Year"
                  />
                ),
                (props) => (
                  <Select
                    {...props}
                    key="confidentiality"
                    id="confidentiality"
                    label="Confidentiality"
                    placeholder="Select..."
                    value={props.formData?.confidentiality}
                    options={
                      props.formProps?.fieldOptions?.confidentiality || []
                    }
                    onChange={(valObj) => {
                      if (
                        valObj.confidentiality === "Confidential – Restricted"
                      ) {
                        props.onChange({ ...valObj, downloadable: "No" });
                      } else {
                        props.onChange(valObj);
                      }
                    }}
                  />
                ),
                (props) => (
                  <Select
                    {...props}
                    key="downloadable"
                    id="downloadable"
                    label="Downloadable"
                    placeholder="Select..."
                    value={props.formData?.downloadable}
                    disabled={
                      props.formData?.confidentiality ===
                      "Confidential – Restricted"
                    }
                    options={props.formProps?.fieldOptions?.downloadable || []}
                  />
                ),
                (props) => (
                  <Select
                    {...props}
                    key="published"
                    id="published"
                    label="Publish"
                    placeholder="Select..."
                    value={props.formData?.published}
                    options={props.formProps?.fieldOptions?.published || []}
                  />
                ),
              ],
            },
            {
              orientation: "horizontal",
              fields: [
                (props) => (
                  <Select
                    {...props}
                    key="contentType"
                    id="contentType"
                    label="Content Type"
                    placeholder="Select..."
                    options={props.formProps?.fieldOptions?.contentType || []}
                    onChange={(valObj) => {
                      //console.log(props.formProps.fieldOptions);
                      const contentTypeName =
                        props.formProps?.fieldOptions?.contentType.find(
                          (option) => option.value === valObj.contentType
                        ).label;
                      props.onChange({
                        contentTypeName,
                        contentType: valObj.contentType,
                      });
                    }}
                  />
                ),
                (props) => (
                  <Select
                    {...props}
                    key="language"
                    id="language"
                    label="Language"
                    placeholder="Select..."
                    value={props.formData?.language}
                    options={props.formProps?.fieldOptions?.language || []}
                  />
                ),
                (props) => (
                  <Select
                    {...props}
                    key="salesPlayType"
                    id="salesPlayType"
                    label="Sales Play Type"
                    placeholder="Select..."
                    value={props.formData?.salesPlayType}
                    options={props.formProps?.fieldOptions?.salesPlayType || []}
                  />
                ),
              ],
            },
          ],
        },
      ],
    },
    {
      orientation: "horizontal",
      numItemsWide: 4,
      gap: true,
      fields: [
        (props) => (
          <CheckboxGroup
            {...props}
            scrollable
            collapsible
            filterable
            key="salesRole"
            id="salesRole"
            label="Sales Role"
            options={props.formProps?.fieldOptions?.salesRole || []}
          />
        ),
        (props) => (
          <CheckboxGroup
            {...props}
            scrollable
            collapsible
            filterable
            key="salesStage"
            id="salesStage"
            label="Sales Stage"
            options={props.formProps?.fieldOptions?.salesStage || []}
          />
        ),
        (props) => (
          <CheckboxGroup
            {...props}
            scrollable
            collapsible
            filterable
            key="audience"
            id="audience"
            label="Audience"
            options={props.formProps?.fieldOptions?.audience || []}
          />
        ),
        (props) => (
          <CheckboxGroup
            {...props}
            scrollable
            collapsible
            filterable
            key="industry"
            id="industry"
            label="Industry"
            options={props.formProps?.fieldOptions?.industry || []}
          />
        ),
        (props) => (
          <CheckboxGroup
            {...props}
            scrollable
            collapsible
            filterable
            key="region"
            id="region"
            label="Region"
            options={props.formProps?.fieldOptions?.region || []}
          />
        ),
        (props) => (
          <CheckboxGroup
            {...props}
            scrollable
            collapsible
            key="suite"
            id="suite"
            label="Suite"
            options={props.formProps?.fieldOptions?.suite || []}
            onChange={(valObj) => {
              const newSuiteAreas = checkSuiteArea(valObj, props.formData);
              const newValObj = {
                ...valObj,
                suiteArea: newSuiteAreas,
              };
              props.onChange(newValObj);
            }}
          />
        ),
        (props) => (
          <CheckboxGroup
            {...props}
            scrollable
            collapsible
            key="suiteArea"
            id="suiteArea"
            label="Suite Area"
            options={
              getSuiteAreaOptions(
                props.formData,
                props.formProps?.fieldOptions?.suiteArea || []
              ) || []
            }
          />
        ),
        // (props) => (
        //   <CheckboxGroup
        //     {...props}
        //     scrollable
        //     collapsible
        //     filterable
        //     key="application"
        //     id="application"
        //     label="Application"
        //     options={props.formProps?.fieldOptions?.application || []}
        //   />
        // ),
        (props) => (
          <CheckboxGroup
            {...props}
            scrollable
            collapsible
            filterable
            key="product"
            id="product"
            label="Product"
            options={props.formProps?.fieldOptions?.product || []}
          />
        ),
        (props) => (
          <CheckboxGroup
            {...props}
            scrollable
            collapsible
            filterable
            key="productVersion"
            id="productVersion"
            label="Product Version"
            options={props.formProps?.fieldOptions?.productVersion || []}
          />
        ),
        (props) => {
          return (
            [
              "Battle card",
              "Competitor Overview",
              "Win Loss Analysis",
              "Customer Wins",
              "Analyst Report",
              "Presentation",
              "Document",
              "Video",
            ].includes(props.formData?.contentTypeName) && (
              <CheckboxGroup
                {...props}
                scrollable
                collapsible
                filterable
                key="competitor"
                id="competitor"
                label="Competitor Name"
                options={props.formProps?.fieldOptions?.competitor || []}
              />
            )
          );
        },
        (props) => {
          return (
            ["Case Study", "Customer Success Story - PDF"].includes(
              props.formData?.contentTypeName
            ) && (
              <CheckboxGroup
                {...props}
                scrollable
                collapsible
                filterable
                key="customer"
                id="customer"
                label="Customer Name"
                options={props.formProps?.fieldOptions?.customer || []}
              />
            )
          );
        },
      ],
    },
    {
      fields: [
        (props) => (
          <Button {...props} variant="primary" key="formSubmit" id="formSubmit">
            Submit
          </Button>
        ),
      ],
    },
  ],
};
