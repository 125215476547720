import { fork } from 'redux-saga/effects';
import {
    watchSearchQuery,
    watchSearchMLT,
    watchSearchMLTRemove,
    watchLoadConfig,
    watchSuggestQuery,
    watchSuggestClear,
    watchPackages,
    watchAssets,
    watchBriefcase,
    watchProfile,
    watchProfileIndustryChange,
    watchBriefcaseRemove,
    watchBriefcaseLoadAssets,
    watchAuth,
    watchIsEditor,
    watchTakeSnapshot,
    watchImportSnapshot,
    watchIsArchitect
} from './watchers';

export default function* startForman() {
    yield fork(watchSearchQuery);
    yield fork(watchSearchMLT);
    yield fork(watchSearchMLTRemove);
    yield fork(watchLoadConfig);
    yield fork(watchSuggestQuery);
    yield fork(watchSuggestClear);
    yield fork(watchPackages);
    yield fork(watchAssets);
    yield fork(watchBriefcase);
    yield fork(watchProfile);
    yield fork(watchProfileIndustryChange);
    yield fork(watchBriefcaseRemove);
    yield fork(watchBriefcaseLoadAssets);
    yield fork(watchAuth);
    yield fork(watchIsEditor);
    yield fork(watchIsArchitect);
    yield fork(watchTakeSnapshot);
    yield fork(watchImportSnapshot);
}