import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { FaFilter, FaPen, FaTrash, FaPlus } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import bem from "../../helpers/bem";
import {
  Button,
  Table,
  Checkbox,
  Section,
} from "ui-kit.aspentech.com/dist/components";
import Modal from "../../components/modal/Modal";
import Loading from "../../components/loading/Loading";
import EditorForm from "../../components/editorForm/EditorForm";
import * as editorService from "../../services/editorService";
import * as editorTableService from "./EditorTable.service";
import "./Editor.scss";
import { FIELDS_MAPPING } from "./Editor.config";

const EditorTable = (props) => {
  const {
    rows,
    setRows,
    currentTab,
    formConfig,
    listFields,
    listMetadata,
    claimsRequest,
  } = props;
  const columns = formConfig.TABLE_COLUMNS;
  const [selectedRows, setSelectedRows] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [defaultFormData, setDefaultFormData] = useState(
    formConfig.DEFAULT_FORM_DATA
  );
  const [editMode, setEditMode] = useState("add-new");
  const [tableHeaders, setTableHeaders] = useState({ data: [] });
  const [tableBody, setTableBody] = useState({ data: [] });
  const [editModal, setEditModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const fieldOptions = useMemo(() => {
    function parseManagedMetadata(fieldObj) {
      const id = fieldObj.TermSetId;
      const metadata = listMetadata[id];
      return Object.entries(metadata.terms).map((term) => {
        return {
          value: term[0],
          label: term[1],
        };
      });
    }

    function parseChoices(fieldObj) {
      return fieldObj.Choices.map((choice) => {
        return {
          value: choice,
          label: choice,
        };
      });
    }

    function parseChoiceObjects(fieldObj) {
      const options = fieldObj.Choices.map((choice) => {
        return {
          value: choice.Id,
          label: choice.Name,
        };
      });

      return options.sort((a, b) => a.label.localeCompare(b.label));
    }

    function getOptions() {
      const options = {};
      listFields["SRC Package Content"].forEach((fieldObj) => {
        switch (fieldObj.FieldTypeKind) {
          case 0: // Managed Metadata
            options[FIELDS_MAPPING?.[fieldObj.Title]] =
              parseManagedMetadata(fieldObj);
            break;
          case 6: // Single choice
          case 15: // Multi choice
            options[FIELDS_MAPPING?.[fieldObj.Title]] = parseChoices(fieldObj);
            break;
          case 25: // special content type choice
            options[FIELDS_MAPPING?.[fieldObj.Title]] =
              parseChoiceObjects(fieldObj);
            break;
          default:
            break;
        }
      });

      options.productVersion && options.productVersion.reverse(); // Special sorting on productVersions to have the latest version be the top option
      return options;
    }

    return getOptions();
  }, [listFields, listMetadata]);

  const selectRow = useCallback(
    (valObj) => {
      let newSelectedRows = [...selectedRows];
      const checkboxId = Object.keys(valObj)[0];
      const value = Object.values(valObj)[0];

      if (checkboxId === "selectAll") {
        value
          ? (newSelectedRows = rows.map((row) => {
              return row.guid;
            }))
          : (newSelectedRows = []);
        setSelectedRows(newSelectedRows);
      } else {
        const rowId = checkboxId.split("__")[1];
        if (selectedRows.find((id) => id === rowId)) {
          setSelectedRows(newSelectedRows.filter((id) => id !== rowId));
        } else {
          setSelectedRows([...newSelectedRows, rowId]);
        }
      }
    },
    [rows, selectedRows]
  );

  const editRow = useCallback(
    (id) => {
      const row = rows.find((row) => row.guid === id);
      setDefaultFormData({
        ...row,
      });
      setSelectedRows([id]);
      setEditMode("edit");
      setEditModal(true);
    },
    [rows]
  );

  const deleteRows = useCallback(
    (idArray) => {
      const newRows = rows.filter((row) => {
        return !idArray.includes(row.guid);
      });
      setRows(newRows);
      setSelectedRows([]);
    },
    [rows, setRows]
  );

  useLayoutEffect(() => {
    const { headers, body } = editorTableService.getTableData({
      rows,
      columns,
      selectedRows,
      hiddenColumns,
      fieldOptions,
      selectRow,
      editRow,
      deleteRows,
    });
    setTableHeaders(headers);
    setTableBody(body);
  }, [
    rows,
    columns,
    selectedRows,
    hiddenColumns,
    fieldOptions,
    selectRow,
    editRow,
    deleteRows,
  ]);

  function handleAddNewRows(formData) {
    const newRows = [...rows];

    for (let i = 0; i < parseInt(formData.count); i++) {
      const row = {
        ...formData,
        guid: uuidv4(),
        title:
          formData.title ??
          (currentTab === "Assets"
            ? `Asset ${rows.length + i + 1}`
            : `Package ${rows.length + i + 1}`),
        status: {
          sharepointRecordCreated: false,
          isDirty: true,
          statusCode: "",
          message: "",
        },
      };
      delete row.count;
      newRows.push(row);
    }
    setRows(newRows);
  }

  function handleEditRows(formData) {
    let newRows = [...rows];
    selectedRows.forEach((id) => {
      const rowObj = rows.find((row) => row.guid === id);
      const rowIndex = rows.findIndex((row) => row.guid === id);
      const newRowObj = {
        ...rowObj,
        ...formData,
      };
      newRowObj.status.isDirty = true;
      newRows[rowIndex] = newRowObj;
    });
    setRows(newRows);
    setSelectedRows([]);
    setDefaultFormData({});
  }

  function filterTableColumns(valObj) {
    let newHiddenColumns = [...hiddenColumns];
    const columnName = Object.keys(valObj)[0];
    const checked = Object.values(valObj)[0];

    if (newHiddenColumns.includes(columnName) && checked) {
      newHiddenColumns = newHiddenColumns.filter(
        (column) => column !== columnName
      );
    }
    if (!newHiddenColumns.includes(columnName) && !checked) {
      newHiddenColumns = newHiddenColumns.concat(columnName);
    }

    setHiddenColumns(newHiddenColumns);
  }

  //TODO
  async function saveEntries() {
    const newRows = [...rows];
    const formattedRows = editorTableService.formatRows({
      rows: newRows,
      fieldOptions,
    }); // formatting rows for api endpoint
    try {
      window.requestAnimationFrame(() => setLoadingModal(true));
      const response = await editorService.save(
        formattedRows,
        currentTab,
        "true",
        "true",
        claimsRequest
      );
      
      Object.keys(response.data.data).forEach((id) => {
        const row = newRows.find((row) => row.guid === id);
        const statusCode = response.data.data[id].action;
        const statusMessage = response.data.data[id].message;
        const successCheck = statusCode === "201" ? true : false;

        row.status = {
          isDirty: false,
          sharepointRecordCreated: successCheck,
          statusCode: statusCode,
          message: statusMessage,
        };
      });
      setRows(newRows);
      window.requestAnimationFrame(() => setLoadingModal(false));
    } catch (e) {
      // TODO: Add logic to handle errors
      //console.log(e);
      window.requestAnimationFrame(() => setLoadingModal(false));
    }
  }

  function handleAllFilter() {
    if (hiddenColumns.length === columns.length) {
      setHiddenColumns([]);
    } else {
      let newHiddenColumns = [];
      columns.forEach((column) => {
        newHiddenColumns = newHiddenColumns.concat(column.key);
      });
      setHiddenColumns(newHiddenColumns);
    }
  }

  return (
    <div className="editor-table">
      <div className={bem("action-buttons")}>
        <Button
          className="filter-button"
          variant="action-neutral"
          onClick={() => setFilterModal(true)}
        >
          <FaFilter /> <p>Filter Columns</p>
        </Button>
        <Button
          className="edit-button"
          variant="action-neutral"
          disabled={selectedRows.length === 0}
          onClick={() => {
            setDefaultFormData({});
            setEditMode("bulk-edit");
            selectedRows.length !== 0 && setEditModal(true);
          }}
        >
          <FaPen /> <p>Edit</p>
        </Button>
        <Button
          className="delete-button"
          variant="action-warning"
          disabled={selectedRows.length === 0}
          onClick={() => {
            deleteRows(selectedRows);
          }}
        >
          <FaTrash /> <p>Delete</p>
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setEditMode("add-new");
            setDefaultFormData(formConfig.DEFAULT_FORM_DATA);
            setEditModal(true);
          }}
        >
          <FaPlus /> {`Add New ${currentTab}`}
        </Button>
      </div>
      <div className="table-wrapper">
        <Table headers={tableHeaders} body={tableBody} />
      </div>
      <div className={bem("action-buttons")}>
        <p className="table-state">
          {editorTableService.checkUnsavedRows(rows) ? "Unsaved Changes" : ""}
        </p>
        <Button
          variant="primary"
          onClick={() => saveEntries()}
          disabled={editorTableService.checkDisableSubmit({ rows, currentTab })}
        >
          {`Save ${currentTab}`}
        </Button>
      </div>

      {editModal && (
        <Modal
          title={
            editMode === "add-new" ? (
              <h2>{`Add New ${currentTab}`}</h2>
            ) : (
              <h2>Edit</h2>
            )
          }
          className="edit-modal"
          onClose={() => {
            setSelectedRows([]);
            setEditModal(false);
          }}
          fixedTitle
        >
          <EditorForm
            config={formConfig}
            editMode={editMode}
            defaultFormData={defaultFormData}
            fieldOptions={fieldOptions}
            onSuccess={ formData => {
              let f = editMode === "add-new" ? handleAddNewRows : handleEditRows
              f(formData)
              setEditModal(false);
            }}
          />
        </Modal>
      )}

      {filterModal && (
        <Modal
          className="filter-column-modal"
          title={<h2>Filter Table Columns</h2>}
          onClose={() => setFilterModal(false)}
          fixedTitle
        >
          <>
            <div className={bem("action-buttons")}>
              <Button variant="text" onClick={handleAllFilter}>
                {hiddenColumns.length === columns.length
                  ? "Show All"
                  : "Hide All"}
              </Button>
            </div>
            <Section numItemsWide={4}>
              {columns.map((column) => {
                return (
                  <Checkbox
                    key={column.key}
                    id={column.key}
                    label={column.data}
                    value={column.value}
                    checked={!hiddenColumns.includes(column.key)}
                    onChange={(valObj) => {
                      filterTableColumns(valObj);
                    }}
                  />
                );
              })}
            </Section>
          </>
        </Modal>
      )}

      {loadingModal && (
        <Modal
          className="loading-modal"
          title={<h2>{`Saving ${currentTab} to Sharepoint...`}</h2>}
          onClose={() => true}
        >
          <Loading />
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  listMetadata: state.configReducer.listMetadata,
  listFields: state.configReducer.listFields,
});
export default connect(mapStateToProps)(EditorTable);
