import React from 'react';
import './Hero.scss';

export class Hero extends React.Component {
    render() {
        return (
            <div className="c-block-hero">
                <div className="c-block-hero__wrapper">
                    <div className="c-block-hero__title">
                        <h2 className="c-block-hero__headline">Sales Resource Center</h2>
                        <h3 className="c-block-hero__sub-headline">Prospect | Convert | Win</h3>
                    </div>
                </div>
            </div>
        )
    }
}