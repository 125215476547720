import httpFactory from "../utils/httpClient";
import qs from "qs";


export const UserIsEditor = action => httpFactory().get("isEditor")
export const UserIsArchitect = action => httpFactory().get("isArchitect")

export const loadBriefcase = action => httpFactory().get("briefcase/list")

export const loadMissingBriefcaseItems = action => httpFactory().get("briefcase/missing")

export const addBriefcaseAsset = params => httpFactory().get("briefcase/add", {
    params: {
        ...params
    },
    paramsSerializer: function( params ) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
    }
})

export const removeBriefcaseAsset = action => httpFactory().get("briefcase/delete", {
    params: {
        ...action.params
    },
    paramsSerializer: function( params ) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
    }
})

export const loadProfile = action => httpFactory().get("profile", {
    params: {
        ...action.params
    }
})

export const profileIndustryChange = action => httpFactory().get(`industry/${action.params.action}`, {
    params: {
        industry: action.params.industry
    }
})