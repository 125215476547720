import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { loadConfigAction, updateTokenAction} from "../redux/actions/actions";
import App from './App';
import { PublicClientApplication, EventType } from "@azure/msal-browser";

const AppContainer = (props) => {
  
  useEffect(() => {
    //console.log("CONFIG", "LOAD")
    props.dispatch(loadConfigAction());
  }, []);

  if (props.state.config.initialized) {

    const msalInstance = new PublicClientApplication(props.state.config.auth.msalConfig);

    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    msalInstance.enableAccountStorageEvents();

    msalInstance.addEventCallback((event) => {
      switch (event.eventType) {
        case EventType.LOGIN_SUCCESS:
          const account = event.payload.account
          msalInstance.setActiveAccount(account)
          props.dispatch(updateTokenAction(event.payload))
          break
        case EventType.ACQUIRE_TOKEN_SUCCESS:
          props.dispatch(updateTokenAction(event.payload))
          break
        case EventType.LOGOUT_SUCCESS:
          props.dispatch(updateTokenAction({}))
          break
        default:
          break;
      }
    });

    //console.log("CONTAINER", "RENDER")
    return <App pca={msalInstance} />
  } else {
    return null;
  }
}

const mapStateToProps = state => {
  return {
    state: {
      config: state.configReducer
    }
  }
}

export default connect(mapStateToProps)(AppContainer)