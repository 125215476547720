import React, { createRef } from 'react';
import { connect } from 'react-redux';
import {
    loadBriefcaseAction,
    removeBriefcaseAction
} from "../../redux/actions/actions";

//import '../Base.scss';
import './Search.scss';
import ReactPaginate from 'react-paginate';
import Autosuggest from 'react-autosuggest';
//import moment from 'moment';
import { Element, scroller } from 'react-scroll';
import { searchMLTService, searchQueryService, suggestQueryService } from '../../services/searchService';
import produce from 'immer';
import { withMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

var autoSubmitOn = true;
var typeAheadOn = false;
var moreLikeThisOn = true;

var defaults = {
    filters: {},
    query: "",
    facetsOn: true,
    limit: 15,
    page: 0,
    sort: "score",
    order: "desc",
    mlt: [],
    children: false,
    parents: false,
};

var searchQueryParamList = [
    "filters",
    "query",
    "limit",
    "page",
    "sort",
    "order",
    "facetsOn",
    "explain",
    "children",
    "parents"
];

var cloneDeep = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            qFocused: false,
            qsValues: this.props.qsParams,
            facetGroupVisibility: [],
            mlt: [],
            showFilters: true,
            contextualFilters: {},
            suggestions: [],
            namespace: props.namespace || 'searchModalFilter' //for colliisions
        };

        this.pageRef = React.createRef();
        this.blurTimer = null;
        this.onSubmit = this.onSubmit.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.onMLTChange = this.onMLTChange.bind(this);
        this.isFilterChecked = this.isFilterChecked.bind(this);
    }

    keyDown = ({ code, ctrlKey, altKey, shiftKey }) => {        
        if (code === "ArrowLeft") {            
            this.onPrevPage()
        }
        else if (code === "ArrowRight") {
            this.onNextPage()
        }
    }

    componentDidMount = () => {
        window.addEventListener("keydown", this.keyDown)
        let qs = this.props.qsParams;        
        this.executeQuery(qs);

        try {
            var facets = this.props.state.config.ui.facets;
            var keys = Object.keys(facets);
            keys.map( key => {
                var facet = facets[key];

                if (facet.expanded) {
                    if (!this.isFacetGroupVisible(facet.name)) {
                        this.toggleFacetGroupVisibility(facet.name)
                    }
                }
            });

            this.configured = true;
        } catch (error) {
            
        }

    }

    componentWillUnmount = () => {
        window.removeEventListener("keydown", this.keyDown)
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {      
        if (JSON.stringify(this.props.qsParams) !== JSON.stringify(prevProps.qsParams)) {            
            this.executeQuery(this.props.qsParams);
        }
    }

    fetchSuggestions = async (query) => {
        if (typeAheadOn) {
            if (query && query.length) {
                try {
                    let res = await suggestQueryService({
                        params: {query: query} 
                    });
    
                    this.setState(produce(this.state, draftState => {
                        draftState.search.suggestions = res.data.data.suggest.suggest[query]?.suggestions || []
                    }))

                } catch (error) {
                    console.error(error)
                }
            } else {
                this.onSuggestionsClearRequested();
            }
        }
    }

    getSuggestionValue = function (suggestion) {
        return suggestion.term;
    }

    onSuggestionsFetchRequested = ({ value, reason }) => {
        switch (reason) {
            case 'input-focused':
            case 'escape-pressed':
            case 'suggestions-revealed':
            case 'suggestion-selected':
                break;
            case 'input-changed':
            default:
                this.fetchSuggestions(value);
                break;
        }
    }

    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        this.setState({
            qsValues: {
                ...this.state.qsValues,
                query: suggestionValue
            }
        },
            () => {
                this.submitForm();
                this.fetchSuggestions(suggestionValue);
            }
        );
    }

    onSuggestionsClearRequested = () => {
        this.setState({search: { suggestions: [] }})
    };

    renderSuggestion(suggestion) {
        return (
            <span>{suggestion.term}</span>
        );
    }
    
    isFilterChecked = (name, value) => {
        return (typeof this.state.qsValues.filters === "object" && this.state.qsValues.filters[name] && this.state.qsValues.filters[name].indexOf(value.toString()) > -1);
    }

    isMLTChecked = (value) => {
        return (moreLikeThisOn && typeof this.state.moreLikeThis === "object" && Object.keys(this.state.moreLikeThis).indexOf(value) > -1);
    }

    executeQuery = async (params, skipSubmit, mlt) => {        
        var qsValues = cloneDeep({
            ...defaults,
            ...params
        });
        
        if (!qsValues.filters) {
            qsValues.filters = "";
        };                    

        this.setState({
            qsValues
        }, async () => {

            if (!skipSubmit) {                
                Object.keys(qsValues).map(key => {
                    if (searchQueryParamList.indexOf(key) === -1) {
                        delete qsValues[key];
                    }
                });

                try {
                    let res = await searchQueryService({
                        params: {
                            ...qsValues,
                            query: qsValues.query,
                            facetFilter: "search",
                            preview: this.props.previewMode
                        } 
                    });
                    // delete res.data.data.facets.count
                    
                    this.setState({search: res.data.data})
                } catch (error) {
                    console.error(error)
                    if (error.response?.status === 401 && this.props.login) {
                        this.props.login()
                    }
                }
            }
        })
    }

    onFormChange = () => {
        this.pageRef.value = 0;

        if (autoSubmitOn && this.submitButtonRef) {
            this.submitForm();
        }
    }

    async onMLTChange(event) {
        if (moreLikeThisOn) {
            if (event.target.checked) {

                try {
                    let res = await searchMLTService({
                        params: {
                            id: event.target.value
                        } 
                    });
    
                    this.setState({
                        search: { 
                            ...this.state.search,
                            mlt: res.data.data 
                        }
                    })
                            
                } catch (error) {
                    console.error(error)
                }

            } else if (!event.target.checked && typeof this.state.moreLikeThis === "object" && Object.keys(this.state.moreLikeThis).indexOf(event.target.value) > -1) {
                this.setState({ 
                    moreLikeThis: {
                        id: event.target.value
                    }
                });
            }
        }
    }

    submitForm = () => {
        this.submitButtonRef.click();
    }

    onSubmit(event) {
        event.preventDefault()

        var params = cloneDeep(defaults);

        for (var el of event.target) {
            var name = el.name

            if (name.indexOf(`${this.state.namespace}.`) === 0) {
                name = name.substring(`${this.state.namespace}.`.length);
            }

            if (name === "query") {
                params.query = el.value;
            } else if (name === "limit") {
                params.limit = parseInt(el.value, 10);
            } else if (name === "page") {
                params.page = parseInt(el.value, 10);
            // } else if (el.name === "sort") {
            //     params.sort = el.value;
            // } else if (el.name === "order") {
            //     if (el.checked) {
            //         params.order = el.value;
            //     }
            } else if (name.indexOf("filter.") === 0) {
                name = name.substring("filter.".length);
                if (!params.filters[name]) {
                    params.filters[name] = [];
                }
                switch (el.type) {
                    case "radio":
                    case "checkbox":
                        if (el.checked) {
                            params.filters[name].push(el.value);
                        }
                        break;
                    case "submit":
                        break;
                    default:
                        params.filters[name].push(el.value);
                        break;
                }
            }
        }

        this.executeQuery({...params, ...this.props.qsParams});

        return
    }

    toggleFacetGroupVisibility = facetGroup => {
        var i = this.state.facetGroupVisibility.indexOf(facetGroup)
        if (i > -1) {
            this.state.facetGroupVisibility.splice(i, 1);
        } else {
            this.state.facetGroupVisibility.push(facetGroup);
        }

        this.setState({ facetGroupVisibility: this.state.facetGroupVisibility });
    }

    isFacetGroupVisible = facetGroup => {
        return this.state.facetGroupVisibility.indexOf(facetGroup) > -1;
    }

    getFacetValueUi = (buckets, facetFieldName, facetFieldLabel) => {
        var values = buckets.filter(facet => {
            return (facet?.val && (!this.state.contextualFilters[facetFieldName] || facet.val.toLowerCase().indexOf(this.state.contextualFilters[facetFieldName]) > -1))
        });

        if (values.length > 0) {
            return values.map(facet => {
                if (facet?.val && facet.val !== null && facet.val !== "" && facet.count !== "0" && facet.count !== 0) {
                    var label = facet.val;
                    return (
                        <div key={facet.val} className="facet__item facet__item--checkBoxes" >
                            <input id={`${this.state.namespace}.` + facetFieldName + "--" + facet.val} name={`${this.state.namespace}.filter.` + facetFieldName} value={facet.val} type="checkbox" checked={this.isFilterChecked(facetFieldName, facet.val)} onChange={this.onFormChange} />
                            <label htmlFor={`${this.state.namespace}.` + facetFieldName + "--" + facet.val}>{label} ({facet.count})</label>
                        </div>
                    )
                }
            })
        } else {
            return <div className="facet__item--no-results">No matches found in {facetFieldLabel}</div>
        }
            
    }

    getFacets = (searchData, searchConfig) => {
        if (searchData?.facets && searchConfig?.ui?.facets) {

            var facetKeys = Object.keys(searchConfig.ui.facets);

            return facetKeys.map(facetFieldName => {

                let facetUI = searchConfig.ui.facets[facetFieldName],
                    facetData = searchData.facets[facetFieldName],
                    show = true,
                    fields = Object.keys(facetUI.filters);

                if (fields && fields.length) {
                    show = false;

                    fields.map( fieldName => {
                        if (!show) {
                            try {
                                let selectedValues = this.state.qsValues.filters[fieldName],
                                    neededValues = facetUI.filters[fieldName],
                                    intersection = neededValues.filter(x => selectedValues.includes(x));
    
                                ////console.log(selectedValues, neededValues, intersection);
                                show = !!(intersection.length > 0);
                            } catch (error) {
                                
                            }
                        }
                    });
                }

                if (show && facetUI && facetData?.buckets?.length) {
                    return (
                        <div className="facets__container facets__slide" key={facetFieldName}>
                            <div className={"accordion facets__category" + (this.isFacetGroupVisible(facetFieldName) ? " active" : "")} category={facetFieldName} onClick={() => this.toggleFacetGroupVisibility(facetFieldName)}>
                                {facetUI.label}
                                <div className="icon-box">
                                    <svg className="svg-icon-size svg-icons_angle-down-dims">
                                        <use xlinkHref="#icons_angle-down" />
                                    </svg>
                                </div>
                            </div>

                            {this.isFacetGroupVisible(facetFieldName) && facetData.buckets.length >= 10 &&
                                <div className={"facets__contextualFilter facets__contextualFilter--" + facetFieldName}>
                                    <input placeholder={"Search " + facetUI.label} type="search" onChange={event => this.onContextualFilterChange(facetFieldName, event)} />
                                </div>
                            }

                            <div className={"facets__items facets__items--checkBoxes panel" + (" facets__items--" + facetFieldName) + (this.isFacetGroupVisible(facetFieldName) ? " active" : "")} category={facetFieldName}>
                                {this.getFacetValueUi(facetData.buckets, facetFieldName, facetUI.label)}
                            </div>
                        </div>
                    );
                }
            });
        }
    }

    onPageClick = data => {
        this.setState({
            qsValues: {
                ...this.state.qsValues,
                page: data.selected
            }
        }, () => {
            this.submitForm();
            var speed = this.state.qsValues.limit * 2.5;
            scroller.scrollTo('search__form', {
                duration: speed,
                delay: 100,
                smooth: true,
                offset: -25, // Scrolls to element + 50 pixels down the page
            });
        });
    }

    onLimitChange = event => {
        this.pageRef.value = 0;
        
        this.setState({
            qsValues: {
                ...this.state.qsValues,
                limit: event.target.value
            }
        },
            this.submitForm
        );
    }

    onNextPage = event => {
        
        if ( ((this.state.qsValues.page * this.state.qsValues.limit) + this.state.qsValues.limit)  < this.state.search.response.numFound) {
            this.setState({
                qsValues: {
                    ...this.state.qsValues,
                    page: this.state.qsValues.page + 1,
                }
            },
                this.submitForm
            );
        }
        
    }

    onPrevPage = event => {

        if (this.state.qsValues.page) {
            this.setState({
                qsValues: {
                    ...this.state.qsValues,
                    page: this.state.qsValues.page - 1,
                }
            },
                this.submitForm
            );
        }

    }

    onContextualFilterChange = (fieldName, event) => {
        var val = event.target.value.toLowerCase();

        var newObj = {};
        newObj[fieldName] = val;

        this.setState({
            contextualFilters: {
                ...this.state.contextualFilters,
                ...newObj
            }
        });
    }

    getHighlightFieldValue = (highlighting, doc, fieldName) => {
        if (highlighting && highlighting[doc.id] && highlighting[doc.id][fieldName]) {
            return highlighting[doc.id][fieldName];
        } else {
            return doc[fieldName];
        }
    }

    createResultItemKey(id, index, level, mltIndex) {
        mltIndex = mltIndex === undefined ? "root" : mltIndex;
        return `${id}_${index}_${level}_${mltIndex}`;
    }

    onShowFiltersChanged = (event) => {
        event.preventDefault();

        this.setState({
            showFilters: !(this.state.showFilters)
        });
    }

    isOrderChecked = (order) => {
        return this.state.qsValues.order === order;
    }
    getActiveFacetValues = () => {
        var values = [];
        let facetKeys = this.state.qsValues.filters ? Object.keys(this.state.qsValues.filters) : [];
        facetKeys.map( facetKey => {
            let facetGroup = this.state.qsValues.filters[facetKey];
            if (typeof facetGroup === "string") {
                facetGroup = [facetGroup];
            }
            facetGroup.map( facetValue => {
                var val = facetValue;

                values.push({
                    name: facetKey,
                    value: val
                })
            })
        })

        return values;
    }

    removeFacetValue = facetObj => {
        var values = {};
        if (typeof facetObj === "string" && facetObj === "*") {
            //keep as empty object;
        } else {
            values = cloneDeep(this.state.qsValues.filters);
            var i = values[facetObj.name].indexOf(facetObj.value);
            if (i > -1) {
                values[facetObj.name].splice(i, 1);
            }
        }

        this.executeQuery({
            ...this.state.qsValues,
            filters: values
        });
    }

    render() {
        if (!this.state || !this.props.state.user || !this.props.state.briefcase) {
            return null;
        }
        
        let searchConfig = this.props.state.config,
            searchData = this.state.search,
            facetValues = this.getActiveFacetValues(),
            facetUI = searchConfig?.ui?.facets || {};

        return (
            <div className={"c-search screen screen--search" + (this.state.showFilters ? " c-search__facets--active" : "")}>

                <Element name="search__form"></Element>

               {/* 
                <div className="topAd">
                    <img src="/optimize.png" alt="" className="c-card__img" />
                </div>
                */}
                
                <form id="c-insertion__form" className="l--two-col form" method="get" action="" onSubmit={this.onSubmit}>                 
                    <input type="hidden" name={`${this.state.namespace}.page`} defaultValue={this.state.qsValues.page} ref={input => this.pageRef = input}/>
                    <input type="hidden" name={`${this.state.namespace}.order`} defaultValue={this.state.qsValues.order} />                    
                    <div className="l-main">
                        <div className="top-bar">
                            <select name={`${this.state.namespace}.limit`} className="c-search__limit" defaultValue={this.state.qsValues.limit} onChange={this.onLimitChange} >
                                <option value="15" key={15}>15</option>
                                <option value="25" key={25}>25</option>
                                <option value="50" key={50}>50</option>
                            </select>
                            <div>
                                {typeAheadOn  &&
                                    <Autosuggest
                                        alwaysRenderSuggestions={false}
                                        focusInputOnSuggestionClick={false}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        suggestions={this.state.suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionSelected={this.onSuggestionSelected}
                                        getSuggestionValue={this.getSuggestionValue}
                                        renderSuggestion={this.renderSuggestion}
                                        inputProps={{
                                            type: 'search',
                                            onChange: (event, newValue) => {
                                                this.onFormChange(event);
                                            },
                                            value: this.state.qsValues.query || "",
                                            name: `${this.state.namespace}.query`,
                                            className: "c-search__input-text",
                                            placeholder: "Search",
                                            autoComplete: "off"
                                        }}
                                        theme={{
                                            container: 'c-search__input-container',
                                            containerOpen: 'c-search__input-item--open',
                                            input: 'c-search__input-text',
                                            inputOpen: 'c-search__input-text--open',
                                            inputFocused: 'c-search__input-text--focused',
                                            suggestionsContainer: 'c-search__input-item__suggestions-container',
                                            suggestionsContainerOpen: 'c-search__input-item__suggestions-container--open',
                                            suggestionsList: 'c-search__input-item__suggestions-list',
                                            suggestion: 'c-search__input-item__suggestion',
                                            suggestionFirst: 'c-search__input-item__suggestion--first',
                                            suggestionHighlighted: 'c-search__input-item__suggestion--highlighted',
                                            sectionContainer: 'c-search__input-item__section-container',
                                            sectionContainerFirst: 'c-search__input-item__section-container--first',
                                            sectionTitle: 'c-search__input-item__section-title'
                                        }}
                                    />
                                }

                                {!typeAheadOn &&
                                    <input
                                        type="search"
                                        name={`${this.state.namespace}.query`}
                                        placeholder="Search"
                                        autoComplete="off"
                                        onChange={event => this.onFormChange(event)}
                                        defaultValue={this.state.qsValues.query}
                                    />
                                }
                                <input type="submit" value="Search" className="c-search__input-submit c-btn c-btn--primary" style={autoSubmitOn ? { display: "none" } : {}} ref={button => this.submitButtonRef = button} />
                            </div>

                            {searchData?.response &&
                                <div className="c-search__count">{searchData.response.numFound} Results</div>
                            }

                            <div className="c-search__actions">
                                {facetValues.length > 0 &&
                                    <a className="c-btn c-btn--primary c-search__actions--clear" onClick={() => this.removeFacetValue("*")}>Clear All</a>
                                }
                            </div>
                         </div>


                         {facetValues.length > 0 && 
                            <div className="c-search__filters c-search__filters--inline ">
                                <div className="c-search__active-facets c-tab__list">
                                    {facetValues.map( facetObj => (
                                        <a key={ facetObj.name + "_" + facetObj.value} className="c-tab c-tab__filter" onClick={() => this.removeFacetValue(facetObj)}>{facetUI[facetObj.name] ? facetUI[facetObj.name].label + ": " : ""}{facetObj.value}</a>
                                    ))}
                                </div>
                            </div>
                        }

                       
                        <div className="c-search__results">
                            {searchData?.response && this.props.resultList && this.props.resultList(searchData)}
                        </div>

                        {searchData?.response && searchData.response.numFound > this.state.qsValues.limit &&
                            <ReactPaginate
                                forcePage={parseInt(this.state.qsValues.page, 10)}
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                pageCount={Math.ceil(searchData.response.numFound / this.state.qsValues.limit)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={8}
                                onPageChange={this.onPageClick}
                                

                                containerClassName={'c-search__pagination'}

                                breakClassName={'break'}
                                breakLinkClassName={'break__link'}

                                pageClassName={'page'}
                                pageLinkClassName={'page__link'}

                                activeClassName={'page--active '}
                                activeLinkClassName={'page__link--active'}

                                previousClassName={'previous'}
                                previousLinkClassName={'previous__link'}

                                nextClassName={'next'}
                                nextLinkClassName={'next__link'}

                                disabledClassName={'disabled'}
                            />
                        }
                    </div>

                    <div className="l-sidebar">
                        <div className={"c-search__facets" + (this.state.showFilters ? " c-search__facets--active" : "")}>
                            {this.state.showFilters}
                            <button className="c-search__facets-toggle" onClick={this.onShowFiltersChanged}> 
                                <svg className="svg-icon-size svg-icons_filter-hobo-dims">
                                    <use xlinkHref="#icons_filter-hobo" />
                                </svg>
                            </button>
                            <div className="c-search__facets-form">
                                {this.getFacets(searchData, searchConfig)}
                            </div>
                        </div>  
                    </div>
                </form>
            </div>
        );
    }
}

// Which props do we want to inject, given the global state?
var mapStateToProps = state => {
    return { 
        state: {
            config: state.configReducer,
            user: state.userReducer,
            briefcase: state.briefcaseReducer 
        }
    };
};

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(withMsal(Search));