import { put } from 'redux-saga/effects';
import * as types from '../actions';

export function* searchMLTRemoveSaga(params) {
  try {
    yield put({
      type: types.PUBLIC_SEARCH_MLT_REMOVE_SUCCESS,
      data: {id: params.params.id}
    });

  } catch(error) {
    yield put({ 
      type: types.PUBLIC_SEARCH_MLT_REMOVE_ERROR, 
      message: error.message
    });

  }
}