import React from 'react';
import "./InsertionForm.scss";
import moment from 'moment';
import Search from './Search';
import { FaPlus, FaSearch, FaSortAmountDown, FaSortAmountUp } from 'react-icons/fa';
import Modal from '../modal/Modal';
import bem from '../../helpers/bem';

export class InsertionForm extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            showSearchModal: false
        };
    }

    getFields = () =>[
        {title: "Type", sort: "type", order: "asc"},
        {title: "ID", sort: "sharepoint_id", order: "asc"},
        {title: "Title", sort: "title", order: "asc"},
        {title: "Description", sort: "description", order: "asc"},
        {title: "Last Modified", sort: "modified", order: "asc", format: val => moment(val).fromNow()},
        {title: "Created On", sort: "created", order: "asc", format: val => moment(val).fromNow()},
        {title: "Actions", sort:'', format: (val, doc) =>
            <label 
                className="c-icon" 
                onClick={() => {
                    this.props.addToList({
                        id: doc.sharepoint_id, 
                        type: doc.type
                    })
                }}
            >
                <FaPlus/>
            </label>
        }
    ]

    showSearchModal = e => {
        this.setState({
            showSearchModal: true
        })
    }

    hideSearchModal = e => {
        this.setState({
            showSearchModal: false
        })
    }

    sort = (sort, order) => {
        if (this.state.sort === sort) {
            this.setState({order: (this.state.order === "desc" ? "asc" : "desc")})
        } else {
            this.setState({sort, order})
        }
    }

    getSortIcon = (field) => {
        if (field.sort === this.state.sort) {
            if (this.state.order === "asc") {
                return <FaSortAmountUp />
            } else {
                return <FaSortAmountDown />
            }
        } else {
            if (field.order === "asc") {
                return <FaSortAmountUp />
            } else {
                return <FaSortAmountDown />
            }
        }
    }

    render() {
        
        return (
            <>
                {this.state.showSearchModal &&
                    <Modal title={this.props.title} className={bem("insertion-form")} onClose={this.hideSearchModal} scrollable={true}>
                        <Search
                            previewMode={this.props.previewMode}
                            qsParams={{
                                sort: this.state.sort,
                                order: this.state.order,
                                
                            }} 
                            resultList={ searchData => {
                                if (searchData?.response) {
                                    return (
                                        <table className={bem("data-table")}>
                                            <thead>
                                                <tr>
                                                    {this.getFields().map( field => 
                                                        <th  
                                                            key={field.sort}
                                                            className={bem(
                                                                "data-table", 
                                                                field.sort && "sortable", 
                                                                [   
                                                                    this.state.sort === field.sort && "active",
                                                                    field.sort,
                                                                    field.order
                                                                ],
                                                            )} 
                                                        >
                                                            <a onClick={() => 
                                                                field.sort ? this.sort(field.sort, field.order) : null
                                                            }>
                                                                    
                                                                {field.title}

                                                                {field.sort && this.getSortIcon(field)}
                                                                
                                                            </a>
                                                        </th>
                                                    )}                                            
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {searchData.response.docs?.map((doc, index) => (
                                                    <tr key={doc.id}>
                                                        {this.getFields().map( field => 
                                                            <td key={field.sort} className={field === "description" ? "truncate" : ""}>
                                                                {field.format ? field.format(doc[field.sort], doc) : doc[field.sort]}
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )
                                } else {
                                    return null
                                }
                            }
                        } />
                    </Modal>
                }

                <label className="c-icon" onClick={this.showSearchModal}>
                    <FaSearch/>
                </label>
            </>
        )
    }
}