import * as types from '../actions';
import produce from "immer";

const initialState = {
    message: {},
    search: {},
    briefcase: []
}

export default function (state = initialState, action) {

    function pad2(number) {
        return (number < 10 ? '0' : '') + number;
    }
    
    function createMessage(text, status) {
        var d = new Date();
        var date = `${d.getMonth()}/${d.getDate() + 1}/${d.getFullYear()} ${d.getHours()}:${pad2(d.getMinutes())}:${pad2(d.getSeconds())}`;
        return {
            text,
            status,
            date 
        }
    }

    var m;
    switch (action.type) {
        case types.PUBLIC_BRIEFCASE_LOAD_SUCCESS:
            m = createMessage(action.message, "success");
            return {
                ...produce(state, draftState => {
                    draftState.briefcase = action.data;
                }),
                message: m
            };

        case types.PUBLIC_BRIEFCASE_LOAD_ERROR:
            m = createMessage(action.message, "error")
            return {
                ...produce(state, draftState => {
                    draftState.briefcase = []
                }),
                message: m
            };

        case types.PUBLIC_BRIEFCASE_REMOVE_SUCCESS:
            m = createMessage(action.message, "success");
            var i = state.briefcase.indexOf(action.data.id);
            if (i === -1) {
                throw "Asset not found in briefcase."
            }
            return {
                ...produce(state, draftState => {
                    delete draftState.briefcase[i];
                }),
                message: m
            };
    
        case types.PUBLIC_BRIEFCASE_REMOVE_ERROR:
            m = createMessage(action.message, "error")
            return {
                ...state,
                message: m
            };

        case types.PUBLIC_LOAD_BRIEFCASE_ASSETS_SUCCESS:

            m = createMessage(action.message, "success");
            return {
                ...produce(state, draftState => {
                    //draftState.facets = action.facets;
                    draftState.facets = null;
                    //draftState.highlighting = action.highlighting;
                    draftState.response = action.response;
                }),
                message: m
            };

        case types.PUBLIC_LOAD_BRIEFCASE_ASSETS_ERROR:
            m = createMessage(action.message, "error")
            return {
                ...produce(state, draftState => {

                }),
                message: m
            };
        default:
            return state;
    }
};
