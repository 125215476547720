import { put, call } from 'redux-saga/effects';
import { createSnapshot, importSnapshot } from '../../services/layoutService';
import { UserIsEditor, UserIsArchitect, loadProfile, profileIndustryChange} from '../../services/userService';
import * as types from '../actions';

export function* tokenSaga({params, type}) {

    try { 
        yield put({
            type: types.PUBLIC_UPDATE_TOKEN_SUCCESS,
            data: params
      });
  } catch (error) {
    
    yield put({ 
      type: types.PUBLIC_UPDATE_TOKEN_ERROR, 
      message: error.message
    });
  }
}

export function* isEditor({ params, type }) {

    try {
        let res = yield call(UserIsEditor)
        const isEditor = res.data.data
        yield put({
            type: types.PUBLIC_CHECK_ISEDITOR_SUCCESS,
            data: {
                ...params,
                isEditor
            }
        });

        // localStorage.setItem("auth", JSON.stringify(data));
    } catch (error) {

        yield put({
            type: types.PUBLIC_CHECK_ISEDITOR_ERROR,
            message: error.message
        });

        // localStorage.removeItem("auth");

    }
}

export function* isArchitect({ params, type }) {

    try {
        let res = yield call(UserIsArchitect)
        const isArchitect = res.data.data
        yield put({
            type: types.PUBLIC_CHECK_ISARCHITECT_SUCCESS,
            data: {
                ...params,
                isArchitect
            }
        });

        // localStorage.setItem("auth", JSON.stringify(data));
    } catch (error) {

        yield put({
            type: types.PUBLIC_CHECK_ISARCHITECT_ERROR,
            message: error.message
        });

        // localStorage.removeItem("auth");

    }
}

export function* profileSaga(params) {
  try {
      const res = yield call(loadProfile, params); 
      
      yield put({
          type: types.PUBLIC_LOAD_PROFILE_SUCCESS,
          profile: {...res.data}
    });

  } catch(error) {
    
    yield put({ 
      type: types.PUBLIC_LOAD_PROFILE_ERROR, 
      message: error.message
    });

  }
}

export function* profileIndustryChangeSaga(params) {
  try {
      const res1 = yield call(profileIndustryChange, params); 
      const res2 = yield call(loadProfile, params); 
      
      yield put({
          type: types.PUBLIC_LOAD_PROFILE_SUCCESS,
          profile: {...res2.data}
    });

  } catch(error) {
    
    yield put({ 
      type: types.PUBLIC_LOAD_PROFILE_ERROR, 
      message: error.message
    });

  }
}

export function* takeSnapshotSaga({ params, type }) {

    try {
        let res = yield call(createSnapshot)
        const snapshot = res.data.data
        yield put({
            type: types.PUBLIC_TAKESNAPSHOT_SUCCESS,
            data: {
                ...params,
                snapshot
            }
        });

        // localStorage.setItem("auth", JSON.stringify(data));
    } catch (error) {

        yield put({
            type: types.PUBLIC_TAKESNAPSHOT_ERROR,
            message: error.message
        });

        // localStorage.removeItem("auth");

    }
}

export function* importSnapshotSaga({ params, type }) {

    try {
        let res = yield call(importSnapshot)
        const snapshot = res.data.data
        yield put({
            type: types.PUBLIC_IMPORTSNAPSHOT_SUCCESS,
            data: {
                ...params,
                snapshot
            }
        });

        // localStorage.setItem("auth", JSON.stringify(data));
    } catch (error) {

        yield put({
            type: types.PUBLIC_IMPORTSNAPSHOT_ERROR,
            message: error.message
        });

        // localStorage.removeItem("auth");

    }
}