import { put } from 'redux-saga/effects';
import * as types from '../actions';

export function* suggestClearSaga(params) {
  try {

      yield put({
          type: types.PUBLIC_SUGGEST_CLEAR_SUCCESS,
          data: { suggestions: [] }
    });

  } catch(error) {

    yield put({ 
      type: types.PUBLIC_SUGGEST_CLEAR_ERROR, 
      message: error.message
    });

  }
}