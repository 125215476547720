import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, BrowserRouter as Router} from "react-router-dom";
import { routes } from "./routes";
import './App.scss';
import Header from '../components/header/Header';
import { Hero } from '../components/hero/Hero';
import Navigation from '../components/navigation/Navigation';
import {CustomNavigationClient} from '../components/navigation/CustomNavigationClient';
import { ProfileCard } from '../components/profile/ProfileCard';
import { ProfileOverlay } from '../components/profile/ProfileOverlay';
import {checkIsArchitectAction, checkIsEditorAction, loadBriefcaseAction, loadProfileAction, profileIndustryChangeAction } from "../redux/actions/actions";
import TagManager from 'react-gtm-module';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { isMobile } from 'react-device-detect';
import { MsalAuthenticationTemplate, MsalProvider, withMsal } from '@azure/msal-react';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { AuthErrorComponent } from '../components/auth/Error';
import { AuthLoadingComponent } from '../components/auth/Loading';

class App extends React.Component {

  constructor(props) {
    //console.log("APP", "CREATE")
    super(props);

    this.state = {
      year: new Date().getFullYear(),
      showProfile: false,
      showMobileMenu: false
    }

    this.gtmCreated = false;
  }

  componentDidMount = async () => {
    if (!this.props.state.user.isAuthenticated) {
        this.handleSignIn()
    }
}

  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    //console.log("APP", "UPDATE")

    if (
      !this.gtmCreated &&
      this.props.state.config?.app?.gtm?.id &&
      this.props.state.user?.profile?.id
    ) {
      const tagManagerArgs = {
        dataLayer: {
            userid: this.props.state.user.profile.id
        },
        gtmId: this.props.state.config.app.gtm.id,    
        auth: this.props.state.config.app.gtm.auth,
        preview: this.props.state.config.app.gtm.env   
      };

      TagManager.initialize(tagManagerArgs);

      this.gtmCreated = true;
      //console.log("GTM CREATED");
    }
      
      if (this.props.state.user?.isAuthenticated === true && prevProps.state.user?.isAuthenticated === false ) {
        this.onAuthentication()
      }
  }

  onAuthentication = async () => {
    //console.log("APP", "AUTHENTICATED")
      this.props.dispatch(loadBriefcaseAction());
      this.props.dispatch(loadProfileAction());
      this.props.dispatch(checkIsEditorAction());
      this.props.dispatch(checkIsArchitectAction());
  }

  handleSignIn = () => {
    if (this.props.msalContext.inProgress === InteractionStatus.None) {
      this.props.pca.loginPopup(this.props.state.config.auth.loginRequest)
    }
  }

  handleClaims = (data) => {
    if (this.props.msalContext.inProgress === InteractionStatus.None) {
      let request = { 
        ...this.props.state.config.auth.claimsRequest(data)
      }
      this.props.pca.loginPopup(request)
    }
  }

  handleSignOut = () => {
    this.props.pca.logoutRedirect()
  }

  getVideoUrl = () => {
    let url = "";

    if (this.props.state.config?.brightcove) {
      url = this.props.state.config.brightcove.brightCoveUrl + this.props.state.config.brightcove.trainingVideoId;
    }

    return url;
  };

  getRoute = (route, index, component) => {
    const params = {
      // key:route.path,
      path:route.path,
      exact:route.exact
    }

    
    if (route.private) {
      const Component = component
      return (
          <Route {...params} render={props => 
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Popup} 
              authenticationRequest={this.props.state.config.auth.loginRequest} 
              errorComponent={AuthErrorComponent} 
              loadingComponent={AuthLoadingComponent}>
                <Component {...props} login={this.handleSignIn} claimsRequest={this.handleClaims} />
            </MsalAuthenticationTemplate>
          } />
      )
    } else {
      params.component = (props) => component({
        ...props,
        login: this.handleSignIn,
        claimsRequest: this.handleClaims
        // msalInstance: this.props.pca
      })
      return (<Route {...params} />)
    }
  }

  
  render() {
      //console.log("APP", "RENDER")
    
      const navigationClient = new CustomNavigationClient(this.props.history);
      this.props.pca.setNavigationClient(navigationClient);

      return (
        <MsalProvider instance={this.props.pca}>
            <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
              <Router>
                  <Header
                    signIn={this.handleSignIn}
                    signOut={this.handleSignOut}
                    onMobileClick={(event) => {
                      event.preventDefault();
                      this.setState({showMobileMenu: !this.state.showMobileMenu},
                        () => {
                          if (this.state.showMobileMenu) {
                            document.body.classList.add('menu-open');
                          } else {
                            document.body.classList.remove('menu-open');
                          }
                        })
                    }}
                  />
                  <Hero videoUrl={this.getVideoUrl()}/>

                  <main role="main">
                      <div className="l">
                        <nav id="nav" className={`c-primary-nav ${this.state.showMobileMenu == true ? " c-primary-nav--is-active" : ""}`}>
                          <div className="c-primary-nav__container">
                              <div className="aside aside-1">
                                {this.props.state.user.profile &&
                                    <ProfileCard profile={this.props.state.user.profile} onClick={() => {
                                      this.setState({showProfile: true}) 
                                    }} />
                                }
                              </div>
                              <div className="aside aside-2">
                                  <Navigation roles={[this.props.state.user.isEditor && "isEditor", this.props.state.user.isArchitect && "isArchitect"].filter( r => r)} navKey="briefcaseNav" />
                              </div>
                                        <div className="l-main">
                                            
                                  <Navigation navKey="primaryNav" showMobileMenu={this.state.showMobileMenu}/>
                                            
                                  {!this.props.state.user.isAuthenticated &&
                                    <a onClick={this.handleSignIn} className="c-btn c-btn--inverse c-btn--small logout-button-mobile"><span>Sign in</span></a>
                                  }
                                  {this.props.state.user.isAuthenticated &&
                                    <a onClick={this.handleSignOut} className="c-btn c-btn--inverse c-btn--small logout-button-mobile"><span>Sign out</span></a>
                                  }
                              </div>
                          </div>
                        </nav>
                      </div>

                      <div className="l">  
                                
                            {/* ROUTE MAIN COMPONENT */}
                          
                        <Switch>
                          {routes.map((route, index) => this.getRoute(route, index, route.main) )}
                        
                          {/* .filter( route => !route.private || this.props.state.user.isAuthenticated).map((route, index) => this.getRoute(route, index, route.main))} */}
                      
                        </Switch>
                      </div>
                      
                      {this.props.state.user?.isAuthenticated && this.state.showProfile && this.props.state.user?.profile && this.props.state.config?.facets?.industry &&
                        <ProfileOverlay 
                          profile={this.props.state.user.profile} 
                          industries={this.props.state.config.facets.industry} 
                          onClose={() => this.setState({showProfile: false}) } 
                          onIndustryChange={ params => this.props.dispatch(profileIndustryChangeAction(params))}
                        />
                      }
                  </main>

                  <footer className="c-footer" role="contentinfo">
                    <div className="l">

                        {this.props.state.config?.app?.environment && 
                          (
                            this.props.state.config.app.environment.toLowerCase() === "development" ||
                            this.props.state.config.app.environment.toLowerCase() === "local" ||
                            this.props.state.config.app.environment.toLowerCase() === "local2" ||
                            this.props.state.config.app.environment.toLowerCase() === "staging"
                          ) &&
                            <div style={{
                              "float": "right",
                              "textAlign": "right"
                            }}>
                                <h3>{this.props.state.config.app.environment}</h3>
                            </div>
                        }

                        <a href="" className="c-logo-footer-link">
                            <img src="/images/logo_aspentech.svg" className="c-logo c-logo-footer-link__img" alt="Aspen Tech" />
                        </a>

                        <p className="copyright">&copy; {this.state.year} - src.aspentech.com - 
                          <a href="https://asptechinc.sharepoint.com/:b:/s/InformationServices/ETugUkDhlztPscvaHwOg8UgBgji0s0LLsO4YSjX_7k0hSg?e=qWg7Yr" target="privacy">
                            Privacy
                          </a>
                        </p>
                    </div>
                  </footer>
              </Router>
            </DndProvider>
        </MsalProvider>
      );
  }
}

// Which props do we want to inject, given the global state?
var mapStateToProps = state => {
  return { 
    state: {
      user: state.userReducer,
      config: state.configReducer
    }
  }
};

// AppContainer is a container component wrapped by HOC AuthProvider
export default connect(mapStateToProps)(withMsal(App));