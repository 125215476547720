import httpFactory from "../utils/httpClient";
import qs from "qs";

export const savePackage = action => httpFactory().post("layout", action?.params)

export const getPackage = action => httpFactory().get("layout", { 
    params: {
        ...action?.params
    }    
})

export const createSnapshot = action => {
    return httpFactory().post("takeSnapshot", action?.params)
}

export const downloadSnapshot = action => httpFactory().get("downloadSnapshot")

export const importSnapshot = action => {
    return httpFactory().get("importSnapshot", action?.params)
}

export const uploadSnapshot = action => {
    return httpFactory().post("uploadSnapshot", action?.formData, { headers: { "Content-Type": "multipart/form-data" } });
}

export const importFeedback = action => {
    return httpFactory().get("importFeedback")
}