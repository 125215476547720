import httpFactory, { FileUploadInstance } from "../utils/httpClient";
export const helpRequestService = action => httpFactory().post("help-request", action)
export const helpRequestFileUpload = file => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append(
        "file",
        file,
        file.name
    );

    return FileUploadInstance.post("file-uploader", formData, { headers: { "Content-Type": "multipart/form-data" } })
}
