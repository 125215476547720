import React from "react";
import { connect } from "react-redux";
import { RadioGroup } from "ui-kit.aspentech.com/dist/components/RadioGroup/RadioGroup";
import { bem } from "ui-kit.aspentech.com/src/helpers";
import { ImageCache } from "../../components/image/ImageCache";
import "./Images.scss";

class Images extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listName: Object.keys(props.state.config.listThumbnailImages)[0],
    };
  }

  onListNameChange = (e) => {
    this.setState(e);
  };

  render() {
    const lists = Object.keys(this.props.state.config.listThumbnailImages);

    return (
      <table>
        <thead>
          <tr>
            <th colSpan="6">
              {
                <RadioGroup
                  id="listName"
                  label="Change Library"
                  formData={{}}
                  options={lists.map((listName) => {
                    return {
                      value: listName,
                      label: listName,
                      checked: this.state.listName === listName,
                    };
                  })}
                  onChange={this.onListNameChange}
                />
              }
            </th>
          </tr>
          <tr>
            <th>Preview</th>
            <th>Name</th>
            <th>Modified</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody className={bem("image-table", "body")}>
          {this.props.state.config.listThumbnailImages[this.state.listName].map(
            (listItem) => {
              const url = `/images/cache/${encodeURIComponent(
                listItem.Name
              )}?url=${encodeURIComponent(
                `https://asptechinc.sharepoint.com${listItem.ServerRelativeUrl}`
              )}`;
              return (
                <tr
                  key={listItem.ServerRelativeUrl}
                  onClick={() => {
                    this.props.onChange({
                      url,
                      imageId: listItem.UniqueId,
                      imageName: listItem.Name,
                      serverRelativeUrl: listItem.ServerRelativeUrl,
                    });
                    this.props.setShowImagePickerModal(false);
                  }}
                >
                  <td>
                    <ImageCache src={url} height="50px" width="100px" />
                  </td>
                  <td>{listItem.Name}</td>
                  <td>
                    {new Date(listItem.TimeLastModified).toLocaleDateString(
                      "en-US",
                      { month: "long", day: "numeric", year: "numeric" }
                    )}
                  </td>
                  <td>
                    {" "}
                    {new Date(listItem.TimeCreated).toLocaleDateString(
                      "en-US",
                      { month: "long", day: "numeric", year: "numeric" }
                    )}
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="6"></td>
          </tr>
        </tfoot>
      </table>
    );
  }
}

// Which props do we want to inject, given the global state?
var mapStateToProps = (state) => {
  return {
    state: {
      config: state.configReducer,
    },
  };
};

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(Images);
