import React from "react";
import { Link } from "react-router-dom";
import { ImageCache } from "../image/ImageCache";
import moment from "moment";
import { FaRegFolder, FaRegFile } from "react-icons/fa";
import "./Card.scss";
import { Explain } from "../../components/explain/Explain";

const convertToKebabCase = (s) => {
  if (!s) {
    return s;
  }
  return s.replace(/\s+/g, "-").toLowerCase();
};

export const PackageCard = (props) => {
  let data = props.data,
    editMode = props.editMode,
    inBriefcaseClass = data.IsInBriefcase ? "in-briefcase" : "not-in-briefcase",
    childInfo = {
      asset: props.childInfo?.asset || 0,
      package: props.childInfo?.package || 0,
    };

  return (
    <div
      className={`c-card card--package card--${convertToKebabCase(
        data.theme
      )} ${inBriefcaseClass}`}
      data-type={data.type}
      id={data.id}
    >
      <div className="c-card__icon-wrapper">
        <FaRegFolder className="c-card__icon" />
      </div>
      <div className="c-card__media">
        <ImageCache
          src={
            (data.image_url &&
              `/images/cache/${data.sharepoint_id}.png?url=${encodeURIComponent(
                data.image_url
              )}`) ||
            (data.src && data.src)
          }
          alt={data.title}
          className="c-card__img"
        />
      </div>

      <div className="c-card__body">
        {data.Subtitle && <p>{data.Subtitle}</p>}

        {data.publish_year && (
          <p className="c-card__by-line">{data.publish_year}</p>
        )}

        <div
          className="c-card__excerpt line-clamp4"
          lines="3"
          dangerouslySetInnerHTML={{
            __html: data.description,
          }}
        />

        <p className="c-card__date line-clamp" lines="3">
          {moment(data.modified).fromNow()}
        </p>

        <p className="c-card__children">
          <span className="icon__wrapper icon__wrapper--file">
            <FaRegFile /> {childInfo.asset} asset
            {childInfo.asset !== 1 ? "s" : null}
          </span>
          <span className="icon__wrapper icon__wrapper--folder">
            <FaRegFolder /> {childInfo.package} package
            {childInfo.package !== 1 ? "s" : null}
          </span>
        </p>
      </div>

      {data.content_type && (
        <div className="c-card__flag">
          <span>{data.content_type}</span>
        </div>
      )}

      <div className="c-card__actions">
        {data.explain && <Explain explanation={data.explain} />}
        <Link
          to={`/package/${data.sharepoint_id}`}
          className="c-card__action c-card__action--read c-btn c-btn--primary"
        >
          View
        </Link>

        {data.sharepoint_id && editMode && (
          <a
            className="c-card__action c-card__action--id c-btn c-btn--primary"
            onClick={() => {
              navigator.clipboard
                .writeText(data.sharepoint_id)
                .then(() => alert("Copied"));
            }}
          >
            Package Id: {data.sharepoint_id}
          </a>
        )}
      </div>
    </div>
  );
};
