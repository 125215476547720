import * as types from '../actions';
import produce from "immer";

const initialState = {
    message: {},
    initialized: false
}

export default function (state = initialState, action) {

    function pad2(number) {
        return (number < 10 ? '0' : '') + number;
    }
    
    function createMessage(text, status) {
        var d = new Date();
        var date = `${d.getMonth()}/${d.getDate() + 1}/${d.getFullYear()} ${d.getHours()}:${pad2(d.getMinutes())}:${pad2(d.getSeconds())}`;
        return {
            text,
            status,
            date 
        }
    }

    var m;
    switch (action.type) {
        case types.PUBLIC_LOAD_CONFIG_SUCCESS:
            m = createMessage(action.message, "success")
            return {
                ...action.data,
                initialized: true,
                message: m
            };
        case types.PUBLIC_LOAD_CONFIG_ERROR:
            m = createMessage(action.message, "error")
            return {
                ...produce(state, draftState => {

                }),
                initialized: false,
                message: m
            };

        default:
            return state;
    }
};
