import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import { Prompt } from "react-router-dom";
import Tabs from "ui-kit.aspentech.com/dist/components/Tabs/Tabs";
import EditorTable from "./EditorTable";
import * as assetFormConfig from "../../components/editorForm/assetsForm.config";
import * as packageFormConfig from "../../components/editorForm/packagesForm.config";
import "./Editor.scss";

const Editor = (props) => {
  const [currentTab, setCurrentTab] = useState("Assets");
  const [formConfig, setFormConfig] = useState({});
  const [assets, setAssets] = useState([]);
  const [packages, setPackages] = useState([]);

  const checkUnsaved = useCallback(() => {
    const assetsCopy = [...assets];
    const packagesCopy = [...packages];
    const unsavedAssets = assetsCopy.filter(
      (asset) => !asset.status.sharepointRecordCreated
    );
    const unsavedPackages = packagesCopy.filter(
      (packageObj) => !packageObj.status.sharepointRecordCreated
    );

    if (unsavedAssets.length > 0 || unsavedPackages.length > 0) {
      return true;
    }
    return false;
  }, [assets, packages]);

  useEffect(() => {
    function alertUser(event) {
      if (checkUnsaved()) {
        event.preventDefault();
        event.returnValue = "";
      } else {
        return undefined;
      }
    }

    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [checkUnsaved]);

  useLayoutEffect(() => {
    function getFormConfig() {
      let newFormConfig;
      if (currentTab === "Assets") newFormConfig = assetFormConfig;
      // if (currentTab === "Packages") newFormConfig = packageFormConfig;
      return newFormConfig;
    }

    const formConfig = getFormConfig();
    setFormConfig(formConfig);
  }, [currentTab]);

  return (
    <>
      <Prompt
        when={checkUnsaved()}
        message={
          "You have unsynced items with Sharepoint. Are you sure you want to exit?"
        }
      />
      <h1 className="c-section__sub-title">Editor</h1>
      <Tabs
        tabs={[
          // { value: "Packages", label: "Packages" },
          { value: "Assets", label: "Assets" },
        ]}
        onChange={(value) => {
          setCurrentTab(value);
        }}
      />
      <EditorTable
        rows={assets}
        setRows={setAssets}
        // rows={currentTab === "Assets" ? assets : packages}
        // setRows={currentTab === "Assets" ? setAssets : setPackages}
        formConfig={formConfig}
        currentTab={currentTab}
      />
    </>
  );
};

export default Editor;
