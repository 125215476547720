import { takeLatest, debounce } from 'redux-saga/effects';
import { searchQuerySaga } from './searchQuerySaga';
import { searchMLTSaga } from './searchMLTSaga';
import { searchMLTRemoveSaga } from './searchMLTRemoveSaga';
import { suggestQuerySaga } from './suggestQuerySaga';
import { loadConfigSaga } from './loadConfigSaga';
import { suggestClearSaga } from './suggestClearSaga';
import { packagesSaga } from './packagesSaga';
import { assetsSaga } from './assetsSaga';
import * as types from '../actions';
import { tokenSaga, profileSaga, profileIndustryChangeSaga, isEditor, isArchitect, takeSnapshotSaga, importSnapshotSaga } from './userSaga';
import { /*briefcaseAddSaga,*/ briefcaseRemoveSaga, briefcaseLoadSaga, briefcaseLoadAssetsSaga } from './briefcaseSaga';

export function* watchSearchQuery() {
    yield debounce(250, types.PUBLIC_SEARCH_QUERY, searchQuerySaga);
}

export function* watchSearchMLT() {
    yield takeLatest(types.PUBLIC_SEARCH_MLT, searchMLTSaga);
}

export function* watchSearchMLTRemove() {
    yield takeLatest(types.PUBLIC_SEARCH_MLT_REMOVE, searchMLTRemoveSaga);
}

export function* watchLoadConfig() {
    yield takeLatest(types.PUBLIC_LOAD_CONFIG, loadConfigSaga);
}

export function* watchSuggestQuery() {
    yield takeLatest(types.PUBLIC_SUGGEST_QUERY, suggestQuerySaga);
}

export function* watchSuggestClear() {
    yield takeLatest(types.PUBLIC_SUGGEST_CLEAR, suggestClearSaga);
}

export function* watchPackages() {
    yield takeLatest(types.PUBLIC_LOAD_PACKAGES, packagesSaga);
}

export function* watchAssets() {
    yield takeLatest(types.PUBLIC_LOAD_ASSETS, assetsSaga);
}

export function* watchBriefcase() {
    yield takeLatest(types.PUBLIC_BRIEFCASE_LOAD, briefcaseLoadSaga);
}

// export function* watchBriefcaseAdd() {
//     yield takeLatest(types.PUBLIC_BRIEFCASE_ADD, briefcaseAddSaga);
// }

export function* watchBriefcaseRemove() {
    yield takeLatest(types.PUBLIC_BRIEFCASE_REMOVE, briefcaseRemoveSaga);
}

export function* watchProfile() {
    yield takeLatest(types.PUBLIC_LOAD_PROFILE, profileSaga);
}

export function* watchProfileIndustryChange() {
    yield takeLatest(types.PUBLIC_PROFILE_INDUSTRY_CHANGE, profileIndustryChangeSaga);
}

export function* watchBriefcaseLoadAssets() {
    yield takeLatest(types.PUBLIC_LOAD_BRIEFCASE_ASSETS, briefcaseLoadAssetsSaga);
}

export function* watchAuth() {
    yield takeLatest(types.PUBLIC_UPDATE_TOKEN, tokenSaga);
}

export function* watchIsEditor() {
    yield takeLatest(types.PUBLIC_CHECK_ISEDITOR, isEditor);
}

export function* watchIsArchitect() {
    yield takeLatest(types.PUBLIC_CHECK_ISARCHITECT, isArchitect);
}

export function* watchTakeSnapshot() {
    yield takeLatest(types.PUBLIC_TAKESNAPSHOT, takeSnapshotSaga);
}

export function* watchImportSnapshot() {
    yield takeLatest(types.PUBLIC_IMPORTSNAPSHOT, importSnapshotSaga);
}