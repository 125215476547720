import React from 'react';
import { Link } from 'react-router-dom';

export class ProfileCard extends React.Component { 
    
    constructor(props) {
      super(props);
    }
  
    render() {
        return (
            <div className="c-avatar icon-wrapper">
                <a className="c-avatar__link" overlay="profile-overlay" onClick={this.props.onClick} >
                    <span className="c-avatar__icon">
                        <span className="c-avatar__icon-box icon-box">
                            {this.props.profile.photo &&
                                <img src={`data:image/jpeg;base64, ${this.props.profile.photo}`} />
                            }

                            {!this.props.profile.photo &&
                                <svg className="svg-icon-icons_avatar-size svg--dims">
                                    <use xlinkHref="#icons_avatar"></use>
                                </svg>
                            }
                        </span>
                    </span>

                    <span className="c-avatar__label icon-label">
                        <span className="c-avatar__label-wrapper icon-label__wrapper">
                            {this.props.profile.displayName &&
                                <span>
                                    <span>{this.props.profile.displayName}</span>
                                    {this.props.profile.jobTitle &&
                                        <span><br />{this.props.profile.jobTitle}</span>
                                    }
                                </span>
                            }
                        </span>
                    </span>
                </a>
            </div>
        )
    }
  }