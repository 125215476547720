import React from 'react';
import { connect } from 'react-redux';
import {
    removeBriefcaseAction, loadBriefcaseAction
} from "../../redux/actions/actions";
import qs from "qs";
import ReactPaginate from 'react-paginate';
import { Element, scroller } from 'react-scroll';
import '../Base.scss';
import './Briefcase.scss';
import { AssetCard } from '../../components/cards/AssetCard';
import { searchQueryService } from '../../services/searchService';
import { loadMissingBriefcaseItems } from '../../services/userService';
import { withMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

var autoSubmitOn = true;

var defaults = {
    limit: 30,
    page: 0,
    sort: "publish_year desc, title",
    order: "asc",
    filters: {},
    facetsOn: true
};

var querystringParamList = [
    "filters",
    "limit",
    "page"
];

var searchQueryParamList = [
    "query",
    "limit",
    "page",
    "filters",
    "facetsOn",
    "sort",
    "order"
];

var cloneDeep = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}
 
class Briefcase extends React.Component {
    constructor(props) {
        //console.log("BRIEFCASE", "CREATE")
        super(props);

        this.state = {
            qsValues: {},
            facetGroupVisibility: [],
            showFilters: true,
            contextualFilters: {}
        };

        this.configured = false;
        this.pageRef = React.createRef();
        this.onSubmit = this.onSubmit.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.isFilterChecked = this.isFilterChecked.bind(this);
    }


    getRecords = () => {
        this.executeQuery({
            ...this.state.qsValues
        });

        const facets = this.props.state.config?.ui?.facets;
        if (!this.configured && facets) {
            try {
                var keys = Object.keys(facets);
                keys.map( key => {
                    var facet = facets[key];

                    //if (facet.expanded) {
                        if (this.isFacetGroupVisible(facet.name)) {
                            this.toggleFacetGroupVisibility(facet.name)
                        }
                    //}
                });

                this.configured = true;
            } catch (error) {
                
            }  
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!prevProps.state.user.isAuthenticated && this.props.state.user.isAuthenticated) {
            this.getRecords()
        } else if (prevProps.state.briefcase?.briefcase !== this.props.state.briefcase?.briefcase) {
            this.getRecords()
        }
    }

    componentDidMount = () => {
        //console.log("BRIEFCASE", "MOUNT")
        if (this.props.state.user.isAuthenticated) {
            this.getRecords()
        }
    }

    executeQuery = async (params) => {
        
        //console.log("BRIEFCASE", "EXECUTE_QUERY")
        var searchParams = cloneDeep({
            ...defaults,
            ...params
        });

        var qsParams = cloneDeep(searchParams);
        Object.keys(qsParams).map(key => {
            if (querystringParamList.indexOf(key) === -1) {
                delete qsParams[key];
            }
        });

        var s = qs.stringify(qsParams, {
            allowDots: true,
            indices: false,
            encode:true,
            encodeValuesOnly: true
        });

        if (window.location.search.length > 1) {
            this.props.history && this.props.history.push({
                search: "?" + s
            });
        } else {
            this.props.history && this.props.history.replace({
                search: "?" + s
            });
        }


        Object.keys(searchParams).map(key => {
            if (searchQueryParamList.indexOf(key) === -1) {
                delete searchParams[key];
            }
        });

        try {
            // let res = await loadBriefcase(searchParams);
    
            var ids = Object.values(this.props.state.briefcase.briefcase);
            var q = [];
            let assetRes = {};

            if (ids.length > 0) {
        
                ids.map( id => {
                    q.push(`sharepoint_id:"${id}"`);
                })
            
                assetRes = await searchQueryService({
                    params: {
                        ...searchParams,
                        parents: false,
                        facetFilter: "child",
                        query: q.join(" OR ")
                    }
                });
            }


            const missingAssetRes = await loadMissingBriefcaseItems();

            this.setState({
                qsValues: qsParams,
                briefcase: assetRes?.data?.data || {},
                missingItems: missingAssetRes.data.data
            })

        }  catch (error) {
            console.error(error)
            if (error.response?.status === 401 && this.props.login) {
                this.props.login()
            }
        }
    }

    getQuerystringObject = function () {
        var retVal = window.location.search;

        if (retVal.substring(0, 1) === "?") {
            retVal = retVal.substring(1);
        }

        var qsParams = qs.parse(retVal, {
            plainObjects: true,
            allowDots: true
        });

        Object.keys(qsParams).map(key => {
            if (querystringParamList.indexOf(key) === -1) {
                delete qsParams[key];
            }
        });

        return qsParams;
    }


    submitForm = () => {
        this.submitButtonRef.click();
    }

    isFilterChecked = (name, value) => {
        return (typeof this.state.qsValues.filters === "object" && this.state.qsValues.filters[name] && this.state.qsValues.filters[name].indexOf(value.toString()) > -1);
    }

    onSubmit(event) {
        event.preventDefault();

        var params = cloneDeep(defaults);

        for (var el of event.target) {

            if (el.name === "q") {
                params.query = el.value;
            } else if (el.name === "limit") {
                params.limit = parseInt(el.value, 10);
            } else if (el.name === "page") {
                params.page = parseInt(el.value, 10);
            } else if (el.name === "sort") {
                params.sort = el.value;
            } else if (el.name === "order") {
                if (el.checked) {
                    params.order = el.value;
                }
            } else if (el.name === "mode") {
                if (el.checked) {
                    params.mode = el.value;
                }
            } else if (el.name.indexOf("filter.") === 0) {
                var name = el.name.substring(7);
                if (!params.filters[name]) {
                    params.filters[name] = [];
                }
                switch (el.type) {
                    case "radio":
                    case "checkbox":
                        if (el.checked) {
                            params.filters[name].push(el.value);
                        }
                        break;
                    case "submit":
                        break;
                    default:
                        params.filters[name].push(el.value);
                        break;
                }
            }
        }

        this.executeQuery(params);
    }

    toggleFacetGroupVisibility = facetGroup => {
        var i = this.state.facetGroupVisibility.indexOf(facetGroup)
        if (i > -1) {
            this.state.facetGroupVisibility.splice(i, 1);
        } else {
            this.state.facetGroupVisibility.push(facetGroup);
        }

        this.setState({ facetGroupVisibility: this.state.facetGroupVisibility });
    }

    isFacetGroupVisible = facetGroup => {
        return this.state.facetGroupVisibility.indexOf(facetGroup) > -1;
    }

    getFacetValueUi = (buckets, facetFieldName, facetFieldLabel) => {
        var values = buckets.filter(facet => {
            return (facet?.val && (!this.state.contextualFilters[facetFieldName] || facet.val.toLowerCase().indexOf(this.state.contextualFilters[facetFieldName]) > -1))
        });

        if (values.length > 0) {
            return values.map(facet => {
                if (facet && facet.val && facet.val !== null && facet.val !== "" && facet.count !== "0" && facet.count !== 0) {
                    var label = facet.val;
                    return (
                        <div key={facet.val} className="facet__item facet__item--checkBoxes" >
                            <input id={"filter." + facetFieldName + "--" + facet.val} name={"filter." + facetFieldName} value={facet.val} type="checkbox" checked={this.isFilterChecked(facetFieldName, facet.val)} onChange={this.onFormChange} />
                            <label htmlFor={"filter." + facetFieldName + "--" + facet.val}>{label} ({facet.count})</label>
                        </div>
                    )
                }
            })
        } else {
            return <div className="facet__item--no-results">No matches found in {facetFieldLabel}</div>
        }
            
    }


    onFormChange = function () {
        this.pageRef.value = 0;
        this.submitForm();
    }

    getFacets = (searchData, searchConfig) => {

        if (searchData?.facets && searchConfig?.ui?.facets) {

            var facetKeys = Object.keys(searchConfig.ui.facets);

            return facetKeys.filter( facetFieldName => facetFieldName !== "theme").map(facetFieldName => {

                let facetUI = searchConfig.ui.facets[facetFieldName],
                    facetData = searchData.facets[facetFieldName],
                    show = true,
                    fields = Object.keys(facetUI.filters);

                if (fields && fields.length) {
                    show = false;

                    fields.map( fieldName => {
                        if (!show) {
                            try {
                                let selectedValues = this.state.qsValues.filters[fieldName],
                                    neededValues = facetUI.filters[fieldName],
                                    intersection = neededValues.filter(x => selectedValues.includes(x));
    
                                ////console.log(selectedValues, neededValues, intersection);
                                show = !!(intersection.length > 0);
                            } catch (error) {
                                
                            }
                        }
                    });
                }

                if (show && facetUI && facetData?.buckets?.length) {
                    return (
                        <div className="facets__container facets__slide" key={facetFieldName} onMouseLeave={() => {
                            if (this.isFacetGroupVisible(facetFieldName)) {
                                this.toggleFacetGroupVisibility(facetFieldName)
                            }
                        }}>
                            <div className={"accordion facets__category" + (this.isFacetGroupVisible(facetFieldName) ? " active" : "")} category={facetFieldName} onClick={() => this.toggleFacetGroupVisibility(facetFieldName)}>
                                {facetUI.label}
                                <div className="icon-box">
                                    <svg className="svg-icon-size svg-icons_angle-down-dims">
                                        <use xlinkHref="#icons_angle-down" />
                                    </svg>
                                </div>
                            </div>

                            {this.isFacetGroupVisible(facetFieldName) && facetData.buckets.length >= 10 &&
                                <div className={"facets__contextualFilter facets__contextualFilter--" + facetFieldName}>
                                    <input placeholder={"Search " + facetUI.label} type="search" onChange={event => this.onContextualFilterChange(facetFieldName, event)} />
                                </div>
                            }

                            <div className={"facets__items facets__items--checkBoxes panel" + (" facets__items--" + facetFieldName) + (this.isFacetGroupVisible(facetFieldName) ? " active" : "")} category={facetFieldName}>
                                {this.getFacetValueUi(facetData.buckets, facetFieldName, facetUI.label)}
                            </div>
                        </div>
                    );
                }
            });
        }
    }

    onPageClick = data => {
        this.setState({
            qsValues: {
                ...this.state.qsValues,
                page: data.selected
            }
        }, () => {
            this.executeQuery(this.state.qsValues);
            var speed = this.state.qsValues.limit * 2.5;
            scroller.scrollTo('search__form', {
                duration: speed,
                delay: 100,
                smooth: true,
                offset: -25, // Scrolls to element + 50 pixels down the page
            });
        });
    }

    onLimitChange = event => {
        this.pageRef.value = 0;
        
        this.setState({
            qsValues: {
                ...this.state.qsValues,
                limit: event.target.value
            }
        },
            //this.submitForm
        );
    }

    getActiveFacetValues = () => {
        var values = [];
        let facetKeys = this.state.qsValues.filters ? Object.keys(this.state.qsValues.filters) : [];
        facetKeys.map( facetKey => {
            let facetGroup = this.state.qsValues.filters[facetKey];
            if (typeof facetGroup === "string") {
                facetGroup = [facetGroup];
            }
            facetGroup.map( facetValue => {
                var val = facetValue;

                values.push({
                    name: facetKey,
                    value: val
                })
            })
        })

        return values;
    }

    removeFacetValue = facetObj => {
        var values = {};
        if (typeof facetObj === "string" && facetObj === "*") {
            //keep as empty object;
        } else {
            values = cloneDeep(this.state.qsValues.filters);
            var i = values[facetObj.name].indexOf(facetObj.value);
            if (i > -1) {
                values[facetObj.name].splice(i, 1);
            }
        }

        this.executeQuery({
            ...this.state.qsValues,
            filters: values
        });
    }

    render() {
        if (this.state.briefcase || this.state.missingItems) {            

            let searchConfig = this.props.state.config,
                searchData = this.state.briefcase,
                facetValues = this.getActiveFacetValues(),
                facetUI = searchConfig?.ui?.facets ? searchConfig.ui.facets : {};

            return (
                <div className={"c-search screen screen--search" + (this.state.showFilters ? " c-search__facets--active" : "")}>
                    
                    <Element name="search__form"></Element>

                    { searchData.response?.numFound > 0 &&
                        <form id="c-search__form" className="l--two-col form" method="get" action="" onSubmit={this.onSubmit}>                   
                        <input type="hidden" name="page" defaultValue={this.state.qsValues.page} ref={input => this.pageRef = input}/>
                        <input type="hidden" name="order" defaultValue={this.state.qsValues.order} />      
                        <input type="submit" value="Search" className="c-search__input-submit c-btn c-btn--primary" style={autoSubmitOn ? { display: "none" } : {}} ref={button => this.submitButtonRef = button} />

                        <div className="l-main">

                            <div className="top-bar">
                                {/* <select name="limit" className="c-search__limit" defaultValue={this.state.qsValues.limit} onChange={this.onLimitChange} >
                                    <option value="30" key={30}>30</option>
                                    <option value="60" key={60}>60</option>
                                    <option value="90" key={90}>90</option>
                                </select> */}

                                <div className={"c-search__facets c-search__facets--horizontal" + (this.state.showFilters ? " c-search__facets--active" : "")}>
                                    {this.state.showFilters}
                                    <div className="c-search__facets-form">
                                        {this.getFacets(searchData, searchConfig)}
                                    </div>
                                </div>  

                                <div className="c-search__actions">
                                    {facetValues.length > 0 &&
                                        <a className="c-btn c-btn--primary c-search__actions--clear" onClick={() => this.removeFacetValue("*")}>Clear All</a>
                                    }
                                    <a className="c-btn c-btn--primary c-search__actions--share" href={"mailto:?subject=" + encodeURIComponent("Check out my AspenTech search results") + "&body=" + encodeURIComponent(window.location.href + "&shared=true")}>Share</a>
                                </div>
                            </div>

                            {facetValues.length > 0 && 
                                <div className="c-search__filters c-search__filters--inline ">
                                    <div className="c-search__active-facets c-tab__list">
                                        {facetValues.map( facetObj => (
                                            <a key={ facetObj.name + "_" + facetObj.value} 
                                                className="c-tab c-tab__filter" 
                                                onClick={() => this.removeFacetValue(facetObj)}
                                            >
                                                {facetUI[facetObj.name] ? facetUI[facetObj.name].label + ": " : ""}{facetObj.value}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>
                    </form>
                    } 
                    <div className="c-search__results">
                        <h2 className="c-section__sub-title">Briefcase</h2>
                        {searchData.response?.numFound > 0  && this.state.briefcase  &&
                            <ul className="c-card-list g flex-grid-5">
                                {searchData.response.docs.map && searchData.response.docs.map( asset => (
                                    <li key={asset.id} className="c-card-list__item fi">
                                        <AssetCard 
                                            data={asset}
                                            onAddedToBriefcase={ params => {
                                                this.props.dispatch(loadBriefcaseAction(params))
                                            }}
                                            onRemoveFromBriefcase={params => {
                                                this.props.dispatch(removeBriefcaseAction(params))
                                            }}
                                            inBriefcase={(this.props.state.briefcase.briefcase?.indexOf(asset.sharepoint_id) > -1)} />
                                    </li>
                                ))}
                            </ul>
                        }
                    </div>

                    {searchData.response?.numFound > 0 && searchData.response.numFound > this.state.qsValues.limit &&
                        <ReactPaginate
                            forcePage={parseInt(this.state.qsValues.page, 10)}
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            pageCount={Math.ceil(searchData.response.numFound / this.state.qsValues.limit)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={8}
                            onPageChange={this.onPageClick}

                            containerClassName={'c-search__pagination'}

                            breakClassName={'break'}
                            breakLinkClassName={'break__link'}

                            pageClassName={'page'}
                            pageLinkClassName={'page__link'}

                            activeClassName={'page--active '}
                            activeLinkClassName={'page__link--active'}

                            previousClassName={'previous'}
                            previousLinkClassName={'previous__link'}

                            nextClassName={'next'}
                            nextLinkClassName={'next__link'}

                            disabledClassName={'disabled'}
                        />
                    }


                    {this.state.missingItems?.length > 0 && 
                        <div className="c-search__results">
                            <h2 className="c-section__sub-title">Missing Items</h2>
                                <ul className="c-card-list g flex-grid-5">
                                    {this.state.missingItems.map( asset => (
                                        <li key={asset.item1} className="c-card-list__item fi">
                                            {/* {asset.item2} 
                                            <a onClick={this.props.dispatch(removeBriefcaseAction({
                                                type: "asset",
                                                id: asset.item1
                                            }))}>Remove from Briefcase</a> */}
                                            <AssetCard 
                                                data={{
                                                    sharepoint_id: asset.item1,
                                                    title: asset.item2
                                                }}
                                                onRemoveFromBriefcase={params => {
                                                    this.props.dispatch(removeBriefcaseAction(params))
                                                }}
                                                inBriefcase={true} 
                                            />
                                        </li>
                                    ))}
                                </ul>
                        </div>
                    }
                </div>
            );
        } else {
            return null;
        }
        
    }
}

// Which props do we want to inject, given the global state?
var mapStateToProps = state => {
    return { 
        state: {
            briefcase: state.briefcaseReducer,
            config: state.configReducer,
            user: state.userReducer
        }
    };
};

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(withMsal(Briefcase));