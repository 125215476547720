import React  from 'react';
import { connect } from 'react-redux';
import { Form, Input, Checkbox, TextArea, Section } from 'ui-kit.aspentech.com/dist/components'

import '../Base.scss';
import './HelpRequest.scss';
import {helpRequestService} from "../../services/helpService"
import formConfig from "./formConfig"

class HelpRequest extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          message: "",
          messageType: ""
        };

    }

    success = async (formData, formRef) => {
        try {
            //api call
            const res = await helpRequestService(formData)
            formRef.reset();
            this.setState({message: res.data.message, messageType: "success"})
            //navigate
        } catch(e) {
            this.setState({message: e?.message || "an error occured", messageType: "error"})
        }
    }
    
    error = () => {
        this.setState({message: "Please fix the errors below", messageType: "error"})
    }

    render() {
        return (
            <div className="c-help screen screen--help">
                <div class="c-section">
                    <h2>Request Help</h2>
                </div>
                <p class="c-section__description">Do you need support on a competitive deal, or do you have a question about a competitor? Please provide details here and we will get back to you as soon as possible. Thank you!
                </p> 
                {this.state.message &&
                    <ul className={`global-messages errors`}>
                        <li className={`message message--${this.state.messageType}`}><h3>{this.state.message}</h3></li> 
                    </ul>
                }
                <Section numItemsWide={2}>
                    <Form 
                        onSuccess={this.success} 
                        onError={this.error}
                        errorMode="field"
                        config={formConfig}
                        className="help-request"
                        defaultFormData={{
                            title: "",
                            message: ""
                        }}
                    />
                    <div className="sidebar">                        
                        <div className='note'>
                            <b>Note:</b> Submitting this help form will send an email on your behalf to our Competitive Intelligence team.
                        </div>
                    </div>
                </Section>
                
            </div>
        )
        
    }
}

// Which props do we want to inject, given the global state?
var mapStateToProps = state => {
    return { 
        state: {
            config: state.configReducer,
            user: state.userReducer,
            // briefcase: state.briefcaseReducer 
        }
    };
};

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(HelpRequest);