import React from 'react';
import './Header.scss';
import { Link } from 'react-router-dom';
import SearchForm from '../search/SearchForm';
import { connect } from 'react-redux';

class Header extends React.Component {
    constructor(props) {
        super(props)
    }
  
  render = () => {
    return (
        <div>
            
            <a href="#nav" className={`nav-toggle nav-toggle-menu icon-menu ${this.props.className}`} onClick={(event) => {
                if (this.props.onMobileClick) {
                    this.props.onMobileClick(event);
                }
                
            }}>
                <div className="c-menu-toggle">
                    <span className="top"></span>
                    <span className="middle"></span>
                    <span className="bottom"></span>
                </div>
            </a>

            <header className="c-header" role="banner">
                <div className="c-header__logo">
                    <span className="c-logo c-logo--desktop">
                        <a href="https://www.aspentech.com" target="_blank" className="c-logo__link">
                            <img src="/images/logo_aspentech.svg" className="c-logo--desktop c-logo__image c-logo-link__img" alt="Aspen Tech" style={{height: "40px"}} asp-append-version="true"/>                                
                        </a>
                    </span>
                    <span className="c-logo c-logo--mobile">
                        <a href="https://www.aspentech.com" target="_blank" className="c-logo__link">
                            <img src="/images/aspen_leaf.svg" className="c-logo__image c-logo-link__img" alt="Aspen Tech" style={{height: "40px"}} asp-append-version="true"/>                                                    
                        </a>
                    </span>
                </div>
                
                <SearchForm />
                
                <div className="c-header__controls"> 
                    <a href="#nav" className="nav-toggle nav-toggle-menu icon-menu"><span>Menu</span></a>
                    {!this.props.state.user.isAuthenticated &&
                        <a className="c-btn c-btn--inverse c-btn--small" onClick={this.props.signIn} ><span>Sign in</span></a>
                    }
                    {this.props.state.user.isAuthenticated &&
                        <a className="c-btn c-btn--inverse c-btn--small" onClick={this.props.signOut} ><span>Sign out</span></a>
                    }
                </div>
            </header>
        </div>
    )
    }
  }

  var mapStateToProps = state => {
    return { 
        state: {
            user: state.userReducer
        }
    };
};

  export default connect(mapStateToProps)(Header);