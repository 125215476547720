export const PUBLIC_SEARCH_QUERY = 'PUBLIC_SEARCH_QUERY';
export const PUBLIC_SEARCH_QUERY_SUCCESS = 'PUBLIC_SEARCH_QUERY_SUCCESS';
export const PUBLIC_SEARCH_QUERY_ERROR = 'PUBLIC_SEARCH_QUERY_ERROR';

export const PUBLIC_SEARCH_MLT = 'PUBLIC_SEARCH_MLT';
export const PUBLIC_SEARCH_MLT_SUCCESS = 'PUBLIC_SEARCH_MLT_SUCCESS';
export const PUBLIC_SEARCH_MLT_ERROR = 'PUBLIC_SEARCH_MLT_ERROR';

export const PUBLIC_SEARCH_MLT_REMOVE = 'PUBLIC_SEARCH_MLT_REMOVE';
export const PUBLIC_SEARCH_MLT_REMOVE_SUCCESS = 'PUBLIC_SEARCH_MLT_REMOVE_SUCCESS';
export const PUBLIC_SEARCH_MLT_REMOVE_ERROR = 'PUBLIC_SEARCH_MLT_REMOVE_ERROR';

export const PUBLIC_LOAD_CONFIG = 'PUBLIC_LOAD_CONFIG';
export const PUBLIC_LOAD_CONFIG_SUCCESS = 'PUBLIC_LOAD_CONFIG_SUCCESS';
export const PUBLIC_LOAD_CONFIG_ERROR = 'PUBLIC_LOAD_CONFIG_ERROR';

export const PUBLIC_SUGGEST_QUERY = 'PUBLIC_SUGGEST_QUERY';
export const PUBLIC_SUGGEST_QUERY_SUCCESS = 'PUBLIC_SUGGEST_QUERY_SUCCESS';
export const PUBLIC_SUGGEST_QUERY_ERROR = 'PUBLIC_SUGGEST_QUERY_ERROR';

export const PUBLIC_SUGGEST_CLEAR = 'PUBLIC_SUGGEST_CLEAR';
export const PUBLIC_SUGGEST_CLEAR_SUCCESS = 'PUBLIC_SUGGEST_CLEAR_SUCCESS';
export const PUBLIC_SUGGEST_CLEAR_ERROR = 'PUBLIC_SUGGEST_CLEAR_ERROR';

export const PUBLIC_LOAD_PACKAGES = 'PUBLIC_LOAD_PACKAGES';
export const PUBLIC_LOAD_PACKAGES_SUCCESS = 'PUBLIC_LOAD_PACKAGES_SUCCESS';
export const PUBLIC_LOAD_PACKAGES_ERROR = 'PUBLIC_LOAD_PACKAGES_ERROR';

export const PUBLIC_LOAD_PACKAGE_ASSETS = 'PUBLIC_LOAD_PACKAGE_ASSETS';
export const PUBLIC_LOAD_PACKAGE_ASSETS_SUCCESS = 'PUBLIC_LOAD_PACKAGE_ASSETS_SUCCESS';
export const PUBLIC_LOAD_PACKAGE_ASSETS_ERROR = 'PUBLIC_LOAD_PACKAGE_ASSETS_ERROR';

export const PUBLIC_LOAD_ASSETS = 'PUBLIC_LOAD_ASSETS';
export const PUBLIC_LOAD_ASSETS_SUCCESS = 'PUBLIC_LOAD_ASSETS_SUCCESS';
export const PUBLIC_LOAD_ASSETS_ERROR = 'PUBLIC_LOAD_ASSETS_ERROR';

export const PUBLIC_BRIEFCASE_LOAD = 'PUBLIC_BRIEFCASE_LOAD';
export const PUBLIC_BRIEFCASE_LOAD_SUCCESS = 'PUBLIC_BRIEFCASE_LOAD_SUCCESS';
export const PUBLIC_BRIEFCASE_LOAD_ERROR = 'PUBLIC_BRIEFCASE_LOAD_ERROR';

export const PUBLIC_BRIEFCASE_ADD = 'PUBLIC_BRIEFCASE_ADD';
export const PUBLIC_BRIEFCASE_ADD_SUCCESS = 'PUBLIC_BRIEFCASE_ADD_SUCCESS';
export const PUBLIC_BRIEFCASE_ADD_ERROR = 'PUBLIC_BRIEFCASE_ADD_ERROR';

export const PUBLIC_BRIEFCASE_REMOVE = 'PUBLIC_BRIEFCASE_REMOVE';
export const PUBLIC_BRIEFCASE_REMOVE_SUCCESS = 'PUBLIC_BRIEFCASE_REMOVE_SUCCESS';
export const PUBLIC_BRIEFCASE_REMOVE_ERROR = 'PUBLIC_BRIEFCASE_REMOVE_ERROR';

export const PUBLIC_LOAD_PROFILE = 'PUBLIC_LOAD_PROFILE';
export const PUBLIC_LOAD_PROFILE_SUCCESS = 'PUBLIC_LOAD_PROFILE_SUCCESS';
export const PUBLIC_LOAD_PROFILE_ERROR = 'PUBLIC_LOAD_PROFILE_ERROR';

export const PUBLIC_PROFILE_INDUSTRY_CHANGE = 'PUBLIC_PROFILE_INDUSTRY_CHANGE';
export const PUBLIC_PROFILE_INDUSTRY_CHANGE_SUCCESS = 'PUBLIC_PROFILE_INDUSTRY_CHANGE_SUCCESS';
export const PUBLIC_PROFILE_INDUSTRY_CHANGE_ERROR = 'PUBLIC_PROFILE_INDUSTRY_CHANGE_ERROR';

export const PUBLIC_LOAD_BRIEFCASE_ASSETS = 'PUBLIC_LOAD_BRIEFCASE_ASSETS';
export const PUBLIC_LOAD_BRIEFCASE_ASSETS_SUCCESS = 'PUBLIC_LOAD_BRIEFCASE_ASSETS_SUCCESS';
export const PUBLIC_LOAD_BRIEFCASE_ASSETS_ERROR = 'PUBLIC_LOAD_BRIEFCASE_ASSETS_ERROR';

export const PUBLIC_UPDATE_TOKEN = 'PUBLIC_UPDATE_TOKEN';
export const PUBLIC_UPDATE_TOKEN_SUCCESS = 'PUBLIC_UPDATE_TOKEN_SUCCESS';
export const PUBLIC_UPDATE_TOKEN_ERROR = 'PUBLIC_UPDATE_TOKEN_ERROR';

export const PUBLIC_CHECK_ISEDITOR = 'PUBLIC_CHECK_ISEDITOR';
export const PUBLIC_CHECK_ISEDITOR_SUCCESS = 'PUBLIC_CHECK_ISEDITOR_SUCCESS';
export const PUBLIC_CHECK_ISEDITOR_ERROR = 'PUBLIC_CHECK_ISEDITOR_ERROR';

export const PUBLIC_CHECK_ISARCHITECT = 'PUBLIC_CHECK_ISARCHITECT';
export const PUBLIC_CHECK_ISARCHITECT_SUCCESS = 'PUBLIC_CHECK_ISARCHITECT_SUCCESS';
export const PUBLIC_CHECK_ISARCHITECT_ERROR = 'PUBLIC_CHECK_ISARCHITECT_ERROR';

export const PUBLIC_TAKESNAPSHOT = 'PUBLIC_TAKESNAPSHOT';
export const PUBLIC_TAKESNAPSHOT_SUCCESS = 'PUBLIC_TAKESNAPSHOT_SUCCESS';
export const PUBLIC_TAKESNAPSHOT_ERROR = 'PUBLIC_TAKESNAPSHOT_ERROR';

export const PUBLIC_IMPORTSNAPSHOT = 'PUBLIC_IMPORTSNAPSHOT';
export const PUBLIC_IMPORTSNAPSHOT_SUCCESS = 'PUBLIC_IMPORTSNAPSHOT_SUCCESS';
export const PUBLIC_IMPORTSNAPSHOT_ERROR = 'PUBLIC_IMPORTSNAPSHOT_ERROR';