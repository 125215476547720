import React from 'react';

// PUBLIC ROUTES
import Packages from "../screens/packagesScreen/PackagesScreen";
import {ThemeType} from "../constants";
import Search from '../screens/search/Search';
import { Redirect } from 'react-router-dom';
import Briefcase from '../screens/briefcase/Briefcase';
import HelpRequest from '../screens/helpRequest/HelpRequest';
import Editor from '../screens/editor/Editor';

export const routes = [
  {
    path: "/",
    menuLabel: "",
    exact: true,
    private: false,
    main: props => <Redirect to="/News" />
  },
  {
    path: "/Editor",
    menuLabel: "",
    exact: true,
    private: false,
    main: props => <Editor id={ThemeType.Editor} {...props} />
  },
  {
      path: "/News",
      menuLabel: "News",
      exact: true,
      private: true,
      main: props => <Packages id={ThemeType.NewsAndUpdate} {...props} />
  },
  {
    path: "/Plays",
    menuLabel: "Sales Plays",
    exact: true,
    private: true,
    main: props => <Packages id={ThemeType.SalesPlay} {...props} />
  },
  {
    path: "/SuccessStories",
    menuLabel: "Success Stories",
    exact: true,
    private: true,
    main: props => <Packages id={ThemeType.CaseStudy} {...props} />
  },
  {
    path: "/Intel",
    menuLabel: "Competitive Intelligence",
    exact: true,
    private: true,
    main: props => <Packages id={ThemeType.CompetitiveIntelligence} {...props} />
  },
  {
    path: "/Products",
    menuLabel: "Products & Solutions",
    exact: true,
    private: true,
    main: props => <Packages id={ThemeType.ProductAndPricing} {...props} />
  },
  {
    path: "/SalesProcess",
    menuLabel: "Insights",
    exact: true,
    private: true,
    main: props => <Packages id={ThemeType.SalesProcess} {...props} />
  },
  {
    path: "/Events",
    menuLabel: "Aspen Events",
    exact: true,
    private: true,
    main: props => <Packages id={ThemeType.AspenEvents} {...props} />
  },
  {
    path: "/Deployment",
    menuLabel: "Pricing & Promos",
    exact: true,
    private: true,
    main: props => <Packages id={ThemeType.DeploymentAndLicensing} {...props} />
  },
  {
    path: "/Partners",
    menuLabel: "Partners",
    exact: true,
    private: true,
    main: props => <Packages id={ThemeType.Partners} {...props} />
  },
  {
    path: "/Training",
    menuLabel: "Sales Training",
    exact: true,
    private: true,
    main: props => <Packages id={ThemeType.SalesTraining} {...props} />
  },
  {
    path: "/Briefcase",
    menuLabel: "My Briefcase",
    exact: true,
    private: true,
    main: props => <Briefcase theme={ThemeType.Briefcase} {...props} />
  },
  {
    path: "/Package/:packageId",
    menuLabel: "Package",
    exact: true,
    private: true,
    main: props => <Packages {...props} id={props.match.params.packageId} />
  },
  {
    path: "/Search",
    menuLabel: "Search",
    exact: true,
    private: true,
    main: props => <Search {...props} />
  },
  {
    path: "/Help",
    menuLabel: "Help",
    exact: true,
    private: true,
    main: props => <HelpRequest {...props} />
  }
];
