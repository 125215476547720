const _reducer = function(output, current) {
    var result = "";

    if (typeof current === "object") {
        var key = Object.keys(current)[0];
        var value = current[key];

        result = `${key}.${value}`;
    } else {
        result = current;
    }

    if (output.length > 0) {
        output += ".";
    }

    return output + result;
}

export const o2s = obj => {
    if (Array.isArray(obj)) {
        return obj.reduce(_reducer, "")
    }
}