import { combineReducers } from 'redux';
import briefcaseReducer from './briefcaseReducer';
import configReducer from './configReducer';
import searchReducer from './searchReducer';
import userReducer from "./userReducer";

const rootReducer = combineReducers({
    briefcaseReducer,
    configReducer,
    searchReducer,
    userReducer
});

export default rootReducer;