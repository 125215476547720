import React from 'react';
import { connect } from 'react-redux';
import {
    loadBriefcaseAction,
    removeBriefcaseAction
} from "../../redux/actions/actions";

import '../Base.scss';
import './Search.scss';
import qs from "qs";
import ReactPaginate from 'react-paginate';
import Autosuggest from 'react-autosuggest';
//import moment from 'moment';
import { Element, scroller } from 'react-scroll';
import { Explain } from "../../components/explain/Explain";
import { AssetCard } from '../../components/cards/AssetCard';
import { searchMLTService, searchQueryService, suggestQueryService } from '../../services/searchService';
import Modal from '../../components/modal/Modal';
import { PackageCard } from '../../components/cards/PackageCard';
import { withMsal } from '@azure/msal-react';
import EditorForm from '../../components/editorForm/EditorForm';
import { FaPen } from 'react-icons/fa';
import { get, save } from '../../services/editorService'
import { unformatRow } from '../editor/EditorTable.service'
import * as assetFormConfig from "../../components/editorForm/assetsForm.config"
import { FIELDS_MAPPING } from '../editor/Editor.config'
import { cloneDeep as _cloneDeep } from "lodash";


var autoSubmitOn = true;
var typeAheadOn = true;
var moreLikeThisOn = true;

var modes = [
    {
        label: "List",
        value: "list"
    },
    {
        label: "Grid",
        value: "grid"
    }
];

var orders = [
    {
        label: "Asc",
        value: "asc"
    },
    {
        label: "Desc",
        value: "desc"
    }
];

var defaults = {
    filters: {},
    query: "",
    facetsOn: true,
    limit: 24,
    page: 0,
    sort: "score",
    order: "desc",
    mlt: [],
    mode: "grid",
    children: true,
};

var querystringParamList = [
    "filters",
    "query",
    "limit",
    "page",
    "sort",
    "order",
    "mlt",
    "mode",
    "explain"
];

var searchQueryParamList = [
    "filters",
    "query",
    "limit",
    "page",
    "sort",
    "order",
    "facetsOn",
    "explain",
    "children"
];

var sortFields = [
    {
        label: "By Relevance",
        value: "score"
    },
    {
        label: "Created Date",
        value: "created"
    },
    {
        label: "Modified Date",
        value: "modified"
    }
];

var cloneDeep = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            qFocused: false,
            qsValues: {},
            facetGroupVisibility: [],
            mlt: [],
            showFilters: true,
            contextualFilters: {},
            editMode: false,
            selectedFile: null
        };

        this.pageRef = React.createRef();
        this.blurTimer = null;
        this.onSubmit = this.onSubmit.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.onMLTChange = this.onMLTChange.bind(this);
        this.isFilterChecked = this.isFilterChecked.bind(this);
        this.isModeChecked = this.isModeChecked.bind(this);
    }

    componentDidMount = async () => {
        //console.log("SEARCH", "MOUNT")
        if (this.props.state.user.isAuthenticated) {
            this.getRecords()

            this.setInitialFacetVisibility()
        }
    }

    setInitialFacetVisibility = () => {
        let qsParams = this.getQuerystringObject()
            
        let filterKeys = qsParams.filters ? Object.keys(qsParams.filters) : [];
        Object.keys(filterKeys).map( facetGroupIndex => {
            const facetGroup = filterKeys[facetGroupIndex]

            if (!this.isFacetGroupVisible(facetGroup)) {
                this.toggleFacetGroupVisibility(facetGroup)
            }
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!prevProps.state.user.isAuthenticated && this.props.state.user.isAuthenticated) {
            this.getRecords()

            this.setInitialFacetVisibility()

        } else if (this.props.state.user.isAuthenticated) {
            let qs = this.getQuerystringObject();
            if (JSON.stringify(qs) !== JSON.stringify(this.state.qsValues)) {
                var makeFiltersvisible = (!prevProps.location?.state?.redirected && this.props.location?.state?.redirected)
                this.executeQuery(qs, false, makeFiltersvisible);
            }
        }
    }

    getRecords  = () => {
        let qs = this.getQuerystringObject();        
        this.executeQuery(qs);

        try {
            var facets = this.props.state.config.ui.facets;
            var keys = Object.keys(facets);
            keys.map( key => {
                var facet = facets[key];

                if (facet.expanded) {
                    if (!this.isFacetGroupVisible(facet.name)) {
                        this.toggleFacetGroupVisibility(facet.name)
                    }
                }
            });

            this.configured = true;
        } catch (error) {
            
        }
    }

    fetchSuggestions = async (query) => {
        if (typeAheadOn) {
            if (query && query.length) {
                try {
                    let res = await suggestQueryService({
                        params: {query: query} 
                    });
    
                    this.setState({search: {
                        ...this.state.search,
                        suggestions: res.data.data.suggest.suggest[query]?.suggestions || []
                    }})
                } catch (error) {
                    console.error(error)
                }
            } else {
                this.onSuggestionsClearRequested();
            }
        }
    }

    getSuggestionValue = function (suggestion) {
        return suggestion.term;
    }

    onSuggestionsFetchRequested = ({ value, reason }) => {
        switch (reason) {
            case 'input-focused':
            case 'escape-pressed':
            case 'suggestions-revealed':
            case 'suggestion-selected':
                break;
            case 'input-changed':
            default:
                this.fetchSuggestions(value);
                break;
        }
    }

    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        this.setState({
            qsValues: {
                ...this.state.qsValues,
                query: suggestionValue
            }
        },
            () => {
                this.submitForm();
                this.fetchSuggestions(suggestionValue);
            }
        );
    }

    onSuggestionsClearRequested = () => {
        this.setState({search: { suggestions: [] }})
    };

    renderSuggestion(suggestion) {
        return (
            <span>{suggestion.term}</span>
        );
    }

    getQuerystringObject = function () {
        if (!this.props.qsParams) {
            var retVal = this.props.location?.search || "";

            if (retVal.substring(0, 1) === "?") {
                retVal = retVal.substring(1);
            }

            var qsParams = qs.parse(retVal, {
                plainObjects: true,
                allowDots: true
            });

            qsParams = {
                ...defaults,
                ...qsParams
            }

            Object.keys(qsParams).map(key => {
                if (querystringParamList.indexOf(key) === -1) {
                    delete qsParams[key];
                }

                // if (moreLikeThisOn) {
                //     if (typeof qsParams.mlt === "string") {
                //         qsParams.mlt = [qsParams.mlt];
                //     }
                // }
            });

            let facetKeys = qsParams.filters ? Object.keys(qsParams.filters) : [];
            facetKeys.map( facetKey => {
                let facetGroup = qsParams.filters[facetKey];
                if (typeof facetGroup === "string") {
                    facetGroup = [facetGroup];
                }
                qsParams.filters[facetKey] = facetGroup
            })
        }
        else {
            qsParams = this.props.qsParams
        }

        return qsParams
    }

    isFilterChecked = (name, value) => {
        return (typeof this.state.qsValues.filters === "object" && this.state.qsValues.filters[name] && this.state.qsValues.filters[name].indexOf(value.toString()) > -1);
    }

    isMLTChecked = (value) => {
        return (moreLikeThisOn && typeof this.state.moreLikeThis === "object" && Object.keys(this.state.moreLikeThis).indexOf(value) > -1);
    }

    executeQuery = async (params, skipSubmit, shouldMakeFiltersVisible) => {
        var searchParams = cloneDeep({
            ...defaults,
            ...params
        });

        var testFilters = qs.stringify(searchParams.filters, {
            allowDots: true,
            indices: false,
            encode:true,
            encodeValuesOnly: true
        });

        if (!testFilters) {
            searchParams.filters = "";
        };

        var qsParams = cloneDeep(searchParams);

        Object.keys(qsParams).map(key => {
            if (querystringParamList.indexOf(key) === -1) {
                delete qsParams[key];
            }
        });

        var s = qs.stringify(qsParams, {
            allowDots: true,
            indices: false,
            encode:true,
            encodeValuesOnly: true
        });

        if (window.location.search.length > 1) {
            this.props.history && this.props.history.push({
                search: "?" + s
            });
        } else {
            this.props.history && this.props.history.replace({
                search: "?" + s
            });
        }
       

        this.setState({
            qsValues: qsParams
        }, async () => {

            if (!skipSubmit) {
                Object.keys(searchParams).map(key => {
                    if (searchQueryParamList.indexOf(key) === -1) {
                        delete searchParams[key];
                    }
                });

                try {
                    let res = await searchQueryService({
                        params: {
                            ...searchParams,
                            facetFilter: "search",
                            parents: false
                        } 
                    });

                    
                    // delete res.data.data.facets.count
                    
                    this.setState({search: res.data.data}, () => {
                        if (shouldMakeFiltersVisible) {
                            this.setInitialFacetVisibility()
                        }
                    })
                }  catch (error) {
                    console.error(error)
                    if (error.response?.status === 401 && this.props.login) {
                        this.props.login()
                    }
                }
            }
        })
    }

    onFormChange = function () {

        this.pageRef.value = 0;

        if (autoSubmitOn && this.submitButtonRef) {
            this.submitForm();
        }
    }

    async onMLTChange(id) {
        if (moreLikeThisOn) {
            try {
                let res = await searchMLTService({
                    params: {
                        id: id
                    } 
                });

                this.setState({
                    mlt: res.data.data 
                })
                        
            } catch (error) {
                console.error(error)
            }
        }
    }

    submitForm = () => {
        this.submitButtonRef.click();
    }

    onSubmit(event) {
        event.preventDefault();

        var params = cloneDeep(defaults);

        for (var el of event.target) {

            if (el.name === "query") {
                params.query = el.value;
            } else if (el.name === "limit") {
                params.limit = parseInt(el.value, 10);
            } else if (el.name === "page") {
                params.page = parseInt(el.value, 10);
            } else if (el.name === "sort") {
                params.sort = el.value;
            } else if (el.name === "order") {
                if (el.checked) {
                    params.order = el.value;
                }
            } else if (el.name === "mode") {
                if (el.checked) {
                    params.mode = el.value;
                }
            } else if (el.name.indexOf("filter.") === 0) {
                var name = el.name.substring(7);
                if (!params.filters[name]) {
                    params.filters[name] = [];
                }
                switch (el.type) {
                    case "radio":
                    case "checkbox":
                        if (el.checked) {
                            params.filters[name].push(el.value);
                        }
                        break;
                    case "submit":
                        break;
                    default:
                        params.filters[name].push(el.value);
                        break;
                }
            }
        }

        this.executeQuery(params);
    }

    toggleFacetGroupVisibility = facetGroup => {
        var i = this.state.facetGroupVisibility.indexOf(facetGroup)
        if (i > -1) {
            this.state.facetGroupVisibility.splice(i, 1);
        } else {
            this.state.facetGroupVisibility.push(facetGroup);
        }

        this.setState({ facetGroupVisibility: this.state.facetGroupVisibility });
    }

    isFacetGroupVisible = facetGroup => {
        return this.state.facetGroupVisibility.indexOf(facetGroup) > -1;
    }

    getFacetValueUi = (buckets, facetFieldName, facetFieldLabel) => {
        var values = buckets.filter(facet => {
            return (facet && facet.val && (!this.state.contextualFilters[facetFieldName] || facet.val.toLowerCase().indexOf(this.state.contextualFilters[facetFieldName]) > -1))
        });

        if (values.length > 0) {
            return values.map(facet => {
                if (facet && facet.val && facet.val !== null && facet.val !== "" && facet.count !== "0" && facet.count !== 0) {
                    var label = facet.val;
                    return (
                        <div key={facet.val} className="facet__item facet__item--checkBoxes" >
                            <input id={"filter." + facetFieldName + "--" + facet.val} name={"filter." + facetFieldName} value={facet.val} type="checkbox" checked={this.isFilterChecked(facetFieldName, facet.val)} onChange={this.onFormChange} />
                            <label htmlFor={"filter." + facetFieldName + "--" + facet.val}>{label} ({facet.count})</label>
                        </div>
                    )
                }
            })
        } else {
            return <div className="facet__item--no-results">No matches found in {facetFieldLabel}</div>
        }
            
    }

    getFacets = (searchData, searchConfig) => {
        if (searchData && searchData.facets && searchConfig && searchConfig.ui && searchConfig.ui.facets) {

            var facetKeys = Object.keys(searchConfig.ui.facets);

            return facetKeys.map(facetFieldName => {

                let facetUI = searchConfig.ui.facets[facetFieldName],
                    facetData = searchData.facets[facetFieldName],
                    show = true,
                    fields = Object.keys(facetUI.filters);

                if (fields && fields.length) {
                    show = false;

                    fields.map( fieldName => {
                        if (!show) {
                            try {
                                let selectedValues = this.state.qsValues.filters[fieldName],
                                    neededValues = facetUI.filters[fieldName],
                                    intersection = neededValues.filter(x => selectedValues.includes(x));
    
                                ////console.log(selectedValues, neededValues, intersection);
                                show = !!(intersection.length > 0);
                            } catch (error) {
                                
                            }
                        }
                    });
                }

                if (show && facetUI && facetData && facetData.buckets && facetData.buckets.length) {
                    return (
                        <div className="facets__container facets__slide" key={facetFieldName}>
                            <div className={"accordion facets__category" + (this.isFacetGroupVisible(facetFieldName) ? " active" : "")} category={facetFieldName} onClick={() => this.toggleFacetGroupVisibility(facetFieldName)}>
                                {facetUI.label}
                                <div className="icon-box">
                                    <svg className="svg-icon-size svg-icons_angle-down-dims">
                                        <use xlinkHref="#icons_angle-down" />
                                    </svg>
                                </div>
                            </div>

                            {this.isFacetGroupVisible(facetFieldName) && facetData.buckets.length >= 10 &&
                                <div className={"facets__contextualFilter facets__contextualFilter--" + facetFieldName}>
                                    <input placeholder={"Search " + facetUI.label} type="search" onChange={event => this.onContextualFilterChange(facetFieldName, event)} />
                                </div>
                            }

                            <div className={"facets__items facets__items--checkBoxes panel" + (" facets__items--" + facetFieldName) + (this.isFacetGroupVisible(facetFieldName) ? " active" : "")} category={facetFieldName}>
                                {this.getFacetValueUi(facetData.buckets, facetFieldName, facetUI.label)}
                            </div>
                        </div>
                    );
                }
            });
        }
    }

    onPageClick = data => {
        this.setState({
            qsValues: {
                ...this.state.qsValues,
                page: data.selected
            }
        }, () => {
            this.submitForm();
            var speed = this.state.qsValues.limit * 2.5;
            scroller.scrollTo('search__form', {
                duration: speed,
                delay: 100,
                smooth: true,
                offset: -25, // Scrolls to element + 50 pixels down the page
            });
        });
    }

    onLimitChange = event => {
        this.pageRef.value = 0;
        
        this.setState({
            qsValues: {
                ...this.state.qsValues,
                limit: event.target.value
            }
        },
            this.submitForm
        );
    }

    onSortChange = event => {

        this.pageRef.value = 0;

        this.setState({
            qsValues: {
                ...this.state.qsValues,
                sort: event.target.value
            }
        },
            this.submitForm
        );
    }

    onContextualFilterChange = (fieldName, event) => {
        var val = event.target.value.toLowerCase();

        var newObj = {};
        newObj[fieldName] = val;

        this.setState({
            contextualFilters: {
                ...this.state.contextualFilters,
                ...newObj
            }
        });
    }

    getHighlightFieldValue = (highlighting, doc, fieldName) => {
        if (highlighting && highlighting[doc.id] && highlighting[doc.id][fieldName]) {
            return highlighting[doc.id][fieldName];
        } else {
            return doc[fieldName];
        }
    }

    createResultItemKey(id, index, level, mltIndex) {
        mltIndex = mltIndex === undefined ? "root" : mltIndex;
        return `${id}_${index}_${level}_${mltIndex}`;
    }

    getGridCard = (doc, index, searchConfig, searchData, level = 0, mltIndex) => {
        return ( 
            <AssetCard 
                editMode={this.state.editMode} 
                onEdit={(record) => {
                    if (this.state.editMode) {
                        get({id: record.sharepoint_id}, `${record.type}s`, false, this.props.claimsRequest).then(
                            (response) => {
                                var record = unformatRow(response.data.data)
                                record.url = `/images/cache/${
                                    record.imageName
                                    }.png?url=${encodeURIComponent(record.url)}`
                                this.setState({editAsset: { ...record, type: doc.type, sharepoint_id: doc.sharepoint_id }})
                            },
                            (ex) => {
                                this.setState({editAsset: null})
                            }
                        )
                    }
                }}
                data={doc} 
                mlt={moreLikeThisOn}
                onMLT={(e) => {
                    e.preventDefault()
                    this.onMLTChange(doc.id)
                }}
                onAddedToBriefcase={ params => {

                    this.props.dispatch(loadBriefcaseAction(params))

                }}
                onRemoveFromBriefcase={params => {

                    this.props.dispatch(removeBriefcaseAction(params))
                    
                }}
                inBriefcase={(this.props.state.briefcase?.indexOf && this.props.state.briefcase.indexOf(doc.sharepoint_id) > -1)} 
            />
        )
    }

    getListCard = (doc, index, searchConfig, searchData, level = 0, mltIndex) => (
        <div key={this.createResultItemKey(doc.id, index, level, mltIndex)} className={"c-item" + " " + this.createResultItemKey(doc.id, index, level, mltIndex)}>

            <div className="c-item__flag">{doc.data_source}</div>

            {doc.explain &&
                <Explain explanation={doc.explain} />
            }

            <div className="c-item__body">
                {doc.url &&
                    <a className="c-item__headline" href={this.replaceTokens("url", doc.url)} target="searchResult" dangerouslySetInnerHTML={{ __html: this.getHighlightFieldValue(searchData.highlighting, doc, "title") }}></a>
                }

                {!doc.url &&
                    <a className="c-item__headline" target="searchResult" dangerouslySetInnerHTML={{ __html: this.getHighlightFieldValue(searchData.highlighting, doc, "title") }}></a>
                }

                <p className="c-item__sub-headline pipe-seperated-children">
                    {doc.data_source === "Training" &&
                        <React.Fragment>
                            {doc.product &&
                                <span>Product: {doc.product.join(", ")}</span>
                            }

                            {doc.ceus &&
                                <span>CEU: {doc.ceus}</span>
                            }

                            {doc.level &&
                                <span>Level: {doc.level}</span>
                            }

                            {doc.source_id &&
                                <span>Course Id: {doc.source_id}</span>
                            }
                        </React.Fragment>
                    }

                </p>

                <div className="c-item__excerpt line-clamp" lines="3" dangerouslySetInnerHTML={{ __html: this.getHighlightFieldValue(searchData.highlighting, doc, "description") }}></div>
            </div>

            {/* {<p className="c-item__by-line"></p>} */}

            <div className="c-item__options tab-container">

                <div className="tab-bar">
                    <div className="grow" />

                    {moreLikeThisOn && level < 1 &&
                        <div className="tab end">
                            <label className={"accordion accordion--mlt" + (this.isMLTChecked(doc.id) ? " active" : "")} >

                                <input type="checkbox" name={"option_" + doc.id} value={doc.id} defaultChecked={this.isMLTChecked(doc.id)} onChange={this.onMLTChange} />

                                <span>More Like This</span>

                                <div className="icon-box">
                                    <svg className="svg-icon-size svg-icons_angle-down-dims">
                                        <use xlinkHref="#icons_angle-down"></use>
                                    </svg>
                                </div>
                            </label>
                        </div>
                    }

                </div>

                {this.isMLTChecked(doc.id) &&
                    <div className={"tab-panel tab-panel--mlt"}>
                        {(mltIndex !== null || mltIndex !== undefined) && (typeof searchData.moreLikeThis === "object") && (Object.keys(searchData.moreLikeThis).indexOf(doc.id) > -1) &&
                            <ul className="c-list c-list--mlt">
                                {searchData.moreLikeThis[doc.id].map((mltDoc, mltIndex) => (
                                    this.getListCard(mltDoc, index, searchConfig, searchData, level + 1, mltIndex)
                                ))}
                            </ul>
                        }
                    </div>
                }

            </div>
        </div>
    )

    onModeChange = (event) => {
        this.executeQuery({
            ...this.state.qsValues,
            mode: event.target.value
        }, true);
    }

    onShowFiltersChanged = (event) => {
        event.preventDefault();

        this.setState({
            showFilters: !(this.state.showFilters)
        });
    }

    isModeChecked = (mode) => {
        return this.state.qsValues.mode === mode;
    }

    isOrderChecked = (order) => {
        return this.state.qsValues.order === order;
    }

    getActiveFacetValues = () => {
        var values = [];
        let facetKeys = this.state.qsValues.filters ? Object.keys(this.state.qsValues.filters) : [];
        facetKeys.map( facetKey => {
            let facetGroup = this.state.qsValues.filters[facetKey];
            if (typeof facetGroup === "string") {
                facetGroup = [facetGroup];
            }
            facetGroup.map( facetValue => {
                var val = facetValue;

                values.push({
                    name: facetKey,
                    value: val
                })
            })
        })

        return values;
    }

    removeFacetValue = facetObj => {
        var values = {};
        if (typeof facetObj === "string" && facetObj === "*") {
            //keep as empty object;
        } else {
            values = cloneDeep(this.state.qsValues.filters);
            var i = values[facetObj.name].indexOf(facetObj.value);
            if (i > -1) {
                values[facetObj.name].splice(i, 1);
            }
        }

        this.executeQuery({
            ...this.state.qsValues,
            filters: values
        });
    }

    toggleEditMode = (e) => {
        this.setState({ editMode: e.target.checked })
    }

    
    parseManagedMetadata = (fieldObj) => {
        const id = fieldObj.TermSetId;
        const metadata = this.props.state.config.listMetadata[id];
        return Object.entries(metadata.terms).map((term) => {
          return {
            value: term[0],
            label: term[1],
          };
        });
      }
  
    parseChoices =(fieldObj) => {
        return fieldObj.Choices.map((choice) => {
            return {
            value: choice,
            label: choice,
            };
        });
    }
  
    parseChoiceObjects = (fieldObj) => {
        const options = fieldObj.Choices.map((choice) => {
            return {
                value: choice.Id,
                label: choice.Name,
            };
        });

        return options.sort((a, b) => a.label.localeCompare(b.label));
    }

    getOptions = () => {
        if (this.options) {
            return this.options
        }

        const options = {};
        this.props.state.config.listFields["SRC Package Content"].forEach((fieldObj) => {
          switch (fieldObj.FieldTypeKind) {
            case 0: // Managed Metadata
              options[FIELDS_MAPPING?.[fieldObj.Title]] =
              this.parseManagedMetadata(fieldObj);
              break;
            case 6: // Single choice
            case 15: // Multi choice
              options[FIELDS_MAPPING?.[fieldObj.Title]] =
                this.parseChoices(fieldObj);
              break;
            case 25: // special content type choice
              options[FIELDS_MAPPING?.[fieldObj.Title]] =
                this.parseChoiceObjects(fieldObj);
              break;
            default:
              break;
          }
        });
  
        options.productVersion && options.productVersion.reverse(); // Special sorting on productVersions to have the latest version be the top option
        this.options = options
        return options;
    }

    render() {
        let searchConfig = this.props.state.config,
            searchData = this.state.search,
            mltData = this.state.mlt,
            gridMode = (this.state.qsValues.mode === "grid"),
            facetValues = this.getActiveFacetValues(),
            facetUI = searchConfig.ui.facets ? searchConfig?.ui?.facets : {} ,
            editAsset = this.state.editAsset;

        return (
            <div className={"c-search screen screen--search" + (this.state.showFilters ? " c-search__facets--active" : "")}>

                <Element name="search__form"></Element>

               {/* 
                <div className="topAd">
                    <img src="/optimize.png" alt="" className="c-card__img" />
                </div>
                */}
                
                <form id="c-search__form" className="l--two-col form" method="get" action="" onSubmit={this.onSubmit}>              
                    <input type="hidden" name="query" value={this.state.qsValues.query} />          
                    <input type="hidden" name="page" defaultValue={this.state.qsValues.page} ref={input => this.pageRef = input}/>
                    <input type="hidden" name="order" defaultValue={this.state.qsValues.order} />                    
                    <div className="l-main">
                        <div className="top-bar">
                            <select name="limit" className="c-search__limit" defaultValue={this.state.qsValues.limit} onChange={this.onLimitChange} >
                                <option value="24" key={24}>24</option>
                                <option value="48" key={48}>48</option>
                                <option value="72" key={72}>72</option>
                            </select>

                            {/* added className on select sort */}
                            <select name="sort" className="by-relevance" defaultValue={this.state.qsValues.sort} onChange={this.onSortChange}>
                                {sortFields.map(sortField => (
                                    <option key={sortField.value} value={sortField.value}>{sortField.label}</option>
                                ))}
                            </select>

                            <div className="c-search__orders"> 
                                {orders.map(order => (
                                    <label key={order.value} className={"c-search__order c-search__order--" + order.value + (this.isOrderChecked(order.value) ? " c-search__order--active" : "")}>
                                        <input type="radio" name="order" value={order.value} checked={this.isOrderChecked(order.value)} onChange={this.onFormChange} /> <span className="c-search__order-text">{order.label}</span>
                                    </label>
                                ))}
                            </div>

                            <div className={"c-search__facets c-search__facets--horizontal"}></div>

                            <div className="c-search__actions">
                                {facetValues.length > 0 &&
                                    <a className="c-btn c-btn--primary c-search__actions--clear" onClick={() => this.removeFacetValue("*")}>Clear All</a>
                                }
                                <a className="c-btn c-btn--primary c-search__actions--share" href={"mailto:?subject=" + encodeURIComponent("Check out the Sales Resoure Center page") + "&body=" + encodeURIComponent(window.location.href + "&shared=true")}>Share</a>
                                {this.props.state.user.isEditor && <label title={`Turn ${this.state.editMode ? "off" : "on"} edit mode`} className={"c-icon " + (this.state.editMode ? "c-icon--active" : "")}><FaPen /><input type="checkbox" onChange={this.toggleEditMode} /></label>}
                            </div>
                         </div>


                         {facetValues.length > 0 && 
                            <div className="c-search__filters c-search__filters--inline ">
                                <div className="c-search__active-facets c-tab__list">
                                    {facetValues.map( facetObj => (
                                        <a key={ facetObj.name + "_" + facetObj.value} className="c-tab c-tab__filter" onClick={() => this.removeFacetValue(facetObj)}>{facetUI[facetObj.name] ? facetUI[facetObj.name].label + ": " : ""}{facetObj.value}</a>
                                    ))}
                                </div>
                            </div>
                        }

                        <div className="c-search__box">
                            <div className="c-search__input-item">
                                {typeAheadOn && false &&
                                    <Autosuggest
                                        alwaysRenderSuggestions={false}
                                        focusInputOnSuggestionClick={false}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        suggestions={this.state.suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionSelected={this.onSuggestionSelected}
                                        getSuggestionValue={this.getSuggestionValue}
                                        renderSuggestion={this.renderSuggestion}
                                        inputProps={{
                                            type: 'search',
                                            onChange: (event, newValue) => {
                                                this.onFormChange(event);
                                            },
                                            value: this.state.qsValues.query || "",
                                            name: "q",
                                            class: "c-search__input-text",
                                            placeholder: "Search",
                                            autoComplete: "off"
                                        }}
                                        theme={{
                                            container: 'c-search__input-container',
                                            containerOpen: 'c-search__input-item--open',
                                            input: 'c-search__input-text',
                                            inputOpen: 'c-search__input-text--open',
                                            inputFocused: 'c-search__input-text--focused',
                                            suggestionsContainer: 'c-search__input-item__suggestions-container',
                                            suggestionsContainerOpen: 'c-search__input-item__suggestions-container--open',
                                            suggestionsList: 'c-search__input-item__suggestions-list',
                                            suggestion: 'c-search__input-item__suggestion',
                                            suggestionFirst: 'c-search__input-item__suggestion--first',
                                            suggestionHighlighted: 'c-search__input-item__suggestion--highlighted',
                                            sectionContainer: 'c-search__input-item__section-container',
                                            sectionContainerFirst: 'c-search__input-item__section-container--first',
                                            sectionTitle: 'c-search__input-item__section-title'
                                        }}
                                    />
                                }

                                {!typeAheadOn &&
                                    <input
                                        type="search"
                                        name="q"
                                        className="c-search__input-submit c-btn c-btn--primary"
                                        placeholder="Search"
                                        autoComplete="off"
                                        onChange={event => this.onFormChange(event)}
                                        defaultValue={this.state.qsValues.query}
                                    />
                                }
                                <input type="submit" value="Search" className="c-search__input-submit c-btn c-btn--primary" style={autoSubmitOn ? { display: "none" } : {}} ref={button => this.submitButtonRef = button} />

                                <div className="c-search__modes">
                                    {modes.map(mode => (
                                        <label key={mode.value} className={"c-search__mode c-search__mode--" + mode.value + (this.isModeChecked(mode.value) ? " c-search__mode--active" : "")}>
                                            <input type="radio" name="mode" value={mode.value} checked={this.isModeChecked(mode.value)} onChange={this.onModeChange} /> {/*<span className="c-search__mode-text">{mode.label}</span>*/}
                                                <div className={"icon-box c-search__mode--" + (mode.value)}>
                                                
                                                    <svg className={"svg-icon-size svg-icons_"+ mode.value +"-hobo-dims"} >
                                                        <use xlinkHref={"#icons_"+ mode.value +"-hobo"} />
                                                    </svg>
                                                </div>    
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {searchData?.response &&
                            <div className="c-search__count">Found {searchData.response.numFound} Results <span>for <em>"{this.state.qsValues.query}"</em></span></div>
                        }
                        
                        <div className="c-search__results">
                            {mltData?.match?.docs?.length && mltData.response?.docs?.length &&
                                <Modal scrollable={true} fixedTitle={true} title={`More assets like ${mltData.match.docs[0].type} ${mltData.match.docs[0].title}`} onClose={() => {
                                    this.setState({mlt: null})
                                }}>
                                    <ul className="c-card-list g flex-grid-5">
                                    {mltData.response.docs.map( doc => (
                                        <li key={doc.id} className="c-card-list__item fi">
                                            {doc.type === "package" &&
                                                <PackageCard data={doc} />
                                            }
                                            {doc.type === "asset" &&
                                                <AssetCard 
                                                    data={doc} 
                                                    onAddedToBriefcase={ params => {
                                                        this.props.dispatch(loadBriefcaseAction(params))
                                                    }} 
                                                    onRemoveFromBriefcase={params => {
                                                        this.props.dispatch(removeBriefcaseAction(params))
                                                    }}
                                                    inBriefcase={(this.props.state.briefcase.briefcase?.indexOf(doc.sharepoint_id) > -1)}
                                                />
                                            }
                                        </li>
                                    ))}
                                    </ul>
                                </Modal>
                            }
                            {searchData?.response && !this.props.resultList &&
                                <ul className={(gridMode ? " c-card-list g flex-grid-4" : "c-list")}>
                                    {searchData.response.docs.map && searchData.response.docs.map((doc, index) => (
                                        <li key={doc.id} className={(gridMode ? "c-card-list__item fi" : "c-list__item")}>
                                            {gridMode ? this.getGridCard(doc, index, searchConfig, searchData) : this.getListCard(doc, index, searchConfig, searchData)}
                                        </li>
                                    ))}
                                </ul>
                            }
                            {searchData?.response && this.props.resultList && this.props.resultList(searchData)}
                        </div>

                        {searchData?.response && searchData.response.numFound > this.state.qsValues.limit &&
                            <ReactPaginate
                                forcePage={parseInt(this.state.qsValues.page, 10)}
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                pageCount={Math.ceil(searchData.response.numFound / this.state.qsValues.limit)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={8}
                                onPageChange={this.onPageClick}

                                containerClassName={'c-search__pagination'}

                                breakClassName={'break'}
                                breakLinkClassName={'break__link'}

                                pageClassName={'page'}
                                pageLinkClassName={'page__link'}

                                activeClassName={'page--active '}
                                activeLinkClassName={'page__link--active'}

                                previousClassName={'previous'}
                                previousLinkClassName={'previous__link'}

                                nextClassName={'next'}
                                nextLinkClassName={'next__link'}

                                disabledClassName={'disabled'}
                            />
                        }
                    </div>

                    <div className="l-sidebar">
                        <div className={"c-search__facets" + (this.state.showFilters ? " c-search__facets--active" : "")}>
                            {this.state.showFilters}
                            <button className="c-search__facets-toggle" onClick={this.onShowFiltersChanged}> 
                                <svg className="svg-icon-size svg-icons_filter-hobo-dims">
                                    <use xlinkHref="#icons_filter-hobo" />
                                </svg>
                            </button>
                            <div className="c-search__facets-form">
                                {this.getFacets(searchData, searchConfig)}
                            </div>
                        </div>  
                    </div>
                </form>

                {editAsset && 
                            <Modal
                                title={`Edit ${editAsset.type} ${editAsset.sharepoint_id || ""}`}
                                className="edit-modal"
                                onClose={() => {
                                    this.setState({editAsset: null})
                                }}
                                fixedTitle
                            >
                                <EditorForm
                                    config={editAsset?.type === "asset" ? assetFormConfig : null}
                                    editMode="edit-existing"
                                    defaultFormData={editAsset}
                                    fieldOptions={this.getOptions()}
                                    onSuccess={ formData => {
                                        const newRow = _cloneDeep(formData);
                                                                
                                        const multiSelectKeys = Object.keys(newRow).filter((key) =>
                                            Array.isArray(newRow[key])
                                        );
                                        multiSelectKeys.forEach((key) => {
                                            const newValues = [];
                                            const values = newRow[key];
                                            const options = this.getOptions()[key];
                                            values.forEach((value) => {
                                            const option = options.find((option) => option.value === value);
                                            const newOption = _cloneDeep(option);
                                            newOption.id = newOption.value;
                                            newOption.value = newOption.label;
                                            delete newOption.label;
                                            newValues.push(newOption);
                                            });
                                            newRow[key] = newValues;
                                        });

                                        save(
                                            newRow,
                                            "assets",
                                            false,
                                            !!formData.fileName,
                                            this.props.claimsRequest
                                        ).then( response => {
                                            //console.log(response)
                                            this.setState({ editAsset: null })
                                        });
                                    }}
                                />
                            </Modal>
                        }
            </div>
        );
    }
}

// Which props do we want to inject, given the global state?
var mapStateToProps = state => {
    return { 
        state: {
            config: state.configReducer,
            user: state.userReducer,
            briefcase: state.briefcaseReducer 
        }
    };
};

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(withMsal(Search));