import { put, call } from 'redux-saga/effects';
import { searchQueryService } from '../../services/searchService';
import * as types from '../actions';

export function* searchQuerySaga(params) {
  try {
      params.params.parents = false;
      const res = yield call(searchQueryService, {
        params: {
          ...params.params,
          facetFilter: "search"
        }
      }); 
      
      yield put({
          type: types.PUBLIC_SEARCH_QUERY_SUCCESS,
          ...res.data.data
    });

  } catch(error) {

    yield put({ 
      type: types.PUBLIC_SEARCH_QUERY_ERROR, 
      message: error.message
    });

  }
}