import { cloneDeep } from "lodash";
import bem from "../../helpers/bem";
import { Checkbox, Button } from "ui-kit.aspentech.com/dist/components";
import { MdSync, MdCloudDone, MdSyncProblem } from "react-icons/md";
import { FaPen, FaTrash } from "react-icons/fa";
import Tooltip from "../../components/tooltip/Tooltip";

export function getTableData({
  rows,
  columns,
  selectedRows,
  hiddenColumns,
  fieldOptions,
  selectRow,
  editRow,
  deleteRows,
}) {
  const headers = {
    data: [
      {
        key: "status",
        className: bem("column", "", ["fixed", "small", "status"]),
        data: "Status",
      },
      {
        key: "actions",
        className: bem("column", "", ["fixed", "medium", "actions"]),
        data: (
          <Checkbox
            id="selectAll"
            checked={
              selectedRows.length > 0 && selectedRows.length === rows.length
            }
            onChange={(valObj) => selectRow(valObj)}
          />
        ),
      },
    ],
  };

  columns &&
    columns.forEach((column, i) => {
      headers.data.push({
        key: column.key,
        className: bem(
          "column",
          "",
          [hiddenColumns.includes(column.key) ? "hidden" : null],
          column.className ?? null
        ),
        data: column.data,
      });
    });

  const body = { data: [] };

  body.data = body.data.concat(
    rows.map((row) => {
      const tableRow = {
        status: {
          className: bem("column", "", ["fixed", "small", "status"]),
          data: (
            <div className="status">
              {row.status.isDirty && (
                <>
                  <div
                    data-tip={"Unsaved changes"}
                    className={bem("status-icon", "", ["unsynced"])}
                  >
                    <MdSync />
                  </div>
                  <Tooltip />
                </>
              )}
              {!row.status.isDirty && (
                <>
                  <div
                    data-tip={row.status.message}
                    className={bem("status-icon", "", [
                      row.status.sharepointRecordCreated ? "success" : "fail",
                    ])}
                  >
                    {row.status.sharepointRecordCreated ? (
                      <MdCloudDone />
                    ) : (
                      <MdSyncProblem />
                    )}
                  </div>
                  <Tooltip />
                </>
              )}
            </div>
          ),
        },
        actions: {
          className: bem("column", "", ["fixed", "medium", "actions"]),
          data: (
            <div className="row-actions">
              <Checkbox
                id={`checkbox__${row.guid}`}
                className={bem("table", [], "checkbox")}
                checked={selectedRows.find((rowId) => rowId === row.guid)}
                onChange={(valObj) => selectRow({ valObj, rows, selectedRows })}
              />
              <Button
                id={`edit-button__${row.guid}`}
                className="edit-button"
                variant="text"
                onClick={() => editRow(row.guid)}
              >
                <FaPen />
              </Button>
              <Button
                id={`delete-button__${row.guid}`}
                className="delete-button"
                variant="text"
                onClick={() => deleteRows([row.guid])}
              >
                <FaTrash />
              </Button>
            </div>
          ),
        },
      };

      columns &&
        columns.forEach((column, i) => {
          let displayValue;
          if (row[column.key] && Array.isArray(row[column.key])) {
            displayValue = row[column.key]
              .map((value) => {
                return fieldOptions[column.key].find(
                  (option) => option.value === value
                ).label;
              })
              .join(",\r\n");
          } else if (row[column.key] && column.key === "contentType") {
            displayValue = fieldOptions[column.key].find(
              (option) => option.value === row[column.key]
            ).label;
          } else {
            displayValue = row[column.key];
          }

          tableRow[column.key] = {
            data: displayValue ?? null,
            className: bem(
              "column",
              "",
              [hiddenColumns.includes(column.key) ? "hidden" : null],
              column.className ?? null
            ),
          };
        });

      return tableRow;
    })
  );

  return {
    headers,
    body,
  };
}

export function checkUnsavedRows(rows) {
  if (rows) {
    const newRows = [...rows];
    const unsavedRows = newRows.filter(
      (row) => row.status.sharepointRecordCreated === false
    );
    if (unsavedRows.length > 0) return true;
    return false;
  }
}

export function checkIncompleteRows({ rows, currentTab }) {
  let requiredFields = [];
  switch (currentTab) {
    case "Assets":
      requiredFields = [
        "title",
        "description",
        "contentType",
        "assetContent",
        "publishYear",
        "confidentiality",
        "downloadable",
        "published",
        "salesRole",
      ];
      break;
    case "Packages":
      requiredFields = ["title"];
      break;
    default:
      break;
  }

  const completeRows = rows.map((row) => {
    return requiredFields.every((field) => row.hasOwnProperty(field));
  });

  return !completeRows.every((value) => value === true);
}

export function checkDisableSubmit({ rows, currentTab }) {
  if (rows.length === 0) return true;
  return checkIncompleteRows({ rows, currentTab });
}

export function formatRows({ rows, fieldOptions }) {
  return rows.map((row) => formatRow(row, fieldOptions))
}

export function formatRow(row, fieldOptions) {
    const newRow = cloneDeep(row);
    //if (!newRow.status.sharepointRecordCreated) {
      const multiSelectKeys = Object.keys(newRow).filter((key) =>
        Array.isArray(newRow[key])
      );
      multiSelectKeys.forEach((key) => {
        const newValues = [];
        const values = newRow[key];
        const options = fieldOptions[key];
        values.forEach((value) => {
          const option = options.find((option) => option.value === value);
          const newOption = cloneDeep(option);
          newOption.id = newOption.value;
          newOption.value = newOption.label;
          delete newOption.label;
          newValues.push(newOption);
        });
        newRow[key] = newValues;
      });
      return newRow;
    // } else {
    //   return null;
    // }
}


export function unformatRow(row) {
  const newRow = cloneDeep(row);
  if (newRow.status.sharepointRecordCreated) {
    const multiSelectKeys = Object.keys(newRow).filter((key) => Array.isArray(newRow[key]));
    multiSelectKeys.forEach((key) => {
      const newValues = [];
      const values = newRow[key];
      values.forEach((valueObj) => newValues.push(valueObj.id));
      
      newRow[key] = newValues;
    });
    return newRow;
  } else {
    return null;
  }
}
