import React, { createRef } from "react";
import { addBriefcaseAsset } from "../../services/userService";
import httpFactory from "../../utils/httpClient";
import { ImageCache } from "../image/ImageCache";
import moment from "moment";
import { FaRegFile } from "react-icons/fa";
import "./Card.scss";
import { Explain } from "../../components/explain/Explain";
import { Link } from "react-router-dom";
import { Button } from "ui-kit.aspentech.com/dist/components";

const convertToKebabCase = (string) => {
  return string.replace(/\s+/g, "-").toLowerCase();
};

const delayAfterCompletion = 2000;
const progressCheckDelay = 500;
export class AssetCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBusy: false,
      items: [],
      result: "",
      initialized: false,
    };

    this.bar = createRef();
    this.msg = createRef();
  }

  removeBusyOverlay = () => {
    this.setState({ isBusy: false });
  };

  addToBriefcase = (event) => {
    event.preventDefault();

    if (this.msg && this.bar) {
      this.setState({ isBusy: true }, () => {
        addBriefcaseAsset({
          type: "asset",
          id: this.props.data.sharepoint_id,
        }).then(
          (response) => {
            var fileIds = Object.keys(response.data.data.successFileList);
            var fileLength = fileIds.length;
            var completed = 0;

            for (var i = 0; i < fileIds.length; i++) {
              var id = fileIds[i];
              var progressUrl = response.data.data.successFileList[id];
              var onComplete = () => {
                completed += 1;

                if (fileLength === completed) {
                  setTimeout(() => {
                    this.removeBusyOverlay();
                  }, delayAfterCompletion);
                }
              };

              this.pollProgress(progressUrl, onComplete);
            }
          },

          (error) => {
            this.removeBusyOverlay();
          }
        );
      });
    }
  };

  removeFromBriefcase = (event) => {
    event.preventDefault();
    if (this.props.onRemoveFromBriefcase) {
      this.props.onRemoveFromBriefcase({
        type: "asset",
        id: this.props.data.sharepoint_id,
      });
    }
  };

  pollProgress = (url, completed) => {
    httpFactory({ anonymous: true })
      .get(url)
      .then(
        (response) => {
          switch (response.data.status) {
            case "failed":
              this.setState({ result: "failed" });
              var res = "Unknown Error. Please try again.";

              if (response.data.error.code === "nameAlreadyExists") {
                res = "File is already in your briefcase.";
              }

              this.msg.innerText = res;

              completed();

              break;

            case "notStarted":
              setTimeout(() => {
                this.pollProgress(url);
              }, progressCheckDelay);
              break;

            case "inProgress":
              var progress = response.data.percentageComplete + "%";

              this.bar.style.width = progress;

              setTimeout(() => {
                this.pollProgress(url);
              }, progressCheckDelay);

              break;

            case "completed":
              this.props.onAddedToBriefcase();

              this.bar.style.width = response.data.percentageComplete + "%";
              this.msg.innerText = "File copy completed.";

              completed();

              break;

            default:
              console.error("what happened?", response);
              completed();
              break;
          }
        },

        (error) => {}
      );
  };

  getLink = (data) => {
    if (data.id === "card-preview") {
      return (
        <Button
          className="c-card__action c-card__action--read c-btn c-btn--primary"
          onClick={(e) => e.preventDefault()}
        >
          View
        </Button>
      );
    }

    let url = data.web_url.replace(/&amp;/g, "&");

    let islocal = url[0] === "/";
    if (islocal) {
      let pathname = url,
        search,
        state = { redirected: true },
        i = url.indexOf("?");

      if (i >= 0) {
        pathname = url.substring(0, i);
        search = url.substring(i);
      }

      return (
        <Link
          to={{
            pathname,
            search,
            state,
          }}
          className="c-card__action c-card__action--read c-btn c-btn--primary"
        >
          View
        </Link>
      );
    } else {
      return (
        <a
          href={url}
          className="c-card__action c-card__action--read c-btn c-btn--primary"
          target={`asset-${data.id}`}
        >
          View
        </a>
      );
    }
  };

  render() {
    let data = this.props.data,
      editMode = this.props.editMode,
      theme = this.props.theme || "",
      busyClass = this.state.isBusy ? "isBusy" : "",
      inBriefcaseClass = this.props.inBriefcase
        ? "in-briefcase"
        : "not-in-briefcase";

    return (
      <div
        className={`c-card card--asset card--${convertToKebabCase(
          theme
        )} ${busyClass} ${inBriefcaseClass}`}
        data-type={data.type}
        id={data.id}
      >
        <div className="c-card__icon-wrapper">
          <FaRegFile className="c-card__icon" />
        </div>

        {/* {this.state.isBusy &&  */}
        <div className="c-card-overlay copy-overlay">
          <img className="copy-overlay__image" src="/images/ajax_loader.gif" />
          <ul className="copy-overlay__progress-list">
            {/* {this.state.items.map( item => { */}
            <li
              className={`copy-overlay__progress-item ${
                this.state.result
                  ? "copy-overlay__progress-item--" + this.state.result
                  : ""
              }`}
            >
              <div
                className="copy-overlay__progress-bar"
                ref={(el) => {
                  this.bar = el;
                }}
              ></div>
              <div
                className="copy-overlay__progress-message"
                ref={(el) => {
                  this.msg = el;
                }}
              ></div>
            </li>
            {/* })} */}
          </ul>
        </div>
        {/* } */}

        <div className="c-card__media">
          <ImageCache
            src={
              (data.image_url &&
                `/images/cache/${
                  data.sharepoint_id
                }.png?url=${encodeURIComponent(data.image_url)}`) ||
              data.src
            }
            alt={data.title}
            className="c-card__img"
          />
        </div>

        <div className="c-card__body">
          {data.title && <h2 className="c-card__headline">{data.title}</h2>}

          {data.Subtitle && <p>{data.Subtitle}</p>}

          <div
            className="c-card__excerpt line-clamp4"
            lines="3"
            dangerouslySetInnerHTML={{
              __html: data.description,
            }}
          />

          <p className="c-card__date line-clamp" lines="3">
            {moment(data.modified).fromNow()}
          </p>

          {data.publish_year && (
            <p className="c-card__by-line">
              {data.publish_year}
              {data.confidentiality && (
                <React.Fragment>
                  <span>-</span> <span>{data.confidentiality}</span>
                </React.Fragment>
              )}
            </p>
          )}
        </div>

        {data.content_type && (
          <div className="c-card__flag">{data.content_type}</div>
        )}

        <div className="c-card__actions ">
          {data.explain && <Explain explanation={data.explain} />}

          {this.props.mlt && this.props.onMLT && (
            <a
              href="#"
              className="c-card__action c-card__action--mlt c-btn c-btn--default c-btn--inverse"
              onClick={this.props.onMLT}
            >
              More Like This
            </a>
          )}

          {data.id && this.getLink(data)}

          {data.sharepoint_id && editMode && (
            <a
              className="c-card__action c-card__action--id c-btn c-btn--primary"
              onClick={() => {
                navigator.clipboard
                  .writeText(data.sharepoint_id)
                  .then(() => alert("Copied"));
              }}
            >
              Asset Id: {data.sharepoint_id}
            </a>
          )}
          
          {data.sharepoint_id && editMode && this.props.onEdit && 
            <a
              className="c-card__action c-card__action--edit-asset c-btn c-btn--primary"
              onClick={() => {
                this.props.onEdit(data)
              }}
            >
              Edit
            </a>
          }

          {(data.is_downloadable || this.props.inBriefcase) && (
            <React.Fragment>
              <a
                href="#"
                className="c-card__action c-card__action--remove c-btn c-btn--default c-btn--inverse"
                onClick={this.removeFromBriefcase}
              >
                - Remove from Briefcase
              </a>

              <a
                href="#"
                className="c-card__action c-card__action--save c-btn c-btn--default c-btn--inverse"
                onClick={(e) => {
                  if (data.id === "card-preview") {
                    e.preventDefault();
                  } else {
                    this.addToBriefcase(e);
                  }
                }}
              >
                + Add to Briefcase
              </a>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}
