import { put, call } from 'redux-saga/effects';
import { searchMLTService } from '../../services/searchService';
import * as types from '../actions';

export function* searchMLTSaga(params) {
  try {
      const res = yield call(searchMLTService, params); 
      
      yield put({
          type: types.PUBLIC_SEARCH_MLT_SUCCESS,
          ...res.data.data
    });

  } catch(error) {
    
    yield put({ 
      type: types.PUBLIC_SEARCH_MLT_ERROR, 
      message: error.message
    });

  }
}