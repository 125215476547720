import React from 'react';
import { Link } from 'react-router-dom';
import './ProfileOverlay.scss';

export class ProfileOverlay extends React.Component { 
    
    constructor(props) {
      super(props);

      this.state = {
          edit: false
      };
    }
  
    onIndustryChange = (event) => {
        if (this.props.onIndustryChange) {
            var action = event.target.checked ? "add" : "delete";
            var industry = event.target.value;
            this.props.onIndustryChange({
                action,
                industry
            });
        }
    }

    isIndustryChecked = industry => {
        return (this.props.profile.industry.indexOf(industry) > -1);
    }

    render() {
        return (
            <div id="c-overlay--profile" className="c-overlay c-overlay--profile">
                <div className="c-overlay__modal" onClick={this.props.onClose}></div>
                <div className="c-overlay__wrapper">
                    <div className="c-overlay__content">
                        <div id="c-profile-card" className="c-profile-card g g-2up">
                            
                            <div className="c-profile-card__name gi">
                                <h2>{this.props.profile.displayName}</h2>
                                <div>{this.props.profile.jobTitle}</div>
                            </div>

                            <div className="c-profile-card__attributes gi">

                                <h3 className="h3 h3-underline">Region</h3>

                                {this.props.profile.region && this.props.profile.region.length &&
                                    <p>
                                        {this.props.profile.region.join(", ")}
                                    </p>
                                }

                                { this.props.industries && this.props.industries.map &&
                                    <div id="profile-card__attribute--industry" className="profile-card__attribute profile-card__attribute--industry">
                                        <h3 className="h3 h3-underline">
                                            Industries
                                            <a className="c-profile-card__edit-link" href="#" id="c-profile-card__industries--edit-mode" onClick={event => {
                                                event.preventDefault(); 
                                                this.setState({edit: !this.state.edit})}
                                            }>
                                                {this.state.edit ? "Close" : "Edit"}
                                            </a>
                                        </h3>

                                        {this.state.edit && this.props.industries && this.props.industries.map &&
                                            <ul id="c-profile-card__industries--edit" className="c-profile-card__industries">
                                                {
                                                    this.props.industries.map( industry => (
                                                        <div key={industry} className="facet__item">
                                                            <input type="checkbox" id={`industry__option--${industry}`} value={industry} onChange={this.onIndustryChange} checked={this.isIndustryChecked(industry)} />
                                                            <label htmlFor={`industry__option--${industry}`}>{industry}</label>
                                                        </div>
                                                    ))
                                                }
                                            </ul>
                                        }

                                        {!this.state.edit &&
                                            <p id="c-profile-card__industries--readonly" className="c-profile-card__industries c-profile-card__industries--readonly">
                                                {this.props.profile.industry && this.props.profile.industry.map &&
                                                    this.props.profile.industry.map( industry => (
                                                        <span key={industry} className={`industry__option industry__option--${industry}`}>{industry}</span>
                                                    ))
                                                }
                                            </p>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}