import * as types from '.';

export const searchQueryAction = (params) => {
  return {
    type: types.PUBLIC_SEARCH_QUERY,
    params
  }
};

export const searchMLTAction = (params) => {
  return {
    type: types.PUBLIC_SEARCH_MLT,
    params
  }
};

export const searchMLTRemoveAction = (params) => {
  return {
    type: types.PUBLIC_SEARCH_MLT_REMOVE,
    params
  }
};

export const loadConfigAction = (params) => {
  return {
    type: types.PUBLIC_LOAD_CONFIG,
    params
  }
};

export const suggestQueryAction = (params) => {
    return {
        type: types.PUBLIC_SUGGEST_QUERY,
        params
    }
};

export const suggestClearAction = () => {
    return {
        type: types.PUBLIC_SUGGEST_CLEAR
    }
};

export const loadPackagesAction = (params) => {
    return {
        type: types.PUBLIC_LOAD_PACKAGES,
        params
    }
};

export const loadAssetsAction = (params) => {
    return {
        type: types.PUBLIC_LOAD_ASSETS,
        params
    }
};

export const loadBriefcaseAssetsAction = (params) => {
  return {
      type: types.PUBLIC_LOAD_BRIEFCASE_ASSETS,
      params
  }
};

export const loadBriefcaseAction = (params) => {
    return {
        type: types.PUBLIC_BRIEFCASE_LOAD,
        params
    }
};

export const addBriefcaseAction = (params) => {
  return {
      type: types.PUBLIC_BRIEFCASE_ADD,
      params
  }
};

export const removeBriefcaseAction = (params) => {
  return {
      type: types.PUBLIC_BRIEFCASE_REMOVE,
      params
  }
};


export const loadProfileAction = (params) => {
  return {
      type: types.PUBLIC_LOAD_PROFILE,
      params
  }
};

export const profileIndustryChangeAction = (params) => {
  return {
      type: types.PUBLIC_PROFILE_INDUSTRY_CHANGE,
      params
  }
};

export const updateTokenAction = (params) => ({
  type: types.PUBLIC_UPDATE_TOKEN,
  params
});

export const checkIsEditorAction = (params) => ({
    type: types.PUBLIC_CHECK_ISEDITOR,
    params
});

export const checkIsArchitectAction = (params) => ({
    type: types.PUBLIC_CHECK_ISARCHITECT,
    params
});

export const takeSnapshotAction = (params) => ({
    type: types.PUBLIC_TAKESNAPSHOT,
    params
});

export const importSnapshotAction = (params) => ({
    type: types.PUBLIC_IMPORTSNAPSHOT,
    params
});