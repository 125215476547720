import axios from "axios";
import React from "react";
import { ImageCacheInstance } from "../../utils/httpClient";

export class ImageCache extends React.Component {
  constructor(props) {
    super(props);

    this.cancelTokenSource = axios.CancelToken.source();

    this.state = {
      file: "",
    };
  }

  componentDidMount() {
    if (this.props.src) {
      ImageCacheInstance.get(this.props.src, {
        cancelToken: this.cancelTokenSource.token,
      }).then((response) => {
        if (response?.data) {
          this.setState({ file: response.data });
        }
      });
    }
  }

  render() {
      if (this.state.file) {
          const bytes = URL.createObjectURL(this.state.file);
          return <img {...this.props} src={bytes} alt="thumbnail" />;
    } else {
      return (
        <img
          {...this.props}
          src="/images/placeholder.png"
          alt="placeholder thumbnail"
        />
      );
    }
  }

  componentWillUnmount() {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel("ImageCache will unmount");
    }
  }
}
