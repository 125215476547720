
import React from 'react';
import ReactTooltip from 'react-tooltip';

const Tooltip = () => {


    return <ReactTooltip place="right" effect="float" className="tooltip" delayShow={1000} overridePosition={(pos, currentEvent, currentTarget, node, place, desiredPlace, effect, offset) => {
                if (place === "right")
                {
                var _node$getBoundingClie = node.getBoundingClientRect(),
                height = _node$getBoundingClie.height,
                width = _node$getBoundingClie.width;
                pos.top += parseInt(height/2, 10);
                }
                return pos
            }} />
}

export default Tooltip