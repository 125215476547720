import * as types from '../actions';
import produce from "immer";

const initialState = {
    message: {},
    search: {},
    suggestions: []
}

export default function (state = initialState, action) {

    function pad2(number) {
        return (number < 10 ? '0' : '') + number;
    }
    
    function createMessage(text, status) {
        var d = new Date();
        var date = `${d.getMonth()}/${d.getDate() + 1}/${d.getFullYear()} ${d.getHours()}:${pad2(d.getMinutes())}:${pad2(d.getSeconds())}`;
        return {
            text,
            status,
            date 
        }
    }
    
    var m;
    switch (action.type) {
        case types.PUBLIC_SEARCH_QUERY_SUCCESS:

            m = createMessage(action.message, "success");

            delete action.facets.count;
            
            return {
                ...produce(state, draftState => {
                    draftState.search.facets = action.facets;
                    draftState.search.highlighting = action.highlighting;
                    draftState.search.response = action.response;
                }),
                message: m
            };

        case types.PUBLIC_SEARCH_QUERY_ERROR:
            m = createMessage(action.message, "error")
            return {
                ...produce(state, draftState => {

                }),
                message: m
            };



        case types.PUBLIC_LOAD_PACKAGES_SUCCESS:
            m = createMessage(action.message, "success");

            // delete action.facets.count;
            // var r = /^v?(?<major>\d+)(?:\.(?<minor>\d+))?(?:\.(?<build>\d+))?/;
            // action.facets.product_version_facet.buckets = action.facets.product_version_facet.buckets.map( facet => {
                
            //     var matches = facet.val.match(r);
                
            //     var major = 0,
            //         minor = 0,
            //         build = 0;

            //     if (matches && matches.groups) {
            //         major = matches.groups.major || 0;
            //         minor = matches.groups.minor || 0;
            //         build = matches.groups.build || 0;
            //     }

            //     facet.version = {
            //         major: major,
            //         minor: minor,
            //         build: build,
            //         sort: [major, minor, build].map( v => v.toString().padStart(5, 0)).join(".")
            //     }

            //     //facet.val = "test";
            //     return facet;
            // }).sort((a, b) => b.version.sort.localeCompare(a.version.sort));

            var packages = action.response.docs;
            var groupBy = function(xs, key) {
                return xs.reduce(function(rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
                }, {});
            };
  
            return {
                ...produce(state, draftState => {
                    draftState.search.facets = action.facets;
                    draftState.search.response = {
                        ...action.response,
                        docs: groupBy(action.response.docs, "category")
                    };
                }),
                message: m
            };

        case types.PUBLIC_LOAD_PACKAGES_ERROR:
            m = createMessage(action.message, "error")
            return {
                ...produce(state, draftState => {

                }),
                message: m
            };



        case types.PUBLIC_LOAD_PACKAGE_ASSETS_SUCCESS:
            m = createMessage(action.message, "success");

            return {
                ...produce(state, draftState => {
                    draftState.search.facets = action.facets;
                    //draftState.search.highlighting = action.highlighting;
                    draftState.search.response = action.response
                }),
                message: m
            };

        case types.PUBLIC_LOAD_PACKAGE_ASSETS_ERROR:
            m = createMessage(action.message, "error")
            return {
                ...produce(state, draftState => {

                }),
                message: m
            };

        case types.PUBLIC_LOAD_ASSETS_SUCCESS:

            m = createMessage(action.message, "success");

            return {
                ...produce(state, draftState => {
                    //draftState.search.facets = action.facets;
                    draftState.search.facets = null;
                    //draftState.search.highlighting = action.highlighting;
                    draftState.search.response = action.response
                }),
                message: m
            };

        case types.PUBLIC_LOAD_ASSETS_ERROR:
            m = createMessage(action.message, "error")
            return {
                ...produce(state, draftState => {

                }),
                message: m
            };
        default:
            return state;
    }
};
