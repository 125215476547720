import React from 'react'
import { connect } from 'react-redux'
import qs from "qs"
import ReactPaginate from 'react-paginate'
import { Element, scroller } from 'react-scroll'
import '../Base.scss'
import './PackagesScreen.scss'
import { PackageCard } from '../../components/cards/PackageCard'
import { searchQueryService } from '../../services/searchService'
import { get } from '../../services/editorService'
import { AssetCard } from '../../components/cards/AssetCard'
import { InsertionForm } from '../../components/InsertionForm/InsertionForm'
import produce from "immer";
import { DragItem } from '../../components/dnd/DragItem'
import { DragTypes } from '../../components/dnd/dragTypes'
import * as PathHelper from "../../helpers/pathHelper"
import objectPath from "object-path"
import { FaPen, FaPlus, FaTimes, FaEye, FaEyeSlash, FaCamera } from 'react-icons/fa';
import * as layoutService from "../../services/layoutService";
import Modal from '../../components/modal/Modal'
import { MsalAuthenticationTemplate, withMsal } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { AuthErrorComponent } from '../../components/auth/Error'
import { AuthLoadingComponent } from '../../components/auth/Loading'
import { loadBriefcaseAction, removeBriefcaseAction } from '../../redux/actions/actions'
import Tooltip from '../../components/tooltip/Tooltip';
import * as assetFormConfig from "../../components/editorForm/assetsForm.config"
import * as packageFormConfig from  "../../components/editorForm/packagesForm.config"
import EditorForm from '../../components/editorForm/EditorForm'
import { FIELDS_MAPPING } from '../editor/Editor.config'
import { unformatRow } from '../editor/EditorTable.service'
import {save} from '../../services/editorService'
import { cloneDeep as _cloneDeep } from "lodash";

const 
    autoSubmitOn = true,
    defaults = {
        // limit: 30,
        // page: 0,
        sort: "category asc, content_type",
        order: "asc",
        filters: {},
        facetsOn: true,
    },
    querystringParamList = [
        "filters",
        // "limit",
        // "page",
        "explain"
    ],
    searchQueryParamList = [
        "filters",
        // "limit",
        // "page",
        "filters",
        "facetsOn",
        "sort",
        "order",
        "query",
        "explain"
    ],
    cloneDeep = (obj) => {
        return JSON.parse(JSON.stringify(obj));
    },
    confirm = window.confirm

function getIDs(container) {
    const {id, type, title, children} = container

    let ids = [];

    switch (type) {
        case DragTypes.Asset:
            //console.log('ITERATE', type, id)
            ids.push({id, type})
            break
        case DragTypes.Package:
            //console.log('ITERATE', type, id)
            ids.push({id, type})
            
            if (children?.map) {
                var cids = children.map(getIDs)

                if (cids?.map) {
                    cids.map(item => item.map( i => ids.push(i)))
                }
            }
            break
        case DragTypes.Section:
        default: 
            if (children?.map) {
                var cids = children.map(getIDs)

                if (cids?.map) {
                    cids.map(item => item.map( i => ids.push(i)))
                }
            }
            break
    }

    
    return ids
    
}

class Packages extends React.Component {
    constructor(props) {
        //console.log("PACKAGE", "CREATE")
        super(props);

        this.state = {
            qsValues: {},
            facetGroupVisibility: [],
            showFilters: true,
            contextualFilters: {},
            search: {},
            hierarchy: {},
            editMode: false,
            selectedFile: null,
            showImportSnapshot: false,
            feedback: {}
        };
        // this.pageRef = React.createRef();
        this.onSubmit = this.onSubmit.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.isFilterChecked = this.isFilterChecked.bind(this);
        this.listKey = "children";
    }

    componentWillUnmount = () => {
        this.confirmSave()
    }

    componentDidMount = async () => {
        //console.log("PACKAGE", "MOUNT")
        if (this.props.state.user.isAuthenticated) {
            this.getRecordsForLayout()
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const hasChanged = !(JSON.stringify(this.state.originalHierarchy) === JSON.stringify(this.state.hierarchy))

        if (this.props.id !== prevProps.id) {
            this.getRecordsForLayout()
        } else if (this.state.hasChanged !== hasChanged) {
            this.setState({hasChanged}, () => {
                if (!prevProps.state.user.isAuthenticated && this.props.state.user.isAuthenticated) {
                    this.getRecordsForLayout()
                }
            })
        } else if (!prevProps.state.user.isAuthenticated && this.props.state.user.isAuthenticated) {
            this.getRecordsForLayout()
        }
    }

    getRecordsForLayout = async() => {
        try {
            const res = await layoutService.getPackage({params: { id: this.props.id, childInfo: true}})
            const masterData = this.fixSections(res.data.data)

            this.setState({ hierarchy: masterData, originalHierarchy: masterData }, () => {
                let qs = this.getQuerystringObject();

                this.executeQuery({
                    ...this.state.qsValues,
                    ...qs,
                    hasChanged: false
                });

                try {
                    var facets = this.props.state.config.ui.facets;
                    var keys = Object.keys(facets);
                    keys.map(key => {
                        var facet = facets[key];

                        //if (facet.expanded) {
                        if (this.isFacetGroupVisible(facet.name)) {
                            this.toggleFacetGroupVisibility(facet.name)
                        }
                        //}
                    });

                } catch (error) {

                }
            });   
        }  catch (error) {
            console.error(error)
        }
    }
    
    fixSections = container => {
        let {id, type, title, children} = container
        
        switch (type) {
            case DragTypes.Asset:
                break
            case DragTypes.Package:
            case DragTypes.Section:
                if (children?.map) {
                    container.children = children.map(this.fixSections)
                } else {
                    container.children = []
                }
                break
            default: 
                container.type = DragTypes.Section
                if (children?.map) {
                    container.children = children.map(this.fixSections)
                } else {
                    container.children = []
                }
                break
        }

        return container
    }

    savePackage = async () => {
        if (this.state.hasChanged) {
           const res = await layoutService.savePackage({params: this.state.hierarchy})
           if (res.status) {
               this.setState({ originalHierarchy: this.state.hierarchy, hasChanged: false })
           }
        }
    }

    cancelChanges = async () => {
        this.setState({ hierarchy: this.state.originalHierarchy, hasChanged: false })       
    }

    executeQuery = async (params) => {
        let ids = getIDs(this.state.hierarchy)
        let uniqueIds = [
            ...new Map(
                ids.map(item => [item.id+item.type, item])
            ).values()
        ];

        var searchParams = cloneDeep({
            ...defaults,
            ...params
        });


        if (uniqueIds?.length > 0) {
            const q = uniqueIds.map( item => `(sharepoint_id:${item.id} AND type:${item.type})`).join(" OR ")
            if (q) {
                searchParams.query = q
            }
    
            //console.log("PACKAGE", "EXECUTE_QUERY")
            
            var qsParams = cloneDeep(searchParams);
    
            Object.keys(qsParams).map(key => {
                if (querystringParamList.indexOf(key) === -1) {
                    delete qsParams[key];
                }
            });
    
            // if (JSON.stringify(this.state.qsValues) === JSON.stringify(qsParams)) {
            //     return 
            // }
    
            var s = qs.stringify(qsParams, {
                allowDots: true,
                indices: false,
                encode:true,
                encodeValuesOnly: true
            });
    
            if (window.location.search.length > 1) {
                this.props.history && this.props.history.push({
                    search: "?" + s
                });
            } else {
                this.props.history && this.props.history.replace({
                    search: "?" + s
                });
            }
    
            Object.keys(searchParams).map(key => {
                if (searchQueryParamList.indexOf(key) === -1) {
                    delete searchParams[key];
                }
            });
    
            try {
                //console.log("query")
                let res = await searchQueryService({
                    params: {
                        ...searchParams,
                        facetFilter: "parent",
                        parents: false,
                        children: false,
                        preview: this.state.previewMode,
                        limit: 10000
                    } 
                });
        
                //res.data.data.response.docs = this.groupBy(res.data.data.response.docs, "category")
        
                this.setState({ 
                    qsValues: qsParams,
                    search: res.data.data
                });
    
            }  catch (error) {
                console.error(error)

                if (error.response?.status === 401 && this.props.login) {
                    this.props.login()
                }
            }
        } else {
            var qsParams = cloneDeep(searchParams);
            this.setState({ 
                qsValues: qsParams,
                search: {
                    response: {
                        docs: [],
                        numFound: 0,
                        start: 0
                    }
                }
            });
        }
    }

    getQuerystringObject = function () {
        var retVal = window.location.search || "";

        if (retVal.substring(0, 1) === "?") {
            retVal = retVal.substring(1);
        }

        var qsParams = qs.parse(retVal, {
            plainObjects: true,
            allowDots: true
        });

        qsParams = {
            ...defaults,
            ...qsParams
        }

        Object.keys(qsParams).map(key => {
            if (querystringParamList.indexOf(key) === -1) {
                delete qsParams[key];
            }
        });

        let facetKeys = qsParams.filters ? Object.keys(qsParams.filters) : [];
        facetKeys.map( facetKey => {
            let facetGroup = qsParams.filters[facetKey];
            if (typeof facetGroup === "string") {
                facetGroup = [facetGroup];
            }
            qsParams.filters[facetKey] = facetGroup
        })

        return qsParams;
    }

    submitForm = () => {
        this.submitButtonRef.click();
    }

    isFilterChecked = (name, value) => {
        return (typeof this.state.qsValues.filters === "object" && this.state.qsValues.filters[name] && this.state.qsValues.filters[name].indexOf(value.toString()) > -1);
    }

    onSubmit(event) {
        event.preventDefault();

        var params = cloneDeep(defaults);

        for (var el of event.target) {

            if (el.name === "q") {
                params.query = el.value;
            } else if (el.name === "limit") {
                params.limit = parseInt(el.value, 10);
            } else if (el.name === "page") {
                params.page = parseInt(el.value, 10);
            } else if (el.name === "sort") {
                params.sort = el.value;
            } else if (el.name === "order") {
                if (el.checked) {
                    params.order = el.value;
                }
            } else if (el.name === "mode") {
                if (el.checked) {
                    params.mode = el.value;
                }
            } else if (el.name.indexOf("filter.") === 0) {
                var name = el.name.substring(7);
                if (!params.filters[name]) {
                    params.filters[name] = [];
                }
                switch (el.type) {
                    case "radio":
                    case "checkbox":
                        if (el.checked) {
                            params.filters[name].push(el.value);
                        }
                        break;
                    case "submit":
                        break;
                    default:
                        params.filters[name].push(el.value);
                        break;
                }
            }
        }

        this.executeQuery(params);
    }

    toggleFacetGroupVisibility = facetGroup => {
        var i = this.state.facetGroupVisibility.indexOf(facetGroup)
        if (i > -1) {
            this.state.facetGroupVisibility.splice(i, 1);
        } else {
            this.state.facetGroupVisibility.push(facetGroup);
        }

        this.setState({ facetGroupVisibility: this.state.facetGroupVisibility });
    }

    isFacetGroupVisible = facetGroup => {
        return this.state.facetGroupVisibility.indexOf(facetGroup) > -1;
    }

    getFacetValueUi = (buckets, facetFieldName, facetFieldLabel) => {
        var values = buckets.filter(facet => {
            return (facet && facet.val && (!this.state.contextualFilters[facetFieldName] || facet.val.toLowerCase().indexOf(this.state.contextualFilters[facetFieldName]) > -1))
        });

        if (values.length > 0) {
            return values.map(facet => {
                if (facet && facet.val && facet.val !== null && facet.val !== "" && facet.count !== "0" && facet.count !== 0 && facet.val.indexOf) {
                    var label = facet.val.substring(facet.val.indexOf('-') + 1).trim();
                    return (
                        <div key={facet.val} className="facet__item facet__item--checkBoxes" >
                            <input id={"filter." + facetFieldName + "--" + facet.val} name={"filter." + facetFieldName} value={facet.val} type="checkbox" checked={this.isFilterChecked(facetFieldName, facet.val)} onChange={this.onFormChange} />
                            <label htmlFor={"filter." + facetFieldName + "--" + facet.val}>{label} ({facet.count})</label>
                        </div>
                    )
                }
            })
        } else {
            return <div className="facet__item--no-results">No matches found in {facetFieldLabel}</div>
        }
            
    }

    onFormChange = function () {
        // this.pageRef.value = 0;
        this.submitForm();
    }

    getFacets = (searchData, searchConfig) => {

        if (searchData?.facets && searchConfig?.ui?.facets) {

            var facetKeys = Object.keys(searchConfig.ui.facets);

            return facetKeys.filter( facetFieldName => facetFieldName !== "theme").map(facetFieldName => {

                let facetUI = searchConfig.ui.facets[facetFieldName],
                    facetData = searchData.facets[facetFieldName],
                    show = true,
                    fields = Object.keys(facetUI.filters);

                if (fields && fields.length) {
                    show = false;

                    fields.map( fieldName => {
                        if (!show) {
                            try {
                                let selectedValues = this.state.qsValues.filters[fieldName],
                                    neededValues = facetUI.filters[fieldName],
                                    intersection = neededValues.filter(x => selectedValues.includes(x));
    
                                ////console.log(selectedValues, neededValues, intersection);
                                show = !!(intersection.length > 0);
                            } catch (error) {
                                
                            }
                        }
                    });
                }

                if (show && facetUI && facetData?.buckets?.length) {
                    
                    return (
                        <div className="facets__container facets__slide" key={facetFieldName}>
                            <div data-tip={searchConfig.tooltips[facetFieldName]} className="facets__wrapper" onMouseLeave={() => {
                            if (this.isFacetGroupVisible(facetFieldName)) {
                                this.toggleFacetGroupVisibility(facetFieldName)
                            }
                        }}>
                                <div className={"accordion facets__category" + (this.isFacetGroupVisible(facetFieldName) ? " active" : "")} category={facetFieldName} onClick={() => this.toggleFacetGroupVisibility(facetFieldName)}>
                                    {facetUI.label}
                                    <div className="icon-box">
                                        <svg className="svg-icon-size svg-icons_angle-down-dims">
                                            <use xlinkHref="#icons_angle-down" />
                                        </svg>
                                    </div>
                                </div>

                                {this.isFacetGroupVisible(facetFieldName) && facetData.buckets.length >= 10 &&
                                    <div className={"facets__contextualFilter facets__contextualFilter--" + facetFieldName}>
                                        <input placeholder={"Search " + facetUI.label} type="search" onChange={event => this.onContextualFilterChange(facetFieldName, event)} />
                                    </div>
                                   
                                }
                                
                                <div className={"facets__items facets__items--checkBoxes panel" + (" facets__items--" + facetFieldName) + (this.isFacetGroupVisible(facetFieldName) ? " active" : "")} category={facetFieldName}>
                                    {this.getFacetValueUi(facetData.buckets, facetFieldName, facetUI.label)}
                                </div>

                            </div>
                        </div>
                    );
                }
            });
        }
    }

    onPageClick = data => {
        this.setState({
            qsValues: {
                ...this.state.qsValues,
                page: data.selected
            }
        }, () => {
            this.executeQuery(this.state.qsValues);
            var speed = this.state.qsValues.limit * 2.5;
            scroller.scrollTo('search__form', {
                duration: speed,
                delay: 100,
                smooth: true,
                offset: -25, // Scrolls to element + 50 pixels down the page
            });
        });
    }

    onLimitChange = event => {
        // this.pageRef.value = 0;
        
        this.setState({
            qsValues: {
                ...this.state.qsValues,
                limit: event.target.value
            }
        },
            //this.submitForm
        );
    }

    getActiveFacetValues = () => {
        var values = [];
        let facetKeys = this.state.qsValues.filters ? Object.keys(this.state.qsValues.filters) : [];
        facetKeys.map( facetKey => {
            let facetGroup = this.state.qsValues.filters[facetKey];
            if (typeof facetGroup === "string") {
                facetGroup = [facetGroup];
            }
            facetGroup.map( facetValue => {
                var val = facetValue;

                values.push({
                    name: facetKey,
                    value: val
                })
            })
        })

        return values;
    }

    removeFacetValue = facetObj => {
        var values = {};
        if (typeof facetObj === "string" && facetObj === "*") {
            //keep as empty object;
        } else {
            values = cloneDeep(this.state.qsValues.filters);
            var i = values[facetObj.name].indexOf(facetObj.value);
            if (i > -1) {
                values[facetObj.name].splice(i, 1);
            }
        }

        this.executeQuery({
            ...this.state.qsValues,
            filters: values
        });
    }
    
    groupBy(xs, key) {
        return xs.reduce(function(rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    /**
     * 
     * @param {array} path Path to array
     * @param {object} obj object to add to end of array
     */
    add = (path, obj, cb) => {
        this.setState(produce(this.state, draftState => {
            objectPath.push(draftState.hierarchy, PathHelper.o2s(path), obj)
        }), () => {         
            this.executeQuery({
                ...this.state.qsValues
            });
            if (cb) {
                cb()
            }
        })
    }

    /**
     * 
     * @param {array} path 
     */
    remove = (path) => {
        this.setState(produce(this.state, draftState => {
            objectPath.del(draftState.hierarchy, PathHelper.o2s(path))
        }))
    }

    get = (path) => objectPath.get(this.state.hierarchy, PathHelper.o2s(path))
    has = (path) => objectPath.has(this.state.hierarchy, PathHelper.o2s(path))

    /**
     * 
     * @param {array} path path to index
     * @param {object} obj object to insert where path
     */
    insert = (path, index, obj) => {
        this.setState(produce(this.state, draftState => {
            objectPath.insert(draftState.hierarchy, PathHelper.o2s(path), obj, parseInt(index))
        }), () => {
            this.executeQuery({
                ...this.state.qsValues
            });
        })
    }

    /**
     * 
     * @param {array} fromPath path to index of source object
     * @param {array} toPath path to destination
     * @param {boolean} append true: toPath is an array and object will be added to the end. false: toPath is an index and should be inserted.
     */
    move = (fromPath, toPath, append = false) => {
        this.setState(produce(this.state, draftState => {
            const obj = this.get(fromPath);
            if (obj) {
                const to = this.getListPathAndIndex(toPath)

                objectPath.del(draftState.hierarchy, PathHelper.o2s(fromPath))

                if (append) {
                    objectPath.push(draftState.hierarchy, PathHelper.o2s(toPath), obj)
                } else {
                    objectPath.insert(draftState.hierarchy, PathHelper.o2s(to.path), obj, to.index)
                }
            }
        }))

    }

    addSection = (title) => {
        if (title) {
            const section = {
                title,
                type: DragTypes.Section,
                children: []
            }
            this.add([this.listKey], section, () => this.setState({sectionTitle:""}))
        }
    }

    /**
     * pass in child path to get the parent list path
     * @param {array} path  child path
     * @returns {array} parent path
     */
    getListPathAndIndex = (path) => {
        const p = [...path]
        const index = p.pop()
        return {
            index: parseInt(index),
            path: p
        } 
    }

    /**
     * pass in child path to get the parent list path
     * @param {array} path  card path
     * @returns {array} parent path
     */
    getParentPath = (path) => {
        path.length = path.lastIndexOf("children")
        return path
    }

    moveCardHandler = (
        dragItemContext,
        _dragpath,  
        _droppath
    ) => {
        let dragpath = [..._dragpath]
        let droppath = [..._droppath]

        //if (dragpath.length === droppath.length && JSON.stringify(dragpath) === JSON.stringify(droppath)) return
        if (this.has(dragpath)) {
            switch(dragItemContext.type) {
                case DragTypes.Asset:
                case DragTypes.Package: 

                    //what did we drop it on?
                    var d = this.get(droppath);

                    if (d.type === DragTypes.Section) {
                        // if we dropped onto a section then we cant just move, 
                        // we need to add the drag item to the drop targets children array
                        droppath.push(this.listKey)
                        droppath.push(d.length)
                        this.move(dragpath, droppath)
                    } else {
                        // dragitem and drop target are asset/package so we can just move
                        this.move(dragpath, droppath)
                    }
                    break;
                
                case DragTypes.Section:
                default:  
                    // user dragged a section onto this card. are we to assume they are targeting this cards parent section?
                    droppath = this.getParentPath(droppath)
                    this.move(dragpath, droppath)
                    break;
                
            }
            return droppath
        }
    }

    moveSectionHandler = (
        dragItemContext,
        _dragpath,  
        _droppath
    ) => {        
        
        const dragpath = [..._dragpath]
        const droppath = [..._droppath]
        
        this.move(dragpath, droppath)
        return droppath
    }

    getCardContainer = () => {
        if (this.state.editMode && this.props.state.user.isArchitect) {
            return (props) => 
                <DragItem 
                    accept={[DragTypes.Asset, DragTypes.Package]} 
                    moveHandler={this.moveCardHandler} 
                    placeholder={true}
                    {...props} 
                />
        } else {
            return (props) => <li {...props}/>
        }
    }

    getSectionContainer = () => {
        if (this.state.editMode && this.props.state.user.isArchitect) {
            return (props) => 
                <DragItem 
                    accept={[DragTypes.Section, DragTypes.Package, DragTypes.Asset]} 
                    moveHandler={this.moveSectionHandler} 
                    {...props} 
                />
        } else {
            return (props) => <li {...props}/>
        }
    }

    edit = (path, data) => {
        const orig = objectPath.get(this.state.hierarchy, PathHelper.o2s(path))
        this.setState(produce(this.state, draftState => {
            let record

            if (typeof orig === "object") {
                if (Array.isArray(data))
                    record = [...orig, ...data]
                else {
                    record = {...orig, ...data}
                }
            } else {
                record = data
            }

            objectPath.set(draftState.hierarchy, PathHelper.o2s(path), record)
            
    }))}
    
    confirmSave = (e) => {
        if (this.state.editMode && this.props.state.user.isArchitect && this.state.hasChanged) {            
            if (confirm('You are leaving behind changes. Would you like them to be saved?')) {
                this.savePackage()
            }           
        }
    }

    toggleEditMode = (e) => {
        if (!e.target.checked && this.state.hasChanged && !this.state.previewMode) {
            this.confirmSave()
        }
        this.setState({ editMode: e.target.checked })
    }

    togglePreviewMode = (e) => {
        var toggle = true
        
        if (!this.state.previewMode && this.state.editMode && this.state.hasChanged) {
            toggle = confirm("You have changes that will be lost by entering preview mode.")
        }

        if (toggle) {
            this.setState({
                hierarchy: this.state.originalHierarchy,
                hasChanged: false,
                previewMode: !this.state.previewMode 
            }, () => {
                this.executeQuery({
                    ...this.state.qsValues
                });
            })
        }
    }

    takeSnapshot = async (e) => { 
        try {
            let res = await layoutService.createSnapshot()
            const snapshot = res.data.data
            this.setState({snapshot})
        } catch (error) {
            if (error.response?.status === 401 && this.props.progress === "none") {
                await this.props.acquireToken()
            } else {
                console.error(error)
            }
        }

        //this.props.dispatch(takeSnapshotAction())
        //setTimeout(this.setState({ snapshotModal: true }), 0)
    }

    closeSnapshot = (e) => {
        this.setState({ snapshot: null })       
    }    

    // On file select (from the pop up)
    onFileChange = event => {

        // Update the state
        this.setState({ selectedFile: event.target.files[0] });

    };

    // On file upload (click the upload button)
    onFileUpload = () => {

        // Create an object of formData
        const formData = new FormData();
        

        // Update the formData object
        formData.append(
            "file",
            this.state.selectedFile,
            this.state.selectedFile.name
        );

        // Details of the uploaded file
        //console.log(this.state.selectedFile);

        // Request made to the backend api
        // Send formData object
        layoutService.uploadSnapshot({ formData }).then(response => {
            if (response.status === 200) {
                this.setState({ feedback: response.data.data })
            }
        })
                     
    };

    closeImportSnapshot = (e) => {
        this.setState({ showImportSnapshot: false })
    }

    showImportSnapshot = () => {
        this.setState({ showImportSnapshot: true, feedback: {} })
    }

    parseManagedMetadata = (fieldObj) => {
        const id = fieldObj.TermSetId;
        const metadata = this.props.state.config.listMetadata[id];
        return Object.entries(metadata.terms).map((term) => {
          return {
            value: term[0],
            label: term[1],
          };
        });
      }
  
    parseChoices =(fieldObj) => {
        return fieldObj.Choices.map((choice) => {
            return {
            value: choice,
            label: choice,
            };
        });
    }
  
    parseChoiceObjects = (fieldObj) => {
        const options = fieldObj.Choices.map((choice) => {
            return {
                value: choice.Id,
                label: choice.Name,
            };
        });

        return options.sort((a, b) => a.label.localeCompare(b.label));
    }

    getOptions = () => {
        if (this.options) {
            return this.options
        }

        const options = {};
        this.props.state.config.listFields["SRC Package Content"].forEach((fieldObj) => {
          switch (fieldObj.FieldTypeKind) {
            case 0: // Managed Metadata
              options[FIELDS_MAPPING?.[fieldObj.Title]] =
              this.parseManagedMetadata(fieldObj);
              break;
            case 6: // Single choice
            case 15: // Multi choice
              options[FIELDS_MAPPING?.[fieldObj.Title]] =
                this.parseChoices(fieldObj);
              break;
            case 25: // special content type choice
              options[FIELDS_MAPPING?.[fieldObj.Title]] =
                this.parseChoiceObjects(fieldObj);
              break;
            default:
              break;
          }
        });
  
        options.productVersion && options.productVersion.reverse(); // Special sorting on productVersions to have the latest version be the top option
        this.options = options
        return options;
    }

    render() {
        if (this.state.search.response) {
            let searchConfig = this.props.state.config, 
                searchData = this.state.search,
                facetValues = this.getActiveFacetValues(),
                facetUI = searchConfig?.ui?.facets || {}  ,
                editAsset = this.state.editAsset

            return (                 
                <MsalAuthenticationTemplate 
                    interactionType={InteractionType.Popup} 
                    authenticationRequest={this.props.state.config.auth.loginRequest} 
                    errorComponent={AuthErrorComponent} 
                    loadingComponent={AuthLoadingComponent}>
                        
                    <div className={"c-search screen screen--search" + (this.state.showFilters ? " c-search__facets--active" : "") + (this.state.editMode ? " screen--editMode" : "")}>

                        <Element name="search__form"></Element>
                        
                        {this.state.snapshot?.packages &&
                            <Modal title={
                                <>
                                    <h3>Snapshot taker</h3>
                                    <button onClick={() => {
                                        navigator.clipboard.writeText(JSON.stringify(this.state.snapshot.packages, 0, 2))
                                        alert("Copied")
                                        }}>Copy
                                    </button>
                                    <a href="/api/downloadSnapshot" target="_blank">Save
                                    </a>
                                </>}
                                onClose={this.closeSnapshot}
                                scrollable={true}>
                                <pre> {JSON.stringify(this.state.snapshot.packages, 0, 2)}
                                </pre>                        
                            </Modal>
                        }

                        {this.state.showImportSnapshot &&
                            <Modal scrollable={true} title={
                                <>
                                    <h3>Import Snapshot</h3>
                                    { this.state.feedback?.success &&
                                    <h5>Success: {this.state.feedback.success}</h5>
                                    }
                                    {this.state.feedback?.failed &&
                                    <h5>Errors: {this.state.feedback.failed.length}</h5>
                                    }
                                    {this.state.feedback?.deleted_success &&
                                        <h5>Deleted Success: {this.state.feedback.deleted_success}</h5>
                                    }
                                    {this.state.feedback?.deleted_failed &&
                                    <h5>Deleted Errors: {this.state.feedback.deleted_failed.length}</h5>
                                    }
                                    <input type="file" onChange={this.onFileChange} />
                                    <button onClick={
                                        this.onFileUpload
                                    }>Import
                                    </button>
                                    <textarea rows="25" placeholder="Paste Snapshot Here"></textarea>
                                </>}
                                onClose={this.closeImportSnapshot}
                            >
                            </Modal>
                        }
                    
                        <section className="c-section c-section__screen-title">
                            {!(this.state.editMode && this.props.state.user.isArchitect) &&
                                <h2>{this.state.hierarchy.title}</h2>
                            }
                            {this.state.editMode && this.props.state.user.isArchitect &&
                                <h2>
                                    <input key="section-edit-title" placeholder="Add A Title" defaultValue={this.state.hierarchy?.title} onBlur={(e) => this.edit(["title"], e.target.value)} />
                                </h2>
                            }

                            {!(this.state.editMode && this.props.state.user.isArchitect) && this.state.hierarchy.description && 
                                <p className="c-section__description">{this.state.hierarchy.description.replace("~~","'")}</p>
                            }

                            {this.state.editMode && this.props.state.user.isArchitect &&
                                <p className="c-section__description">
                                    <textarea key="section-description" placeholder="Add A description" defaultValue={this.state.hierarchy?.description?.replace("~~","'")} onBlur={(e) => this.edit(["description"], e.target.value)} />
                                </p>
                            }
                        </section>

                        <form id="c-search__form" className="l form" method="get" action="" onSubmit={this.onSubmit}>                   
                            {/* <input type="hidden" name="page" defaultValue={this.state.qsValues.page} ref={input => this.pageRef = input}/> */}
                            <input type="hidden" name="order" defaultValue={this.state.qsValues.order} />      
                            <input type="submit" value="Search" className="c-search__input-submit c-btn c-btn--primary" style={autoSubmitOn ? { display: "none" } : {}} ref={button => this.submitButtonRef = button} />

                            <div className="l-main">
                                <div className="top-bar">

                                    <div className={"c-search__facets c-search__facets--horizontal" + (this.state.showFilters ? " c-search__facets--active" : "")}>
                                        {this.state.showFilters}
                                        <div className="c-search__facets-form" >
                                            <label data-title="By selecting options in these three categories, you will be able to see all relevant content that is available on this page related to your selections." className="c-pagefilter">Page Filters</label>
                                            {this.getFacets(searchData, searchConfig)}
                                        </div>
                                    </div>  

                                    <div className="c-search__actions">
                                        {facetValues.length > 0 &&
                                            <a className="c-btn c-btn--primary c-search__actions--clear" onClick={() => this.removeFacetValue("*")}>Clear All</a>
                                        }
                                        <a className="c-btn c-btn--primary c-search__actions--share" href={"mailto:?subject=" + encodeURIComponent("Check out my AspenTech search results") + "&body=" + encodeURIComponent(window.location.href + "&shared=true")}>Share</a>
                                        
                                        {this.state.editMode && this.props.state.user.isArchitect && <button title="Save Changes" className="c-btn c-btn--primary c-search__actions--save" disabled={!this.state.hasChanged} onClick={this.savePackage}>Save</button>}
                                        {this.state.editMode && this.props.state.user.isArchitect && <button title="Revert Changes" className="c-btn c-btn--primary c-search__actions--cancel" disabled={!this.state.hasChanged} onClick={this.cancelChanges}>Revert Changes</button>}
                                        {this.state.editMode && this.props.state.user.isArchitect && <button title="Snapshot" className="c-btn c-btn--primary c-search__actions--snapshot" onClick={this.takeSnapshot}><FaCamera /></button>}
                                        {this.state.editMode && this.props.state.user.isArchitect && <button title="Import Snapshot" className="c-btn c-btn--primary c-search__actions--importsnapshot" onClick={this.showImportSnapshot}>Import</button>}
                                        {(this.props.state.user.isEditor || this.props.state.user.isArchitect) && <button className="c-btn c-btn--primary c-search__actions--preview" onClick={this.togglePreviewMode}>View {this.state.previewMode ? "public" : "staged"} data</button>}
                                        {(this.props.state.user.isEditor || this.props.state.user.isArchitect) && <label title={`Turn ${this.state.editMode ? "off" : "on"} edit mode`} className={"c-icon " + (this.state.editMode ? "c-icon--active" : "")}><FaPen /><input type="checkbox" onChange={this.toggleEditMode} /></label>}
                                    </div>

                                </div>

                                {facetValues.length > 0 && 
                                    <div className="c-search__filters c-search__filters--inline ">
                                        <div className="c-search__active-facets c-tab__list">
                                            {facetValues.map( facetObj => (
                                                <a key={ facetObj.name + "_" + facetObj.value} 
                                                    className="c-tab c-tab__filter" 
                                                    onClick={() => this.removeFacetValue(facetObj)}
                                                >
                                                    {facetUI[facetObj.name] ? facetUI[facetObj.name].label + ": " : ""}{facetObj.value}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                }

                            </div>
                        </form>

                        <div className="c-search__results">
                            {this.state.editMode && this.props.state.user.isArchitect && 
                                <div className="c-section addSection">
                                    <div className="c-section__form">
                                        <div className="c-section__actions">
                                            <label className="c-icon" onClick={() => this.addSection(this.state.sectionTitle)}><FaPlus/></label>
                                        </div>
                                        <h3 className="c-section__sub-title">
                                            <input placeholder="Add A Section" value={this.state.sectionTitle} onChange={(e) => this.setState({ sectionTitle: e.target.value })} />
                                        </h3>
                                    </div>
                                </div>
                            }
                            
                            {this.state.hierarchy.children?.map((section, index) => {
                                if ((!section.children || section.children.length === 0) && !this.state.editMode) {
                                    return null
                                }      
                                                                    
                                const Section = this.getSectionContainer();
                                const sectionPath = [this.listKey, index];
                                const published = (section.published !== false)
                                
                                return (
                                    <div key={`section-wrapper-${sectionPath}`}>
                                        {this.state.editMode && this.props.state.user.isArchitect && 
                                            <div className="c-section__actions">
                                                <label className={"c-icon " + (published ? "c-icon--active": "")}>
                                                    {published ? <FaEye /> : <FaEyeSlash />}
                                                    <input type="checkbox" defaultChecked={published} onChange={e =>{
                                                        this.edit(sectionPath, { published: e.target.checked })} 
                                                    } />
                                                </label>

                                                <label className="c-icon c-section__remove c-icon--alert" onClick={() => confirm(`Are you sure you want to delete this section?`) && this.remove([this.listKey, index])}><FaTimes/></label>
                                                <InsertionForm
                                                    previewMode={this.state.previewMode}
                                                    title={
                                                        <>
                                                            <h3>Assets &amp; Packages</h3>
                                                            <h5>Adding content to the <em>{section.title}</em> section</h5>
                                                        </>
                                                    }
                                                    addToList={(id) => this.add([this.listKey, index, this.listKey], id)}
                                                />
                                            </div>
                                        }

                                        {(this.state.editMode || published) && // BOTH ROLES SHOULD SEE THIS
                                            <Section path={sectionPath} className="c-section" key={`section-${sectionPath}`} type={DragTypes.Section}>
                                                {section.title && !(this.state.editMode && this.props.state.user.isArchitect) &&
                                                    <h3 className="c-section__sub-title">{section.title}</h3>
                                                }

                                                {this.state.editMode && this.props.state.user.isArchitect &&
                                                    <h3 className="c-section__sub-title">
                                                        <input key="section-edit-title" placeholder="Add A Title" defaultValue={section.title} onBlur={(e) => this.edit([...sectionPath, "title"], e.target.value)} />
                                                    </h3>
                                                }
                                            
                                                <ul className="c-card-list g flex-grid-5">
                                                    {this.state.editMode && this.props.state.user.isArchitect && (!section.children || section.children?.length === 0) &&
                                                        <DragItem type={DragTypes.Placeholder} path={[this.listKey, index, this.listKey, 0]} className="c-section__droptarget" accept={[DragTypes.Asset, DragTypes.Package]} moveHandler={this.moveCardHandler} drag={false}>Drop target here</DragItem>
                                                    }

                                                    {section?.children?.map((item, itemIndex) => {

                                                        const ListItem = this.getCardContainer()

                                                        const doc = searchData.response.docs.find(doc => {
                                                            return doc.sharepoint_id === item.id && doc.type === item.type
                                                        })

                                                        if (!doc) {
                                                            if (this.state.editMode && this.props.state.user.isArchitect) {
                                                                return (
                                                                    <li key={"section-" + index + "_child-" + itemIndex} className="c-card-list__item fi">
                                                                        <label className="c-icon c-icon--alert" onClick={() => confirm(`Are you sure you want to delete this missing item? `) && this.remove([this.listKey, index, this.listKey, itemIndex])}><FaTimes/></label>
                                                                        Missing {item.type} {item.id}
                                                                    </li>
                                                                )
                                                            } else {
                                                                return null
                                                            }
                                                                                
                                                        } else {
                                                            return (
                                                                <ListItem data-tip={doc.description} key={doc.id} className="c-card-list__item fi" path={[this.listKey, index, this.listKey, itemIndex]} name={doc.modified} type={doc.type}>
                                                                    {this.state.editMode && this.props.state.user.isArchitect && <label className="c-icon c-card-list__remove c-icon--alert" onClick={() => confirm(`Are you sure you want to delete this ${doc.type}? `) && this.remove([this.listKey, index, this.listKey, itemIndex])}><FaTimes/></label>}
                                                                    {doc.type === "package" &&
                                                                        <PackageCard editMode={this.state.editMode} data={doc} location={this.props.location} childInfo={this.state.hierarchy?.childInfo[doc.sharepoint_id]} />
                                                                    }
                                                                    {doc.type === "asset" &&
                                                                        <AssetCard 
                                                                            editMode={this.state.editMode} 
                                                                            onEdit={(record) => {
                                                                                if (this.state.editMode) {
                                                                                    get({id: record.sharepoint_id}, `${record.type}s`, false, this.props.claimsRequest).then(
                                                                                        (response) => {
                                                                                            var record = unformatRow(response.data.data)
                                                                                            record.url = `/images/cache/${
                                                                                                record.imageName
                                                                                                }.png?url=${encodeURIComponent(record.url)}`
                                                                                            this.setState({editAsset: { ...record, type: doc.type, sharepoint_id: doc.sharepoint_id }})
                                                                                        },
                                                                                        (ex) => {
                                                                                            this.setState({editAsset: null})
                                                                                        }
                                                                                    )
                                                                                }
                                                                            }}
                                                                            data={doc} 
                                                                            location={this.props.location} 
                                                                            onAddedToBriefcase={ params => {
                                                                                this.props.dispatch(loadBriefcaseAction(params))
                                                                            }} 
                                                                            onRemoveFromBriefcase={params => {
                                                                                this.props.dispatch(removeBriefcaseAction(params))
                                                                            }}
                                                                            inBriefcase={(this.props.state.briefcase.briefcase?.indexOf(doc.sharepoint_id) > -1)}
                                                                        />
                                                                    }
                                                                </ListItem>
                                                            )
                                                        }
                                                    })}
                                                </ul>
                                                <Tooltip />
                                            </Section>
                                        }
                                    </div>
                                )}                           
                            )}

                        </div>

                        {editAsset && 
                            <Modal
                                title={`Edit ${editAsset.type} ${editAsset.sharepoint_id || ""}`}
                                className="edit-modal"
                                onClose={() => {
                                    this.setState({editAsset: null})
                                }}
                                fixedTitle
                            >
                                <EditorForm
                                    config={editAsset?.type === "asset" ? assetFormConfig : packageFormConfig}
                                    editMode="edit-existing"
                                    defaultFormData={editAsset}
                                    fieldOptions={this.getOptions()}
                                    onSuccess={ formData => {
                                        const newRow = _cloneDeep(formData);
                                                                
                                        const multiSelectKeys = Object.keys(newRow).filter((key) =>
                                            Array.isArray(newRow[key])
                                        );
                                        multiSelectKeys.forEach((key) => {
                                            const newValues = [];
                                            const values = newRow[key];
                                            const options = this.getOptions()[key];
                                            values.forEach((value) => {
                                            const option = options.find((option) => option.value === value);
                                            const newOption = _cloneDeep(option);
                                            newOption.id = newOption.value;
                                            newOption.value = newOption.label;
                                            delete newOption.label;
                                            newValues.push(newOption);
                                            });
                                            newRow[key] = newValues;
                                        });

                                        save(
                                            newRow,
                                            "assets",
                                            false,
                                            !!formData.fileName,
                                            this.props.claimsRequest
                                        ).then( response => {
                                            //console.log(response)
                                            this.setState({ editAsset: null })
                                        });
                                    }}
                                />
                            </Modal>
                        }
                                                
                        {/* {searchData?.response?.numFound > this.state.qsValues.limit &&
                            <ReactPaginate
                                forcePage={parseInt(this.state.qsValues.page, 10)}
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                pageCount={Math.ceil(searchData.response.numFound / this.state.qsValues.limit)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={8}
                                onPageChange={this.onPageClick}

                                containerClassName={'c-search__pagination'}

                                breakClassName={'break'}
                                breakLinkClassName={'break__link'}

                                pageClassName={'page'}
                                pageLinkClassName={'page__link'}

                                activeClassName={'page--active '}
                                activeLinkClassName={'page__link--active'}

                                previousClassName={'previous'}
                                previousLinkClassName={'previous__link'}

                                nextClassName={'next'}
                                nextLinkClassName={'next__link'}

                                disabledClassName={'disabled'}
                            />
                        } */}
                    </div>
                </MsalAuthenticationTemplate>
            );
            
        } else {
            return null;
        }
    }
}

// Which props do we want to inject, given the global state?
var mapStateToProps = state => {
    return { state: {
        briefcase: state.briefcaseReducer,
        config: state.configReducer,
        user: state.userReducer
    }}
};

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(withMsal(Packages));