import React, { useEffect, useState } from "react";
import { Form } from "ui-kit.aspentech.com/dist/components";
import { cloneDeep } from "lodash";
import "./EditorForm.scss";

const EditorForm = (props) => {
  const {
    config,
    editMode,
    defaultFormData,
    fieldOptions,
  } = props;
  const [formConfig, setFormConfig] = useState({});
  const [showImagePickerModal, setShowImagePickerModal] = useState(false);


  function onError() {
    
  }

  useEffect(() => {
    function updateFormConfig() {
      const newFormConfig = cloneDeep(config.formConfig);
      switch (editMode) {
        case "add-new":
          newFormConfig.sections = config.addNewFields.concat(
            newFormConfig.sections
          );
          break;
        case "edit":
        case "edit-existing":
          newFormConfig.sections = config.singleEditFields.concat(
            newFormConfig.sections
          );
          break;
        case "bulk-edit":
        default:
          break;
      }
      setFormConfig(newFormConfig);
    }

    updateFormConfig();
  }, [
    config.addNewFields,
    config.singleEditFields,
    config.formConfig,
    editMode,
  ]);


  return (
    <Form
      className="editor-form"
      config={formConfig}
      onSuccess={props.onSuccess}
      onError={props.onError || onError}
      defaultFormData={defaultFormData}
      formProps={{
        fieldOptions,
        formConfig: config,
        editMode,
        showImagePickerModal,
        setShowImagePickerModal,
      }}
    />
  );
};

export default EditorForm;
