import { put, call, delay } from 'redux-saga/effects';
import { searchQueryService } from '../../services/searchService';
import { loadBriefcase, addBriefcaseAsset, removeBriefcaseAsset } from '../../services/userService';
import * as types from '../actions';

export function* briefcaseLoadAssetsSaga(params) {
  try {
      const res = yield call(loadBriefcase, params);

      var ids = Object.keys(res.data.data);
      var q = [];

      if (ids.length > 0) {

        ids.map( id => {
          q.push(`sharepoint_id:"${id}"`);
        })
  
        const assetsRes = yield call(searchQueryService, {
          params: {
            ...params.params,
            parents: false,
            facetFilter: "child",
            query: q.join(" OR ")
          }
        });

        yield put({
          type: types.PUBLIC_LOAD_BRIEFCASE_ASSETS_SUCCESS,
          ...assetsRes.data.data
        });

      } else {

        yield put({
          type: types.PUBLIC_LOAD_BRIEFCASE_ASSETS_SUCCESS,
            response: {},
            facets: {}
        });
      }
  } catch(error) {

    yield put({ 
      type: types.PUBLIC_LOAD_BRIEFCASE_ASSETS_ERROR, 
      message: error.message
    });

  }
}

export function* briefcaseLoadSaga(params) {
  try {
      const res = yield call(loadBriefcase, params);

      yield put({
          type: types.PUBLIC_BRIEFCASE_LOAD_SUCCESS,
          data: Object.keys(res.data.data)
    });

  } catch(error) {
    yield put({ 
      type: types.PUBLIC_BRIEFCASE_LOAD_ERROR, 
      message: error.message
    });

  }
}

export function* briefcaseRemoveSaga(params) {
  try {
      const res = yield call(removeBriefcaseAsset, params);

      //TODO: figure out how to wait long enough that the briefcase recognizes
      // const res2 = yield call(loadBriefcase, params);
      // delete res2[res.data]

      yield put({
          type: types.PUBLIC_BRIEFCASE_REMOVE_SUCCESS,
          data: {...params.params}
    });

  } catch(error) {
    yield put({ 
      type: types.PUBLIC_BRIEFCASE_REMOVE_ERROR, 
      message: error.message
    });

  }
}