/**
 * block__element--modifier class name helper 
 * @param {string} block block name
 * @param {string} element element name
 * @param {array} modifiers array of modifiers
 * @param {string} append class string to append.usually for tagging instances 
 * @param {string} format string representing what classses to create. "bem" creates the complete block, element, modifier classes. "m" would only create the modifier classes.
 * @returns {string} bem style class name 
 */
export function bem (
    block = '', 
    element = '', 
    modifiers = [], 
    append = '',
    format = 'bem'
) {
    block = block && block.trim ? block.trim() : ""
    element = element && element.trim ? element.trim() : ""
    modifiers = Array.isArray(modifiers) ? modifiers : [modifiers]
    format = format || 'bem'
    
    let be = element ? `${block}__${element}` : block,
        m = (modifiers).filter( modifier => !!modifier ).map( modifier => `${be}--${modifier}`).join(' '),
        classes = []

    if (m && format.indexOf('m') > -1) {
        classes.push(m)
    } 
    
    if (element && format.indexOf('e') > -1) {
        classes.push(be)
    } else if (block && format.indexOf('b') > -1) {
        classes.push(block)
    }

    classes.reverse()
    classes.push(append)

    return classes.filter( cl => !!cl ).join(' ')
}


export function meb (className) {
    var seperator = "__"

    var iStart = className.indexOf(seperator)
    const block = className.substring(0, iStart);
    iStart += seperator.length

    seperator = "--"

    var iEnd = className.indexOf(seperator)
    const element = className.substring(iStart, iEnd)

    iStart = iEnd += seperator.length
    const modifier = className.substring(iStart)

    return {
        block,
        element,
        modifier
    }
}


export default bem