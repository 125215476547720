import React from 'react';
import NavigationData from "../../data/navigation";
import { Link, NavLink } from 'react-router-dom';
import qs from "qs";
import Tooltip from '../tooltip/Tooltip';

class Navigation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0
        }
    }
  
    getQuerystringObject = function () {
        var retVal = window.location.search;

        if (retVal.substring(0, 1) === "?") {
            retVal = retVal.substring(1);
        }

        var qsParams = qs.parse(retVal, {
            plainObjects: true,
            allowDots: true
        });

        // Object.keys(qsParams).map(key => {
        //     if (querystringParamList.indexOf(key) === -1) {
        //         delete qsParams[key];
        //     }
        // });

        return qsParams;
    }

    onClick = (event, navItem, index) => {
        if (!(navItem.href && navItem.href.substring(0, 4) === "http")) {
            event.preventDefault();
            this.setState({activeIndex: index});
        }
    }
  

    render() {
        let query = this.getQuerystringObject() || {};
        if (this.props.navKey && NavigationData[this.props.navKey]) {
            return (
                <ul className="c-primary-nav__list">
                    {NavigationData[this.props.navKey]
                        .filter( (navItem, index) => {
                            var keep =  !navItem.roles

                            if (!keep && navItem.roles) {
                                keep = navItem.roles.filter(role => this.props.roles.includes(role)).length > 0
                            }

                            return keep
                        })
                        .map( (navItem, index) => {

                            const Icon = props => navItem.icon
                            if (navItem.href.indexOf("http") === 0) {
                                return (
                                    <a key={index} data-tip={navItem.title} className={`c-primary-nav__link ${navItem.class}`} href={navItem.href} target={navItem.target}>
                                        <li className="c-primary-nav__item">
                                            <span className="c-primary-nav__icon icon-box">
                                                {navItem.icon && 
                                                    <Icon />
                                                }
                                                {!navItem.icon && 
                                                    <svg className={`svg-icon-size svg-${navItem.class}-dims`}>
                                                        <use xlinkHref={`#${navItem.class}`}></use>
                                                    </svg>
                                                }
                                                
                                            </span>
                                            <span className="c-primary-nav__label">
                                                {navItem.label}
                                            </span>
                                        </li>
                                    </a>
                                )
                            } else {
                                
                                return (
                                    <NavLink data-tip={navItem.title} key={index} activeClassName="active" className={`c-primary-nav__link ${navItem.class} ${query.from === navItem.href ? "active" : null}`} to={navItem.href}>
                                        <li className="c-primary-nav__item">
                                            <span className="c-primary-nav__icon icon-box">
                                                {navItem.icon && 
                                                    <Icon />
                                                }
                                                {!navItem.icon && 
                                                    <svg className={`svg-icon-size svg-${navItem.class}-dims`}>
                                                        <use xlinkHref={`#${navItem.class}`}></use>
                                                    </svg>
                                                }
                                            </span>
                                            <span className="c-primary-nav__label">
                                                {navItem.label}
                                            </span>
                                        </li>
                                    </NavLink>
                                )
                            }
                        }
                    )}

                    <Tooltip />
                </ul>
            )
        } else {
            return null;
        }
    }
}

export default Navigation;