import * as types from '../actions';
import produce from "immer";

const initialState = {
    message: {},
    profile: {},
    account: {},
    accessToken: null,
    idToken: null,
    isAuthenticated: false
}

export default function (state = initialState, action) {

    function pad2(number) {
        return (number < 10 ? '0' : '') + number;
    }
    
    function createMessage(text, status) {
        var d = new Date();
        var date = `${d.getMonth()}/${d.getDate() + 1}/${d.getFullYear()} ${d.getHours()}:${pad2(d.getMinutes())}:${pad2(d.getSeconds())}`;
        return {
            text,
            status,
            date 
        }
    }

    var m;
    switch (action.type) {   
        case types.PUBLIC_LOAD_PROFILE_SUCCESS:
            m = createMessage(action.message, "success");
            return {
                ...state,
                profile: action.profile,
                initialized: true,
                message: m
            };

        case types.PUBLIC_LOAD_PROFILE_ERROR:
            m = createMessage(action.message, "error")
            return {
                ...state,
                initialized: false,
                profile: {},
                message: m
            };

        case types.PUBLIC_UPDATE_TOKEN_SUCCESS:
            m = createMessage(action.message, "success");
            let s = {
                ...state,
                ...action.data,
            }
            s.isAuthenticated = !!s.accessToken

            return {
                ...s,
                message: m
            };

        case types.PUBLIC_UPDATE_TOKEN_ERROR:
            m = createMessage(action.message, "error")
            return {
                ...state,
                isAuthenticated: false,
                message: m
            };

        case types.PUBLIC_CHECK_ISEDITOR_SUCCESS:
            m = createMessage(action.message, "success");

            return {
                ...state,
                ...action.data,
                message: m
            };

        case types.PUBLIC_CHECK_ISEDITOR_ERROR:
            m = createMessage(action.message, "error")
            return {
                ...state,
                isEditor: false,
                message: m
            };

        case types.PUBLIC_CHECK_ISARCHITECT_SUCCESS:
            m = createMessage(action.message, "success");

            return {
                ...state,
                ...action.data,
                message: m
            };

        case types.PUBLIC_CHECK_ISARCHITECT_ERROR:
            m = createMessage(action.message, "error")
            return {
                ...state,
                isArchitect: false,
                message: m
            };

        case types.PUBLIC_TAKESNAPSHOT_SUCCESS:
            m = createMessage(action.message, "success");            
            return {
                ...state,
                ...action.data,
                message: m
            };

        case types.PUBLIC_TAKESNAPSHOT_ERROR:
            m = createMessage(action.message, "error")
            return {
                ...state,
                message: m
            };

        case types.PUBLIC_IMPORTSNAPSHOT_SUCCESS:
            m = createMessage(action.message, "success");
            return {
                ...state,
                ...action.data,
                message: m
            };

        case types.PUBLIC_IMPORTSNAPSHOT_ERROR:
            m = createMessage(action.message, "error")
            return {
                ...state,
                message: m
            };
            
        default:
            return state;
    }
};
