export const ThemeType = {
    NewsAndUpdate: "Messages, News & Update",
    SalesPlay: "Sales Play Kits",
    CaseStudy: "Customer Success Stories",
    CompetitiveIntelligence: "Competitive Intelligence",
    ProductAndPricing: "Products",
    SalesProcess: "Technical (BC) Training",
    AspenEvents: "Events",
    DeploymentAndLicensing: "Pricing & Licensing",
    Partners: "Partners",
    SalesTraining: "Sales Training",
    Briefcase: "Briefcase",
    Editor: "Editor"
};