
import { Input, Editor } from 'ui-kit.aspentech.com/dist/components'
import React from "react"
import { helpRequestFileUpload } from '../../services/helpService'

export default {
    validation: {
        title: (value, formData) => {
            return !value ? ["A subject is required"] : []
        },
        message: (value, formData) => {
            return !value ? ["A message is required"] : []
        }
    },
    gap: true,
    sections: [
        {
            orientation: "horizontal",
            sections: [
                {
                    orientation: "vertical",
                    fields: [
                        (props, values) => (
                            <Input
                                id={"title"}
                                label={"Subject"}
                                {...props}
                            />
                        ),

                        (props, values) => (
                            <Editor
                                id={"message"}
                                label={"Message"}
                                toolbar={{
                                    options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                                    inline: { options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'] },
                                    image: { uploadCallback: helpRequestFileUpload },
                                    list: { inDropdown: true },
                                    textAlign: { inDropdown: true }
                                }}
                                {...props}
                            />
                        ),
                        (props, values) => (
                            <input className="c-btn c-btn--help" type="submit" value="Submit Request" />
                        ),
                    ],
                }
            ]
        },
    ]
}