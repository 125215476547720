import {
  Input,
  Button,
  CheckboxGroup,
  TextArea,
  Select,
} from "ui-kit.aspentech.com/dist/components";
import Modal from "../modal/Modal";
import Images from "../../screens/editor/Images";
import { PackageCard } from "../cards/PackageCard";
import bem from "../../helpers/bem";

export const SUITES = {
  AIoT: "1d9c6b3d-a4e2-4a70-ad46-67f0edd96213",
  APM: "bc8ea72f-3100-4224-b63f-b3b155b80e8a",
  ENG: "76726547-3071-413a-87c5-c641d8438606",
  MSC: "8d7d379e-4f9f-48c7-a235-2be7f9f33e32",
};
export const SUITE_AREA_PAIRINGS = {
  AIoT: [],
  APM: ["90a6809e-c435-4c5c-8863-aba7d31f1aba"], // "Asset Performance Management"
  ENG: ["2ed9a6f9-3f21-4911-b81d-cfd161627fd1"], // "Performance Engineering"
  MSC: [
    "6d7ec6e8-062b-4bb9-b899-30a54d7a7a87", // APC
    "06992c77-5a6a-4bbe-9c8c-b5e8f5300a87", // MES
    "15efefae-2186-4a4b-934a-20183b505991", // Production Optimization
    "2fee2496-b2ee-4a76-9e52-508a07145519", // PSC
    "2a885475-6ee6-4e66-b1ce-d85576ea8372", // SCM
    "54815b19-a22e-48fa-9433-5d3cd11e0f56", // Value Chain Optimization
  ],
};
export const DEFAULT_FORM_DATA = {};
export const TABLE_COLUMNS = [
  {
    key: "title",
    data: "Package Title",
    className: bem("column", "", ["fixed", "large", "title"]),
  },
  {
    key: "description",
    data: "Description",
    className: bem("column", "", ["x-large"]),
  },
  { key: "imageName", data: "Image", className: bem("column", "", ["medium"]) },
  {
    key: "salesPlayType",
    data: "Sales Play Type",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "salesRole",
    data: "Sales Role",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "salesStage",
    data: "Sales Stage",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "audience",
    data: "Audience",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "industry",
    data: "Industry",
    className: bem("column", "", ["medium"]),
  },
  { key: "region", data: "Region", className: bem("column", "", ["medium"]) },
  { key: "suite", data: "Suite", className: bem("column", "", ["medium"]) },
  {
    key: "suiteArea",
    data: "Suite Area",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "application",
    data: "Application",
    className: bem("column", "", ["medium"]),
  },
  { key: "product", data: "Product", className: bem("column", "", ["medium"]) },
  {
    key: "productVersion",
    data: "Product Version",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "competitor",
    data: "Competitor",
    className: bem("column", "", ["medium"]),
  },
  {
    key: "customer",
    data: "Customer",
    className: bem("column", "", ["medium"]),
  },
];

function getSuiteAreaOptions(formData, options) {
  let availableOptions = [];
  if (formData && formData.suite) {
    formData.suite.forEach((suite) => {
      switch (suite) {
        case "bc8ea72f-3100-4224-b63f-b3b155b80e8a": // APM
          availableOptions = availableOptions.concat(
            options.filter((option) =>
              SUITE_AREA_PAIRINGS.APM.includes(option.value)
            )
          );
          break;
        case "76726547-3071-413a-87c5-c641d8438606": // ENG
          availableOptions = availableOptions.concat(
            options.filter((option) =>
              SUITE_AREA_PAIRINGS.ENG.includes(option.value)
            )
          );
          break;
        case "8d7d379e-4f9f-48c7-a235-2be7f9f33e32": // MSC
          availableOptions = availableOptions.concat(
            options.filter((option) =>
              SUITE_AREA_PAIRINGS.MSC.includes(option.value)
            )
          );
          break;
        case "1d9c6b3d-a4e2-4a70-ad46-67f0edd96213": // AIoT
        default:
          break;
      }
    });
  }

  availableOptions.sort((a, b) => {
    return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
  });
  return availableOptions;
}

function checkSuiteArea(selectedSuites, formData) {
  const missingSuites = Object.values(SUITES).filter(
    (suite) => !selectedSuites.suite.includes(suite)
  );
  let newSuiteAreas = [...formData.suiteArea];
  missingSuites.forEach((suiteId) => {
    const suiteName = Object.keys(SUITES).find(
      (key) => SUITES[key] === suiteId
    );
    newSuiteAreas = newSuiteAreas.filter(
      (suiteArea) => !SUITE_AREA_PAIRINGS[suiteName].includes(suiteArea)
    );
  });

  return newSuiteAreas;
}

export const addNewFields = [
  {
    className: "count-field",
    fields: [
      (props) => (
        <Input
          {...props}
          key="count"
          id="count"
          type="number"
          label="How many new packages would you like to create?"
        />
      ),
    ],
  },
  {
    orientation: "vertical",
    fields: [
      () => (
        <h3 key="infoText">Select the common fields between all packages:</h3>
      ),
    ],
  },
];

export const singleEditFields = [
  {
    orientation: "vertical",
    sections: [
      {
        gap: true,
        className: "image-picker",
        fields: [
          (props) => (
            <div key="card-preview" className="card-preview">
              <PackageCard
                key={props.formData?.imageId}
                data={{
                  title: props.formData?.title,
                  description: props.formData?.description,
                  src: props.formData?.url,
                }}
              />
              <Input
                key="image"
                id="image"
                className="hidden"
                value={props.formData?.imageId || null}
              />
            </div>
          ),
        ],
      },
      {
        orientation: "horizontal",
        numItemsWide: 2,
        className: "form-actions",
        gap: true,
        fields: [
          (props) => (
            <Button
              {...props}
              key="packageImage"
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                props.formProps?.setShowImagePickerModal(true);
              }}
            >
              Choose Package Image
            </Button>
          ),
          (props) => (
            <Input
              {...props}
              key="imageName"
              id="imageName"
              value={props.formData?.imageName}
              disabled
            />
          ),
          (props) => {
            return (
              props.formProps?.showImagePickerModal && (
                <Modal
                  key="image-library"
                  className="image-library"
                  title={<h2>Image Library</h2>}
                  onClose={() => {
                    props.formProps?.setShowImagePickerModal(false);
                  }}
                  scrollable
                  fixedTitle
                >
                  <Images
                    onChange={props.onChange}
                    setShowImagePickerModal={
                      props.formProps?.setShowImagePickerModal
                    }
                  />
                </Modal>
              )
            );
          },
        ],
      },
    ],
  },
];

export const formConfig = {
  validation: {
    count: (value, _formData, formProps) => {
      if (formProps.editMode === "add-new") {
        if (!value) return ["Count is required"];
        if (value <= 0) return ["Invalid count"];
        return [];
      }
      return [];
    },
    title: (value, _formData, formProps) => {
      if (formProps.editMode === "edit")
        return value ? [] : ["Title is required"];
      return [];
    },
  },
  orientation: "vertical",
  gap: true,
  sections: [
    {
      orientation: "horizontal",
      sections: [
        {
          orientation: "vertical",
          fields: [
            (props) => (
              <Input {...props} key="title" id="title" label="Title" />
            ),
            (props) => (
              <TextArea
                {...props}
                key="description"
                id="description"
                label="Description"
              />
            ),
          ],
        },
        {
          orientation: "vertical",
          sections: [
            {
              orientation: "horizontal",
              fields: [
                (props) => (
                  <Select
                    {...props}
                    key="salesPlayType"
                    id="salesPlayType"
                    label="Sales Play Type"
                    placeholder="Select..."
                    value={props.formData?.salesPlayType}
                    options={props.formProps?.fieldOptions?.salesPlayType || []}
                  />
                ),
              ],
            },
          ],
        },
      ],
    },
    {
      orientation: "horizontal",
      numItemsWide: 4,
      gap: true,
      fields: [
        (props) => (
          <CheckboxGroup
            {...props}
            scrollable
            collapsible
            filterable
            key="salesRole"
            id="salesRole"
            label="Sales Role"
            options={props.formProps?.fieldOptions?.salesRole || []}
          />
        ),
        (props) => (
          <CheckboxGroup
            {...props}
            scrollable
            collapsible
            filterable
            key="salesStage"
            id="salesStage"
            label="Sales Stage"
            options={props.formProps?.fieldOptions?.salesStage || []}
          />
        ),
        (props) => (
          <CheckboxGroup
            {...props}
            scrollable
            collapsible
            filterable
            key="audience"
            id="audience"
            label="Audience"
            options={props.formProps?.fieldOptions?.audience || []}
          />
        ),
        (props) => (
          <CheckboxGroup
            {...props}
            scrollable
            collapsible
            filterable
            key="industry"
            id="industry"
            label="Industry"
            options={props.formProps?.fieldOptions?.industry || []}
          />
        ),
        (props) => (
          <CheckboxGroup
            {...props}
            scrollable
            collapsible
            filterable
            key="region"
            id="region"
            label="Region"
            options={props.formProps?.fieldOptions?.region || []}
          />
        ),
        (props) => (
          <CheckboxGroup
            {...props}
            scrollable
            collapsible
            key="suite"
            id="suite"
            label="Suite"
            options={props.formProps?.fieldOptions?.suite || []}
            onChange={(valObj) => {
              const newSuiteAreas = checkSuiteArea(valObj, props.formData);
              const newValObj = {
                ...valObj,
                suiteArea: newSuiteAreas,
              };
              props.onChange(newValObj);
            }}
          />
        ),
        (props) => (
          <CheckboxGroup
            {...props}
            scrollable
            collapsible
            key="suiteArea"
            id="suiteArea"
            label="Suite Area"
            options={
              getSuiteAreaOptions(
                props.formData,
                props.formProps?.fieldOptions?.suiteArea || []
              ) || []
            }
          />
        ),
        (props) => (
          <CheckboxGroup
            {...props}
            scrollable
            collapsible
            filterable
            key="application"
            id="application"
            label="Application"
            options={props.formProps?.fieldOptions?.application || []}
          />
        ),
        (props) => (
          <CheckboxGroup
            {...props}
            scrollable
            collapsible
            filterable
            key="product"
            id="product"
            label="Product"
            options={props.formProps?.fieldOptions?.product || []}
          />
        ),
        (props) => (
          <CheckboxGroup
            {...props}
            scrollable
            collapsible
            filterable
            key="productVersion"
            id="productVersion"
            label="Product Version"
            options={props.formProps?.fieldOptions?.productVersion || []}
          />
        ),
        (props) => {
          return (
            [
              "Battle card",
              "Competitor Overview",
              "Win Loss Analysis",
              "Customer Wins",
              "Analyst Report",
              "Presentation",
              "Document",
              "Video",
            ].includes(props.formData?.packageTypeName) && (
              <CheckboxGroup
                {...props}
                scrollable
                collapsible
                filterable
                key="competitor"
                id="competitor"
                label="Competitor Name"
                options={props.formProps?.fieldOptions?.competitor || []}
              />
            )
          );
        },
        (props) => {
          return (
            ["Case Study", "Customer Success Story - PDF"].includes(
              props.formData?.packageTypeName
            ) && (
              <CheckboxGroup
                {...props}
                scrollable
                collapsible
                filterable
                key="customer"
                id="customer"
                label="Customer Name"
                options={props.formProps?.fieldOptions?.customer || []}
              />
            )
          );
        },
      ],
    },
    {
      fields: [
        (props) => (
          <Button {...props} variant="primary" key="submit" id="formSubmit">
            Submit
          </Button>
        ),
      ],
    },
  ],
};
