import 'react-app-polyfill/stable'
import React from 'react'
import { createRoot } from 'react-dom/client'
import Root from './container/Root'
import './index.scss'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<Root />)

