
import { LogLevel } from "@azure/msal-browser";
export const getAuthConfig = (tenantId, clientId, audience) => {
    // For a full list of MSAL.js configuration parameters, 
    // visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
    const msalConfig = {
        auth: {
            clientId: clientId,
            authority: `https://login.microsoftonline.com/${tenantId}`,
            redirectUri: `${window.location.origin}`
        },
        cache: {
            cacheLocation: "localStorage", // This configures where your cache will be stored
            storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
        },
        // system: {
        //     loggerOptions: {
        //         loggerCallback: (level, message, containsPii) => {
        //             if (containsPii) {	
        //                 return;	
        //             }
        //             switch (level) {	
        //                 case LogLevel.Error:	
        //                     console.error(message);	
        //                     return;	
        //                 case LogLevel.Info:	
        //                     console.info(message);	
        //                     return;	
        //                 case LogLevel.Verbose:	
        //                     console.debug(message);	
        //                     return;	
        //                 case LogLevel.Warning:	
        //                     console.warn(message);	
        //                     return;	
        //                 default:
        //                     return;
        //             }
        //         }
        //     }
        // }
    }

    // Coordinates and required scopes for your web API
    const apiConfig = {
        resourceUri: `${window.location.origin}`,
        resourceScopes: [
            "User.Read",
            "Sites.Read.All", 
            "Files.ReadWrite",
            "Mail.Send"
        ]
    }

    /** 
     * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters, 
     * visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
     */

    //const audience = "https://aspentech.com/src.aspentech.com"//appreg=>expose api->app id: might be needed
    const loginRequest = {
        scopes: [`${audience}/access_as_user`, "openid", "profile", "offline_access", ...apiConfig.resourceScopes]
    }

    // Add here scopes for access token to be used at the API endpoints.
    const claimsRequest = (data) => {
        return {
            claimsRequest: data.claims,
            scopes: [...data.scopes]
        }
    }

    // Add here scopes for silent token request
    const silentRequest = {
        scopes: [`${audience}/access_as_user`, "openid", "profile", ...apiConfig.resourceScopes]
    }

    return {
        msalConfig,
        apiConfig,
        loginRequest,
        claimsRequest,
        silentRequest
    }
}